<div class="prompt-template-container">
  <template-sidenav></template-sidenav>
  <div class="template-page-container">
    <div class="prompt-template-header">
      <h1>Crystal Prompt Template</h1>
      <p>
        Use a prompt template to streamline your Crystal interactions and save
        time. With prompt templates, you can create customizable, reusable
        prompts for a variety of topics, including tone, format, and examples.
        By utilizing prompt templates, you can quickly and easily generate
        high-quality responses, without having to spend time manually crafting
        each one.
      </p>
    </div>

    <template-card></template-card>
  </div>
</div>
