import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import {
  FullStoryService,
  PolicyService,
  PromptTemplateService,
  SidenavService,
  TelemetryService,
} from '@cwan-gpt-ui/services'
import {
  RootState,
  selectHasDigitalSpecialistAccess,
  selectIsBetaUser,
} from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import { ActionConfig } from '@northfork/basics'
import { NavLink } from '@northfork/ng-basics'
import { environment } from 'environments/environment'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-root',
  templateUrl: `./app.component.html`,
  styleUrls: [`./app.component.scss`],
})
export class AppComponent implements OnInit {
  appTitle = 'Crystal'

  navLinks: NavLink[] = [
    {
      display: 'Chat',
      route: '/',
    },
    { display: 'Templates', route: '/template' },
    {
      display: 'FAQ/Support',
      route: '/faq',
    },
  ]

  actions: ActionConfig[] = []

  isAuthComplete = false
  env = environment.environment
  isBetaUser$: Observable<boolean>
  isDigitalSpecialist$: Observable<boolean>

  constructor(
    private readonly sidenavService: SidenavService,
    private readonly policyService: PolicyService,
    private readonly telemetryService: TelemetryService,
    private readonly fullStoryService: FullStoryService,
    private readonly store: Store<RootState>,
    private readonly templateService: PromptTemplateService,
    private readonly router: Router
  ) {
    console.log(
      `running in realm: ${environment.realm}, server: ${environment.cwanGptWsBaseUrl}`
    )
    window.addEventListener('FSInitialized', () => {
      this.detectAdBlocker()
    })
    this.isBetaUser$ = this.store.select(selectIsBetaUser)
    this.isDigitalSpecialist$ = this.store.select(
      selectHasDigitalSpecialistAccess
    )
  }

  async ngOnInit() {
    //force emulated sidenav
    if (window.location.href.includes('?sidenav=false'))
      await this.sidenavService.loginWithEmulatedSidenav()
    else if (!(window as any)?.Cypress) {
      //do not load sidenav when Cypress tests are running
      await this.sidenavService.loadSidenavAndLogin()
    }
    await this.policyService.init()
    this.isAuthComplete = true
    //if you never get here, it's because the user isn't logged in and was redirected to the login page
    console.log('sidenav authentication completed')
    //user is logged in, continue initializing the app

    this.templateService.checkForDefaultTemplate()
    this.telemetryService.init()
    this.fullStoryService.init(true).then(() => {
      this.detectAdBlocker()
    })

    this.isBetaUser$.subscribe((isBetaUser) => {
      if (isBetaUser) {
        if (
          !this.navLinks.find((x) => x.display.includes('Digital Specialist'))
        ) {
          this.navLinks.splice(2, 0, {
            display: 'Digital Specialist',
            route: '/digital-specialist',
          })
        }

        this.navLinks.splice(4, 0, {
          display: 'AI Reconciler',
          route: '/ai-reconciler',
        })
        this.navLinks.splice(5, 0, {
          display: 'Chat Analyzer',
          route: '/chat-analyzer',
        })
      }
    })

    this.isDigitalSpecialist$.subscribe((isDigitalSpecialist) => {
      if (
        isDigitalSpecialist &&
        !this.navLinks.find((x) => x.display.includes('Digital Specialist'))
      ) {
        this.navLinks.push({
          display: 'Digital Specialist',
          route: '/digital-specialist',
        })
      }
    })
  }

  private detectAdBlocker() {
    this.fullStoryService.detectAdBlocker().then(() => {
      if (!window.location.pathname.includes('/adblocker'))
        this.router.navigate(['/adblocker'])
    })
  }
}
