import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'temp-file-ctas',
  templateUrl: './temp-file-ctas.component.html',
  styleUrls: [`./temp-file-ctas.component.scss`],
})
export class TempFileCtasComponent {
  @Input() filename = ''
  @Output() messageSend = new EventEmitter<string>()
  SUGGEST_LIST_OF_QUESTIONS = 'Suggest list of questions related to'

  public onMessageSend(str: string) {
    const message = `${str} the file ${this.filename}`
    this.messageSend.emit(message)
  }
}
