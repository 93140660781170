import {
  DigitalSpecialist,
  DigitalSpecialistSession,
  DsUser,
  LLMTool,
  Memory,
  Skill,
  ToolType,
  UserInstance,
} from '@cwan-gpt-ui/models'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const toolTypes: ToolType[] = [
  {
    name: 'Core',
    description:
      'The tool you are trying to create is part of the core cortex codebase',
    icon: 'hub',
    id: 'core',
  },
  {
    name: 'External',
    description:
      'The tool you are trying to create is an external tool and can be accessed via API',
    icon: 'api',
    id: 'external',
  },
  {
    name: 'Lambda',
    description:
      'The tool you are trying to create is an lambda tool and will trigger an AWS Lambda Function',
    icon: 'λ',
    id: 'lambda',
  },
  {
    name: 'CWIC Script',
    description:
      'The tool you are trying to create is a CWIC Script. It takes a python script and executes it as a tool',
    icon: 'electric_bolt',
    id: 'cwic_script',
  },
  {
    name: 'UI',
    description:
      'The tool you are trying to create will trigger some sort of UI interaction',
    icon: 'web_asset',
    id: 'ui',
  },
  {
    name: 'Pure LLM',
    description:
      'The tool you are trying to create will trigger an LLM callback',
    icon: 'robot_2',
    id: 'llm',
  },
  {
    name: 'Open API Spec',
    description:
      'The tool you are trying to create will use the provided OpenApi spec to answer questions and retrieve information',
    icon: 'data_object',
    id: 'openapi_spec',
  },
]

export interface DigitalSpecialistRedux {
  specialists: Record<string, DigitalSpecialist>
  updatableDigitalSpecialists: Record<string, DigitalSpecialist>
  selectedSpecialistUsername?: string
  availableTools: Record<string, LLMTool>
  selectedToolId?: string
  skills: Record<string, Skill>
  selectedSkillId?: string
  instances: UserInstance[]
  selectedInstance?: UserInstance
  sessions: DigitalSpecialistSession[]
  selectedSession?: DigitalSpecialistSession
  toolTypes: ToolType[]
  memories: Record<string, Memory>
  knowledge: string[]
  dsUsers: Record<string, DsUser[]>
}

export const digitalSpecialistInitialState: DigitalSpecialistRedux = {
  specialists: {},
  updatableDigitalSpecialists: {},
  selectedSpecialistUsername: undefined,

  availableTools: {},
  selectedToolId: undefined,

  skills: {},
  selectedSkillId: undefined,
  instances: [],
  selectedInstance: undefined,

  sessions: [],
  selectedSession: undefined,

  memories: {},
  knowledge: [],

  toolTypes: toolTypes,
  dsUsers: {},
}

export const digitalSpecialistSlice = createSlice({
  name: 'digitalSpecialist',
  initialState: digitalSpecialistInitialState,
  reducers: {
    // Specialists
    setDigitalSpecialists: (
      state,
      action: PayloadAction<DigitalSpecialist[]>
    ) => {
      //convert from list to map
      state.specialists = action.payload.reduce((acc, curr) => {
        acc[curr.username] = curr
        return acc
      }, {} as Record<string, DigitalSpecialist>)
    },
    setUpdatableDigitalSpecialists: (
      state,
      action: PayloadAction<DigitalSpecialist[]>
    ) => {
      //convert from list to map
      state.updatableDigitalSpecialists = action.payload.reduce((acc, curr) => {
        acc[curr.username] = curr
        return acc
      }, {} as Record<string, DigitalSpecialist>)
    },
    setSelectedSpecialist: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.selectedSpecialistUsername = action.payload
    },
    setUpdatedSpecialist: (
      state,
      action: PayloadAction<DigitalSpecialist | undefined>
    ) => {
      if (!action.payload) return
      const newSpecialists = { ...state.specialists }
      newSpecialists[action.payload.username] = action.payload
      state.specialists = newSpecialists
    },
    addSpecialist: (state, action: PayloadAction<DigitalSpecialist>) => {
      const newSpecialists = { ...state.specialists }
      newSpecialists[action.payload.username] = action.payload
      state.specialists = newSpecialists
    },
    removeSpecialist: (state, action: PayloadAction<string>) => {
      const newSpecialists = { ...state.specialists }
      delete newSpecialists[action.payload]
      state.specialists = newSpecialists
    },

    // Tools
    setAvailableTools: (state, action: PayloadAction<LLMTool[]>) => {
      state.availableTools = action.payload.reduce((acc, curr) => {
        acc[curr.toolId] = curr
        return acc
      }, {} as Record<string, LLMTool>)
    },
    setSelectedTool: (state, action: PayloadAction<string | undefined>) => {
      state.selectedToolId = action.payload
    },
    removeTool: (state, action: PayloadAction<string>) => {
      delete state.availableTools[action.payload]
    },
    addTool: (state, action: PayloadAction<LLMTool>) => {
      state.availableTools[action.payload.toolId] = action.payload
    },
    removeSkill: (state, action: PayloadAction<string>) => {
      delete state.skills[action.payload]
    },
    addSkill: (state, action: PayloadAction<Skill>) => {
      state.skills[action.payload.skillId] = action.payload
    },
    setUpdatedTool: (state, action: PayloadAction<LLMTool>) => {
      state.availableTools[action.payload.toolId] = action.payload
    },

    // Skills
    setSelectedSkill: (state, action: PayloadAction<string | undefined>) => {
      state.selectedSkillId = action.payload
    },
    setSkills: (state, action: PayloadAction<Skill[]>) => {
      state.skills = action.payload.reduce((acc, curr) => {
        acc[curr.skillId] = curr
        return acc
      }, {} as Record<string, Skill>)
    },
    setUpdatedSkill: (state, action: PayloadAction<Skill>) => {
      state.skills[action.payload.skillId] = action.payload
    },
    setUserInstances: (state, action: PayloadAction<UserInstance[]>) => {
      state.instances = action.payload
    },
    setSelectedInstance: (
      state,
      action: PayloadAction<UserInstance | undefined>
    ) => {
      state.selectedInstance = action.payload
    },

    // Sessions
    setSessions: (state, action: PayloadAction<DigitalSpecialistSession[]>) => {
      state.sessions = action.payload
    },
    appendSession: (state, action: PayloadAction<DigitalSpecialistSession>) => {
      state.sessions = [action.payload, ...state.sessions]
    },
    deleteSession: (state, action: PayloadAction<string>) => {
      const sessionId = action.payload
      const newSessions = state.sessions.filter((s) => s.sessionId != sessionId)
      state.sessions = newSessions
    },
    setSelectedSession: (
      state,
      action: PayloadAction<DigitalSpecialistSession | undefined>
    ) => {
      state.selectedSession = action.payload
    },
    setMemories: (state, action: PayloadAction<Memory[]>) => {
      state.memories = action.payload.reduce((acc, curr) => {
        acc[curr.memoryId] = curr
        return acc
      }, {} as Record<string, Memory>)
    },
    removeMemory: (state, action: PayloadAction<string>) => {
      delete state.memories[action.payload]
    },
    appendMemory: (state, action: PayloadAction<Memory>) => {
      state.memories[action.payload.memoryId] = action.payload
    },
    setKnowledge: (state, action: PayloadAction<string[]>) => {
      state.knowledge = action.payload
    },
    setDsUsers: (
      state,
      action: PayloadAction<{ [dsName: string]: DsUser[] }>
    ) => {
      state.dsUsers = {
        ...state.dsUsers,
        ...action.payload,
      }
    },
  },
})

export const {
  setDigitalSpecialists,
  setUpdatableDigitalSpecialists,
  setSelectedSpecialist,
  setUpdatedSpecialist,
  setAvailableTools,
  setSelectedTool,
  setUpdatedTool,
  setSelectedSkill,
  setSkills,
  setUpdatedSkill,
  setUserInstances,
  setSelectedInstance,
  removeSkill,
  removeSpecialist,
  removeTool,
  addSkill,
  addTool,
  addSpecialist,
  setSessions,
  appendSession,
  deleteSession,
  setSelectedSession,
  setMemories,
  removeMemory,
  appendMemory,
  setKnowledge,
  setDsUsers,
} = digitalSpecialistSlice.actions

export default digitalSpecialistSlice.reducer
