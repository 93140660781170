import { Injectable } from '@angular/core'
import { PromptTemplate, UserConfiguration } from '@cwan-gpt-ui/models'
import {
  RootState,
  selectAppliedTemplate,
  selectSelectedTemplate,
  setDefaultTemplateId,
  setSelectedTemplate,
} from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import { BehaviorSubject, Observable } from 'rxjs'
import { PromptTemplateApiService } from './prompt-template-api.service'

@Injectable({
  providedIn: 'root',
})
export class PromptTemplateService {
  appliedTemplate$ = this.store.select(selectAppliedTemplate)
  selectedTemplate$ = this.store.select(selectSelectedTemplate)

  promptPrimer = `Please consider the following characteristics in bullet points, if provided, when providing a response to the request written after "User prompt:"`

  presetTemplates: PromptTemplate[] = []
  customTemplates: PromptTemplate[] = []
  allTemplates: PromptTemplate[] = []
  templateTags: string[] = []

  //used to signal view to update
  presetTemplates$ = new BehaviorSubject<PromptTemplate[]>([])
  customTemplates$ = new BehaviorSubject<PromptTemplate[]>([])
  allTemplates$ = new BehaviorSubject<PromptTemplate[]>([])
  allTemplateTags$ = new BehaviorSubject<string[]>([])

  constructor(
    private readonly store: Store<RootState>,
    private readonly templateApiService: PromptTemplateApiService
  ) {}
  getAllTemplates$(): Observable<PromptTemplate[]> {
    return this.allTemplates$
  }

  getAllTemplateTags$(): Observable<string[]> {
    return this.allTemplateTags$
  }

  async fetchAllTemplates() {
    try {
      const allTemplates = await this.templateApiService.getTemplates()
      this.allTemplates = allTemplates
      this.allTemplates$.next(this.allTemplates)

      this.customTemplates = allTemplates.filter(
        (template) => template.type === 'custom'
      )
      this.customTemplates$.next(this.customTemplates)

      this.presetTemplates = allTemplates.filter(
        (template) => template.type === 'preset'
      )
      this.presetTemplates$.next(this.presetTemplates)

      this.templateTags = Array.from(
        new Set(
          allTemplates.reduce(
            (acc, template) => acc.concat(...template.tags),
            [] as string[]
          )
        )
      )
      this.allTemplateTags$.next(this.templateTags)
    } catch (e: any) {
      console.error(`failed to fetch templates`)
    }
  }

  async fetchTemplate(templateId: string): Promise<PromptTemplate> {
    return this.templateApiService.getTemplate(templateId)
  }

  async fetchTemplateByName(
    templateName: string
  ): Promise<PromptTemplate | undefined> {
    return this.templateApiService.getTemplateByName(templateName)
  }

  async createTemplate(template: PromptTemplate) {
    // format the prompt only if there's user config selected
    if (Object.keys(template.userConfiguration ?? {}).length !== 0) {
      if (template.role === 'user') {
        template.prompt = this.promptPrimer + this.getPrependedPrompt(template)
      } else if (template.role === 'system') {
        const combinedUserConfigs = this.addUserConfigsToPrompt(template)
        template.prompt = `${combinedUserConfigs}\n\n ${template.prompt}`
      }
    }

    const createdTemplate = await this.templateApiService.createTemplate(
      template
    )

    if (template.type === 'custom') {
      //update the model
      this.customTemplates.push(createdTemplate)
      //signal views to update and show the new one
      this.customTemplates$.next(this.customTemplates)
    } else {
      this.presetTemplates.push(createdTemplate)
      this.presetTemplates$.next(this.presetTemplates)
    }

    this.store.dispatch(setSelectedTemplate({ template: createdTemplate }))
  }

  async updateTemplate(templateId: string, template: PromptTemplate) {
    const updatedTemplate = {
      ...template,
      prompt: this.getPrependedPromptToUpdate(template),
      userConfiguration: {},
    }

    await this.templateApiService.updateTemplate(templateId, updatedTemplate)
    const updatedTemplateFromApi = await this.templateApiService.getTemplate(
      templateId
    )

    // remove the old entry from its original list
    if (template.type === 'custom') {
      const index = this.customTemplates.findIndex(
        (customTemplate) => customTemplate.id === updatedTemplateFromApi.id
      )
      if (index !== -1) {
        this.customTemplates.splice(index, 1)
        this.customTemplates$.next([...this.customTemplates])
      }
    } else {
      const index = this.presetTemplates.findIndex(
        (presetTemplate) => presetTemplate.id === updatedTemplateFromApi.id
      )
      if (index !== -1) {
        this.presetTemplates.splice(index, 1)
        this.presetTemplates$.next([...this.presetTemplates])
      }
    }

    // then add the template back to its respective list based on the updated template type
    if (updatedTemplateFromApi.type === 'custom') {
      this.customTemplates.push(updatedTemplateFromApi)
      this.customTemplates$.next([...this.customTemplates])
    } else {
      this.presetTemplates.push(updatedTemplateFromApi)
      this.presetTemplates$.next([...this.presetTemplates])
    }

    this.store.dispatch(
      setSelectedTemplate({ template: updatedTemplateFromApi })
    )
  }

  async deleteTemplate(id: string, type: string) {
    await this.templateApiService.deleteTemplate(id)

    if (type === 'custom') {
      this.customTemplates = this.customTemplates.filter(
        (customTemplate) => customTemplate.id !== id
      )
      this.customTemplates$.next([...this.customTemplates])
    } else {
      this.presetTemplates = this.presetTemplates.filter(
        (presetTemplate) => presetTemplate.id !== id
      )
      this.presetTemplates$.next([...this.presetTemplates])
    }
  }

  async toggleShowInMenu(templateId: string, showInMenu: boolean) {
    await this.templateApiService.toggleShowInMenu(templateId, showInMenu)

    const updatedTemplateFromApi = await this.templateApiService.getTemplate(
      templateId
    )
    const updatedTemplate = { ...updatedTemplateFromApi, showInMenu }

    const index = this.allTemplates.findIndex(
      (template) => template.id === updatedTemplateFromApi.id
    )

    if (index !== -1) {
      if (updatedTemplate.type === 'custom') {
        // Check if the template already exists in the array - to fix a bug where it adds a duplicate custom template
        const existingIndex = this.customTemplates.findIndex(
          (template) => template.id === updatedTemplate.id
        )
        if (existingIndex !== -1) {
          this.customTemplates[existingIndex] = updatedTemplate
        } else {
          this.customTemplates.push(updatedTemplate)
        }
        this.customTemplates$.next([...this.customTemplates])
        this.store.dispatch(setSelectedTemplate({ template: updatedTemplate }))
      } else {
        const existingIndex = this.presetTemplates.findIndex(
          (template) => template.id === updatedTemplate.id
        )
        if (existingIndex !== -1) {
          this.presetTemplates[existingIndex] = updatedTemplate
        } else {
          this.presetTemplates.push(updatedTemplate)
        }
        this.presetTemplates$.next([...this.presetTemplates])
        this.store.dispatch(setSelectedTemplate({ template: updatedTemplate }))
      }
    }
  }

  async setDefaultTemplate(templateId: string) {
    await this.templateApiService.setDefaultTemplate(templateId)

    const updatedTemplate = {
      ...(await this.templateApiService.getTemplate(templateId)),
      default: true,
    } as PromptTemplate

    const index = this.allTemplates.findIndex(
      (template) => template.id === updatedTemplate.id
    )
    if (index !== -1) {
      if (updatedTemplate.type === 'custom') {
        // Check if the template already exists in the array - to fix a bug where it adds a duplicate custom template
        const existingIndex = this.customTemplates.findIndex(
          (template) => template.id === updatedTemplate.id
        )
        if (existingIndex !== -1) {
          this.customTemplates[existingIndex] = updatedTemplate
        } else {
          this.customTemplates.push(updatedTemplate)
        }
        this.customTemplates$.next([...this.customTemplates])
        this.store.dispatch(setSelectedTemplate({ template: updatedTemplate }))
      } else {
        const existingIndex = this.presetTemplates.findIndex(
          (template) => template.id === updatedTemplate.id
        )
        if (existingIndex !== -1) {
          this.presetTemplates[existingIndex] = updatedTemplate
        } else {
          this.presetTemplates.push(updatedTemplate)
        }
        this.presetTemplates$.next([...this.presetTemplates])
        this.store.dispatch(setSelectedTemplate({ template: updatedTemplate }))
      }
    }

    this.store.dispatch(
      setDefaultTemplateId({ templateId: updatedTemplate.id })
    )
  }

  async clearDefaultTemplate() {
    await this.templateApiService.removeDefaultTemplate()
  }

  getPrependedPrompt(template: PromptTemplate): string {
    const combinedPrompt = this.addUserConfigsToPrompt(template)

    return `${combinedPrompt}\n\nUser prompt:\n\n${template.prompt}`
  }

  getPrependedPromptToUpdate(template: PromptTemplate): string {
    if (Object.keys(template.userConfiguration ?? {}).length === 0) {
      return template.prompt
    }

    const combinedUserConfigs = this.addUserConfigsToPrompt(template)

    if (!template.prompt.includes(this.promptPrimer)) {
      return `${combinedUserConfigs}\n\n ${template.prompt}`
    }

    // temporarily removing the prefix so we can add it back to the beginning of the prompt
    template.prompt = template.prompt.replace(this.promptPrimer, '')

    return `${this.promptPrimer}\n ${combinedUserConfigs}\n ${template.prompt}`
  }

  private addUserConfigsToPrompt(template: PromptTemplate): string {
    const userConfig = template.userConfiguration as UserConfiguration
    let combinedUserConfigs = ''
    // No custom configurations
    if (!userConfig) return template.prompt

    Object.keys(userConfig).forEach((key) => {
      const value = userConfig[key as keyof UserConfiguration]
      if (value) {
        combinedUserConfigs += `\n - ${key}: ${value}`
      }
    })
    template.userConfiguration = {}

    return combinedUserConfigs
  }

  async checkForDefaultTemplate() {
    await this.fetchAllTemplates()
    this.allTemplates.forEach((template) => {
      if (template.default) {
        this.store.dispatch(setDefaultTemplateId({ templateId: template.id }))
      }
    })
  }
}
