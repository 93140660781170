import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { InputForm, InputFormParam } from '@cwan-gpt-ui/models'

@Component({
  selector: 'chat-message-input-form',
  templateUrl: './chat-message-input-form.component.html',
  styleUrls: [`./chat-message-input-form.component.scss`],
})
export class ChatMessageInputFormComponent {
  form = new UntypedFormGroup({})
  inputFormParams?: InputFormParam[] = []

  @Input() set inputForm(value: InputForm) {
    this.inputFormParams = value.params

    value.params.forEach((param) => {
      this.form.addControl(param.name, new UntypedFormControl(param.value))
    })
  }

  @Output() messageSend = new EventEmitter<string>()

  onSubmit() {
    let responseMessage = `The values are: `

    for (const [key, value] of Object.entries(this.form.value)) {
      responseMessage += ` ${key}: ${value}`
    }

    this.messageSend.emit(responseMessage)
  }
}
