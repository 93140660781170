<div class="cwan-gpt-chat-container">
  <div class="cwan-gpt-chat">
    <div id="pagelayout">
      <query-results id="leftside"> </query-results>
      <div id="prompt" class="card">
        <chat-analyzer-window
          id="rightside"
          class="cwan-gpt-chat-window"
        ></chat-analyzer-window>

        <mat-divider></mat-divider>
        <chat-analyzer-grader class="grading"></chat-analyzer-grader>
      </div>
    </div>
  </div>
</div>
