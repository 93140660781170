import { Component, OnDestroy, OnInit } from '@angular/core'
import { FileUploadService } from '@cwan-gpt-ui/services'
import {
  RootState,
  myDocUploadCompleted,
  selectSelectedFiles,
  setSelectedFiles,
} from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { Observable, Subject, of } from 'rxjs'
import { distinctUntilChanged, takeUntil } from 'rxjs/operators'

@Component({
  selector: 'user-files',
  templateUrl: './user-files.component.html',
  styleUrls: ['./user-files.component.scss'],
})
export class UserFilesComponent implements OnInit, OnDestroy {
  selectedFiles: string[] = []
  saving = false
  required: boolean
  editable = false
  userFiles: string[] = []
  loading = false
  uploadCompleted = true

  private _destroyed$ = new Subject<void>()

  constructor(
    private readonly fileUploadService: FileUploadService,
    private readonly store: Store<RootState>,
    private toastr: ToastrService
  ) {
    this.required = true
    this.loadAsync()
  }

  ngOnInit() {
    this.store
      .select(selectSelectedFiles)
      .pipe(takeUntil(this._destroyed$), distinctUntilChanged())
      .subscribe((filenames) => {
        this.selectedFiles = filenames
      })

    this.store
      .select(myDocUploadCompleted)
      .pipe(takeUntil(this._destroyed$), distinctUntilChanged())
      .subscribe((completed) => {
        this.uploadCompleted = completed
      })
  }

  ngOnDestroy(): void {
    this._destroyed$.next()
    this._destroyed$.complete()
  }

  onFileChange(fileNames: string[]) {
    this.store.dispatch(setSelectedFiles({ selectedFiles: fileNames }))
  }

  async deleteFile($event: MouseEvent, file: string) {
    $event.stopPropagation()
    await this.fileUploadService
      .deleteFile(file)
      .then(() => this.toastr.success('File deleted successfully'))
      .catch(() => this.toastr.error('File deletion failed'))
    await this.loadAsync()
  }

  public loadAsync = async () => {
    this.loading = true
    this.userFiles = await this.fileUploadService.getUserFiles()
    this.loading = false
  }

  canDelete(): Observable<boolean> {
    return of(true)
  }
}
