import { ChatSession } from '@cwan-gpt-ui/models'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface QueryNavBarEvaluatedListRedux {
  evaluatedSessions: ChatSession[]
}

export const queryNavBarEvaluatedListInitialState: QueryNavBarEvaluatedListRedux =
  {
    evaluatedSessions: [],
  }

export const queryNavBarEvaluatedListSlice = createSlice({
  name: 'queryNavBarEvaluatedList',
  initialState: queryNavBarEvaluatedListInitialState,
  reducers: {
    updateEvaluatedSessionsInList: (
      state,
      session: PayloadAction<ChatSession>
    ) => {
      const id = session.payload.id
      const idx = state.evaluatedSessions.findIndex((item) => item.id === id)
      if (idx < 0) {
        return state
      }

      const newSessions = [...state.evaluatedSessions]
      newSessions[idx] = session.payload
      return {
        ...state,
        sessions: newSessions,
      }
    },
    updateEvaluatedSessionsList: (
      state,
      session: PayloadAction<ChatSession[]>
    ) => ({
      ...queryNavBarEvaluatedListInitialState,
      evaluatedSessions: session.payload,
    }),
  },
})

export const { updateEvaluatedSessionsInList, updateEvaluatedSessionsList } =
  queryNavBarEvaluatedListSlice.actions

export default queryNavBarEvaluatedListSlice.reducer
