import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { PromptHelperConfiguration } from '@cwan-gpt-ui/models'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { masterConfigurationsOptions } from '../../utils/user-configuration'

@Component({
  selector: 'new-template-configuration-option',
  templateUrl: './new-template-configuration-option.component.html',
  styleUrls: ['./new-template-configuration-option.component.scss'],
})
export class NewTemplateConfigurationOptionComponent
  implements OnInit, OnDestroy
{
  @Input() isLastRow = false

  @Output() delete = new EventEmitter<void>()
  @Output() changed = new EventEmitter<{
    input: string
    configuration: PromptHelperConfiguration
  }>()

  @Input() set registeredConfigurationValues(values: string[]) {
    this.configurationsOptions = masterConfigurationsOptions.filter(
      (x) => !values.includes(x.value)
    )
  }

  @Input() set input(value: string) {
    this.inputForm.setValue(value)
  }

  @Input() set configuration(value: PromptHelperConfiguration) {
    this.configurationForm.setValue(value)
    this.placeholder = value.placeholder ?? ''
  }

  inputForm = new UntypedFormControl('', [])
  configurationForm = new UntypedFormControl({}, [])

  form = new UntypedFormGroup({
    input: this.inputForm,
    configuration: this.configurationForm,
  })

  placeholder = ''

  configurationsOptions: PromptHelperConfiguration[] =
    masterConfigurationsOptions

  private readonly _destroyed$ = new Subject<void>()

  ngOnInit(): void {
    if (this.isLastRow) {
      this.inputForm.disable()
    }

    this.form.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((values) => {
        this.changed.next({
          input: values.input ?? '',
          configuration: values.configuration,
        })

        if (values.configuration && !this.isLastRow) {
          this.placeholder = values.configuration.placeholder
        }

        if (this.isLastRow) {
          this.form.reset('', {
            emitEvent: false,
          })
        }
      })
  }

  ngOnDestroy(): void {
    this._destroyed$.next()
    this._destroyed$.complete()
  }

  handleDelete() {
    this.delete.emit()
  }
}
