<p class="chat-message-chart-item">
  <highcharts-chart
    *ngIf="showChart && chartOptions"
    [Highcharts]="Highcharts"
    [options]="chartOptions"
    class="chat-message-highchart"
  ></highcharts-chart>
  <button
    mat-raised-button
    color="primary"
    class="chat-message-action"
    [attr.aria-label]="title"
    (click)="handleClick()"
    [matTooltip]="title"
    *ngIf="!showChart"
  >
    {{ title }}
  </button>
</p>
