<div class="chat-input-wrapper mat-app-background">
  <textarea
    #chatInputTextarea
    placeholder="Send a message. (Shift + Enter for a new line)"
    [(ngModel)]="messageText"
    (input)="handleMessageInputChange()"
    (keydown.enter)="handleEnter($event)"
    (keydown.arrowup)="handleArrowUpKey($event)"
    autofocus
  ></textarea>
  <button
    mat-mini-fab
    color="primary"
    aria-label="Send"
    [disabled]="messageText.trim().length === 0"
    (click)="handleClickSend()"
  >
    <mat-icon>arrow_forward</mat-icon>
  </button>
</div>
