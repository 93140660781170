import { ChatSession } from '@cwan-gpt-ui/models'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface QueryNavBarListRedux {
  sessions: ChatSession[]
}

export const queryNavBarListInitialState: QueryNavBarListRedux = {
  sessions: [],
}

export const queryNavBarListSlice = createSlice({
  name: 'queryNavBarList',
  initialState: queryNavBarListInitialState,
  reducers: {
    updateQuerySessionInList: (state, session: PayloadAction<ChatSession>) => {
      const id = session.payload.id

      const idx = state.sessions.findIndex((item) => item.id === id)
      if (idx < 0) {
        return state
      }

      const newSessions = [...state.sessions]
      newSessions[idx] = session.payload
      return {
        ...state,
        sessions: newSessions,
      }
    },

    updateQuerySessionList: (state, session: PayloadAction<ChatSession[]>) => ({
      ...queryNavBarListInitialState,
      sessions: session.payload,
    }),
  },
})

export const { updateQuerySessionInList, updateQuerySessionList } =
  queryNavBarListSlice.actions

export default queryNavBarListSlice.reducer
