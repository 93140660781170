<div id="master-div">
  <ng-content
    *ngIf="(chatHistory?.length ?? 0) === 0 && excludeSplashScreen"
  ></ng-content>
  <div class="pre-messages">
    <h1>Prompt Analysis Tool</h1>
    <p>
      This is an internal tool meant for subject matter experts to grade LLM
      responses to user prompts.
    </p>
    <p>Please enter in a keyword for query search.</p>
    <ng-content
      *ngIf="(chatHistory?.length ?? 0) === 0 && excludeSplashScreen"
    ></ng-content>
    <div class="query-result">
      <div class="chat-body">
        <div #chatContentContainer class="chat-content-container">
          <ng-content
            *ngIf="(chatHistory?.length ?? 0) === 0 && excludeSplashScreen"
          ></ng-content>
          <div
            class="pre-messages"
            *ngIf="chatHistory?.length === 0 && !excludeSplashScreen"
          >
            <p>
              Please use the filters on the left to search for chat sessions.
            </p>
            <p>
              You can filter by digital specialist, LLM model, keyword, user
              model...
            </p>
          </div>
          <div class="chat-content">
            <ng-container *ngFor="let msg of chatHistory; let i = index">
              <chat-message
                *ngIf="msg.role !== 'system' || debug"
                [message]="msg"
                [messageIndex]="i"
                [debug]="debug"
              ></chat-message>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
