<div class="prompt-helper">
  <div class="prompt-helper-action">
    <button
      nf-icon-button
      icon="delete"
      class="btn-bare"
      ngbTooltip="Delete configuration"
      (click)="handleDelete()"
      *ngIf="!isLastRow"
      aria-labelledby="template-menu-delete-button"
    >
      Delete Row
    </button>
    <button
      nf-icon-button
      icon="plus"
      class="btn-bare"
      ngbTooltip="Add configuration"
      *ngIf="isLastRow"
      aria-labelledby="template-menu-add-button"
    >
      Add Row
    </button>
  </div>
  <div class="prompt-helper-dropdown">
    <ng-select
      [items]="configurationsOptions"
      bindLabel="name"
      placeholder="Select a configuration"
      [clearable]="false"
      [formControl]="configurationForm"
    >
      <ng-template ng-option-tmp let-item="item">
        <div container="body" class="ellipsis">
          {{ item.name }}
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div class="prompt-helper-input">
    <input
      type="text"
      class="form-control"
      [formControl]="inputForm"
      [placeholder]="placeholder"
      autocomplete="nope"
    />
  </div>
</div>
