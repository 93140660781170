<div ngbDropdown class="template-action-menu-container">
  <button
    ngbDropdownToggle
    class="template-card-menu-btn"
    icon="dots-vertical"
    nf-icon-button
    aria-labelledby="template-menu-button"
  >
    Template Action Menu
  </button>
  <div ngbDropdownMenu>
    <button
      type="button"
      class="dropdown-item btn btn-link"
      (click)="setAppliedTemplate(selectedTemplate)"
      aria-labelledby="template-menu-apply-button"
    >
      Apply
    </button>
    <button
      *ngIf="menuCanCloneAndEdit"
      type="button"
      class="dropdown-item btn btn-link"
      aria-labelledby="template-menu-clone-and-edit-button"
      (click)="handleCloneAndEdit()"
    >
      Clone and Edit
    </button>
    <button
      *ngIf="menuCanEdit"
      type="button"
      class="dropdown-item btn btn-link"
      (click)="handleEdit()"
      aria-labelledby="template-menu-edit-button"
    >
      Edit
    </button>
    <button
      *ngIf="menuCanDelete"
      type="button"
      class="dropdown-item btn btn-link"
      (click)="handleDelete()"
      aria-labelledby="template-menu-delete-button"
    >
      Delete
    </button>
    <button
      type="button"
      class="dropdown-item btn btn-link"
      (click)="handleSetAsDefault()"
      aria-labelledby="template-menu-set-as-default-button"
      *ngIf="selectedTemplate?.id !== this.defaultTemplateId"
    >
      Set as Default
    </button>
    <button
      type="button"
      class="dropdown-item btn btn-link"
      (click)="handleClearDefault()"
      aria-labelledby="template-menu-clear-default-button"
      *ngIf="selectedTemplate?.id === this.defaultTemplateId"
    >
      Clear Default
    </button>
    <button
      *ngIf="selectedTemplate"
      type="button"
      class="dropdown-item btn btn-link"
      (click)="toggleShowInMenu()"
      aria-labelledby="template-menu-show-in-menu-button"
    >
      {{ selectedTemplate.showInMenu ? 'Hide in Menu' : 'Show in Menu' }}
    </button>
  </div>
</div>
