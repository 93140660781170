import { ChatSession } from '@cwan-gpt-ui/models'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface QueryEvaluatedNavBarRedux {
  currentEvaluatedQuerySession?: ChatSession
}

export const queryEvaluatedNavBarInitialState: QueryEvaluatedNavBarRedux = {
  currentEvaluatedQuerySession: undefined,
}

export const queryEvaluatedNavBarSlice = createSlice({
  name: 'queryEvaluatedNavBar',
  initialState: queryEvaluatedNavBarInitialState,
  reducers: {
    setCurrentEvaluatedQuerySession: (
      state,
      action: PayloadAction<ChatSession>
    ) => {
      return {
        ...state,
        currentEvaluatedQuerySession: action.payload,
      }
    },
  },
})

export const { setCurrentEvaluatedQuerySession } =
  queryEvaluatedNavBarSlice.actions

export default queryEvaluatedNavBarSlice.reducer
