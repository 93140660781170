import { createSelector } from '@ngrx/store'
import { RootState } from '../root'
import { QueryNavBarListRedux } from './query-navbar-list.reducer'

const queryNavBarListState = (state: RootState) =>
  state.queryNavBarList || { sessions: [] }

export const selectQueryNavBarList = createSelector(
  queryNavBarListState,
  (state: QueryNavBarListRedux) => state.sessions
)
