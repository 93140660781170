<form NfFormValidation novalidate [formGroup]="form" class="file-upload-cta">
  <div class="form-row">
    <nf-form-control class="col flex-fill">
      <nf-file-select
        count="1"
        title="Supported documents are csv, xlsx, docx, md, pdf, pptx, png, jpg, jpeg, bmp, tiff, txt"
        noDuplicate
        extensions="pdf,PDF,Pdf,xlsx,XLSX,Xlsx,csv,CSV,docx,DOCX,Docx,pptx,PPTX,Pptx,png,Png,PNG,bmp,Bmp,BMP,jpg,Jpg,JPG,jpeg,Jpeg,JPEG,tiff,Tiff,TIFF,txt,TXT,md,MD"
        accept=".xlsx,.docx,.csv,.pdf,.pptx,.png,.jpg,.jpeg,.bmp,.tiff,.txt,.md"
        [formControl]="selectedFiles"
        (selectedFiles)="onChange($event)"
        contentClassName="file-select-custom-style"
      >
        <span class="mdi mdi-paperclip file-upload-cta-upload-icon"></span>
      </nf-file-select>
    </nf-form-control>
  </div>
  <app-progress
    class="file-upload-progress"
    [progress]="progress"
    [showProgress]="showProgress"
  ></app-progress>
</form>
