import { Injectable } from '@angular/core'
import * as FullStory from '@fullstory/browser'

/**
 * We expect FullStory to be loaded either by sidenav, FrontOffice, or IM Portal
 * FO and IM use older versions, so we can't rely on FullStory.isInitialized() function to exist
 */
@Injectable({
  providedIn: 'root',
})
export class FullStoryService {
  async init(isSidenavLoaded: boolean) {
    if (!isSidenavLoaded) return Promise.resolve()

    //sidenav is responsible for initializing FullStory - check if it's already initialized or wait for the event
    //this is really only needed so we can nag about popup blockers in Crystal
    if (FullStory.isInitialized()) return Promise.resolve()
    return new Promise<void>((resolve, reject) => {
      window.addEventListener('FSInitialized', () => {
        resolve()
      })
    })
  }

  /**
   * https://help.fullstory.com/hc/en-us/articles/360020623274-FS-event-API-Sending-custom-event-data-into-FullStory
   */
  emitCustomEvent(
    eventName: string,
    attributes: {
      [key: string]: string | number | undefined
    }
  ) {
    //because of the older version in FO/Sidenav, we'll just use the lowest commmon denominator - if the FS.event function exists, use it.

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (typeof (window as any).FS?.event == 'function') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(window as any).FS.event(eventName, attributes)
    }
  }

  /**
   * resolves if any adblocker is found.
   * Optional: if used, must be called after fullStory init
   */
  async detectAdBlocker() {
    return new Promise<void>((resolve, reject) => {
      //first, search for the fullstory script
      const fullStoryScripts = document.querySelectorAll(
        'script[src*="fullstory.com"]'
      )
      if (fullStoryScripts.length === 0) {
        return
      }
      //if found, resolve the promise if any errors are found
      const el = fullStoryScripts[0] as unknown as HTMLScriptElement
      el.onerror = (e) => {
        if (e) {
          resolve()
        }
      }
    })
  }
}
