import { Injectable } from '@angular/core'
import { marked } from 'marked'
import * as Prism from 'prismjs'
import 'prismjs/components/prism-css'
import 'prismjs/components/prism-java'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-kotlin'
import 'prismjs/components/prism-markup'
import 'prismjs/components/prism-python'
import 'prismjs/components/prism-sass'
import 'prismjs/components/prism-scss'
import 'prismjs/components/prism-tsx'
import 'prismjs/components/prism-typescript'
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard'
import 'prismjs/plugins/line-numbers/prism-line-numbers'

@Injectable({ providedIn: 'root' })
export class CWMarkdownService {
  constructor() {
    marked.setOptions({
      renderer: new marked.Renderer(),
      highlight: function (code, language) {
        if (Prism.languages[language]) {
          return `${Prism.highlight(code, Prism.languages[language], language)}`
        } else {
          return `<pre class="line-numbers language-none"><code class="language-none">${code}</code></pre>`
        }
      },
      pedantic: false,
      gfm: true,
      breaks: false,
      sanitize: true, // Be careful with setting to `false`, as this could open up XSS vulnerabilities
      smartLists: true,
      smartypants: false,
      xhtml: false,
    })
  }

  convertMarkdownToHtml(text: string): string {
    return marked(text)
  }

  highlightAll() {
    Prism.highlightAll()
  }
}
