import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { PromptTemplate } from '@cwan-gpt-ui/models'
import { PromptTemplateService } from '@cwan-gpt-ui/services'
import {
  RootState,
  selectDefaultTemplateId,
  selectSelectedTemplate,
  setSelectedTemplate,
} from '@cwan-gpt-ui/state-management'
import { NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'
import { NewTemplateModalComponent } from '../new-template-modal/new-template-modal.component'

@Component({
  selector: 'template-sidenav',
  templateUrl: './template-sidenav.component.html',
  styleUrls: ['./template-sidenav.component.scss'],
})
export class TemplateSideNavComponent implements OnInit, OnDestroy {
  @ViewChild('nav') nav: NgbNav | undefined

  //async-piped into sub-views
  presetTemplates$: Observable<PromptTemplate[]> | undefined
  customTemplates$: Observable<PromptTemplate[]> | undefined

  filterPreset = ''
  selectedTemplate = {} as PromptTemplate
  defaultTemplateId: string | null = null

  private readonly _destroyed$ = new Subject<void>()

  constructor(
    private readonly store: Store<RootState>,
    private readonly modal: NgbModal,
    private readonly templateService: PromptTemplateService
  ) {
    this.presetTemplates$ = templateService.presetTemplates$.pipe(
      map((templates) => templates.sort((a, b) => a.name.localeCompare(b.name)))
    )
    this.customTemplates$ = templateService.customTemplates$.pipe(
      map((templates) => templates.sort((a, b) => a.name.localeCompare(b.name)))
    )
  }

  ngOnInit(): void {
    this.loadTemplates()

    this.store
      .select(selectSelectedTemplate)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((template) => {
        const isPresetType = template?.type === 'preset'
        this.nav?.select(isPresetType ? 1 : 2)

        if (template) {
          this.selectedTemplate = template
        }
      })

    this.store
      .select(selectDefaultTemplateId)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((id) => {
        this.defaultTemplateId = id ?? null
      })
  }

  ngOnDestroy(): void {
    this._destroyed$.next()
    this._destroyed$.complete()
  }

  loadTemplates() {
    this.templateService.fetchAllTemplates()
  }

  setSelectedTemplate(template: PromptTemplate) {
    this.store.dispatch(setSelectedTemplate({ template }))
  }

  async createNewTemplate() {
    try {
      const modalRef = this.modal.open(NewTemplateModalComponent, {
        size: 'lg',
      })

      modalRef.componentInstance.modalHeader = 'Create New Template'

      const template = await modalRef.result

      this.templateService.createTemplate(template)
    } catch {
      // Ignore
    }
  }

  showFilteredTemplates(template: PromptTemplate): boolean {
    if (!template) return false
    const filter = this.filterPreset.toLowerCase()
    return template.name.toLowerCase().includes(filter)
  }
}
