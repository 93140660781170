import { createSelector } from '@ngrx/store'
import { RootState } from '../root'
import { ChatSessionListRedux } from './chat-session-list.reducer'

const chatSessionListState = (state: RootState) => state.chatSessionList

export const selectChatSessionList = createSelector(
  chatSessionListState,
  (state: ChatSessionListRedux) => state.sessions
)
