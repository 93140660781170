import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { ChatMessageModel } from '@cwan-gpt-ui/models'
import { RootState, selectChatHistory } from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

const MAX_TEXTAREA_ROWS = 5
const TEXTAREA_LINE_HEIGHT = 24
const MAX_TEXTAREA_HEIGHT = MAX_TEXTAREA_ROWS * TEXTAREA_LINE_HEIGHT

@Component({
  selector: 'chat-input-area',
  templateUrl: './chat-input-area.component.html',
  styleUrls: [`./chat-input-area.component.scss`],
})
export class ChatInputAreaComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  @ViewChild('chatInputTextarea')
  private readonly chatInputTextarea!: ElementRef<HTMLTextAreaElement>

  messageText = ''

  @Output() messageSend = new EventEmitter<string>()

  private chatHistory: ChatMessageModel[] = []
  private readonly _destroyed$ = new Subject()

  constructor(private readonly store: Store<RootState>) {}

  ngAfterViewInit() {
    this.updateTextareaHeight()
  }
  ngOnInit(): void {
    this.store
      .select(selectChatHistory)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((chatHistory) => {
        this.chatHistory = chatHistory
      })
  }

  ngOnDestroy(): void {
    this._destroyed$.next()
    this._destroyed$.complete()
  }

  private sendMessage() {
    this.messageSend.emit(this.messageText)
    this.messageText = ''
    // Wait until ui updates, then update text area height
    setTimeout(() => this.updateTextareaHeight())
  }

  handleClickSend() {
    this.sendMessage()
  }

  handleMessageInputChange() {
    this.updateTextareaHeight()
  }

  handleArrowUpKey(e: Event) {
    const event = e as KeyboardEvent
    // store message before moving

    // If the cursor is not at the beginning the user is editing the message and does want the cursor to move up
    if (this.messageText.length !== 0) {
      return
    }
    event.preventDefault()
    event.stopPropagation()
    const tempMessage = this.findPreviousMessage()
    if (!tempMessage) {
      this.messageText = ''
      return
    }
    this.messageText = tempMessage
    setTimeout(() => this.updateTextareaHeight())
  }

  handleEnter(e: Event) {
    const event = e as KeyboardEvent
    // Check if the textarea is empty or the Shift key is pressed
    if (!this.messageText.trim() || event.shiftKey) {
      // Default behavior: propagate event and add newline
      return
    }
    // this.messageText = 'This is\na multiline\nstring'
    // console.log(this.messageText)
    // this.messageText = this.messageText.replace(/\n/g, '\n \\ \n')
    // console.log(this.messageText)
    event.preventDefault()
    event.stopPropagation()

    this.sendMessage()
  }

  private updateTextareaHeight() {
    const height = TEXTAREA_LINE_HEIGHT
    // Set height to 24, so scrollHeight is adjusted properly.
    this.chatInputTextarea.nativeElement.style.height = `${height}px`
    const scrollHeight = this.chatInputTextarea.nativeElement.scrollHeight
    const textareaHeight = Math.min(scrollHeight, MAX_TEXTAREA_HEIGHT)

    this.chatInputTextarea.nativeElement.style.height = `${textareaHeight}px`
  }

  findPreviousMessage(): string | undefined {
    for (let i = this.chatHistory.length - 1; i >= 0; i--) {
      if (this.chatHistory[i].role === 'user') {
        return this.chatHistory[i].content
      }
    }
    return undefined
  }
}
