import { TemplateService } from '@ag-grid-community/core'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatTabsModule } from '@angular/material/tabs'
import { MatTooltipModule } from '@angular/material/tooltip'
import { BrowserModule } from '@angular/platform-browser'
import { DesignModule } from '@cwan-gpt-ui/design'
import {
  PromptTemplateApiService,
  PromptTemplateHttpService,
} from '@cwan-gpt-ui/services'
import {
  NgbAlertModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbNavModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap'
import { NgSelectModule } from '@ng-select/ng-select'
import {
  ButtonModule,
  ModalVariantModule,
  NfFormsModule,
  ToggleModule,
} from '@northfork/ng-basics'
import { ApplyTemplateModalComponent } from './apply-template-modal/apply-template-modal.component'
import { VariableSubstitutionComponent } from './apply-template-modal/variable-substitution/variable-substitution.component'
import { DeleteTemplateConfirmationModalComponent } from './delete-template-modal/delete-template-confirmation-modal.component'
import { NewTemplateConfigurationOptionComponent } from './new-template-modal/new-template-configuration-option/new-template-configuration-option.component'
import { NewTemplateModalComponent } from './new-template-modal/new-template-modal.component'
import { PromptTemplateComponent } from './prompt-template.component'
import { TemplateCardComponent } from './template-card/template-card.component'
import { TemplateActionMenuComponent } from './template-menu/template-action-menu.component'
import { TemplateSideNavComponent } from './template-sidenav/template-sidenav.component'

@NgModule({
  declarations: [
    PromptTemplateComponent,
    TemplateCardComponent,
    TemplateSideNavComponent,
    NewTemplateModalComponent,
    NewTemplateConfigurationOptionComponent,
    ApplyTemplateModalComponent,
    TemplateActionMenuComponent,
    DeleteTemplateConfirmationModalComponent,
    VariableSubstitutionComponent,
  ],
  imports: [
    // Angular Dependencies
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NfFormsModule,
    NgSelectModule,
    ButtonModule,
    NgbTooltipModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbAlertModule,
    ToggleModule,
    ModalVariantModule,
    BrowserModule,
    DesignModule,

    // Material UI dependencies
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTabsModule,
    MatTooltipModule,
  ],
  exports: [
    PromptTemplateComponent,
    ApplyTemplateModalComponent,
    TemplateSideNavComponent,
  ],
  providers: [
    { provide: TemplateService },
    {
      provide: PromptTemplateApiService,
      useClass: PromptTemplateHttpService,
    },
  ],
})
export class PromptTemplateModule {}
