import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { take } from 'rxjs/operators'

@Component({
  selector: 'app-faq',
  templateUrl: './faq-page.component.html',
  styleUrls: [`./faq-page.component.scss`],
})
export class FaqPageComponent implements OnInit {
  rawMarkdown: string | undefined
  constructor(private readonly httpClient: HttpClient) {}

  async ngOnInit() {
    this.rawMarkdown = await this.loadFaqFile()
  }

  //This could move to a service, but it's super duper tiny
  private loadFaqFile() {
    return this.httpClient
      .get(`/assets/md/faq.md`, {
        responseType: 'text',
      })
      .pipe(take(1))
      .toPromise()
  }
}
