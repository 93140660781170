import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ChatSession } from '@cwan-gpt-ui/models'
import { environment } from 'environments/environment'

const BASE_URL = environment.cwanGptWsBaseUrl
const HISTORY_URL = `${BASE_URL}/chat/history`

@Injectable({
  providedIn: 'root',
})
export class SessionApiService {
  constructor(private readonly http: HttpClient) {}

  saveSession(session: ChatSession) {
    return this.http.put(`${HISTORY_URL}/${session.id}`, session).toPromise()
  }

  getSession(sessionId: string) {
    return this.http.get<ChatSession>(`${HISTORY_URL}/${sessionId}`).toPromise()
  }

  getSessions() {
    return this.http.get<ChatSession[]>(HISTORY_URL).toPromise()
  }

  deleteSession(sessionId: string) {
    return this.http.delete(`${HISTORY_URL}/${sessionId}`).toPromise()
  }
}
