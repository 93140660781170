export const DATASOURCES = [
  "zoomin_help_portal",
  "available_reports",
  "cosmos",
  "compass",
  "my_documents",
  "platform_support_knowledgebase",
  "confluence",
  "jira_platdesk",
  "shared_documents",
  "lmc_document_manager",
  "lmc_portfolio",
  "rfpio",
  "salesforce",
  "sales_sharepoint",
  "client_account_user_tool",
  "solution_documents",
  "jira",
  "hr_queries",
  "database",
  "lmc_database"
]
