import { PromptHelperConfiguration } from '@cwan-gpt-ui/models'

export const masterConfigurationsOptions: PromptHelperConfiguration[] = [
  {
    name: 'Tone',
    value: 'Tone',
    placeholder:
      'Specify the desired tone (e.g., formal, casual, informative, persuasive).',
  },
  {
    name: 'Format',
    value: 'Format',
    placeholder:
      'Define the format or structure (e.g., essay, bullet points, outline, dialogue).',
  },
  {
    name: 'Act as',
    value: 'Act as',
    placeholder:
      'Indicate a role or perspective to adopt (e.g., expert, critic, enthusiast).',
  },
  {
    name: 'Objective',
    value: 'Objective',
    placeholder:
      'State the goal or purpose of the response (e.g., inform, persuade, entertain).',
  },
  {
    name: 'Audience',
    value: 'Audience',
    placeholder: 'Specify the target audience for tailored content.',
  },
  {
    name: 'Language',
    value: 'Language',
    placeholder:
      'Indicate the language for the response, if different from the prompt.',
  },
  {
    name: 'Keywords',
    value: 'Keywords',
    placeholder: 'List important keywords or phrases to be included.',
  },
  {
    name: 'Terminology',
    value: 'Terminology',
    placeholder:
      'Specify industry-specific or technical terms to use or avoid.',
  },
  {
    name: 'Example',
    value: 'Example',
    placeholder: 'Provide examples of desired style, structure, or content.',
  },
  {
    name: 'Citation',
    value: 'Citation',
    placeholder:
      'Request inclusion of citations or sources to support information.',
  },
  {
    name: 'Sensitivity',
    value: 'Sensitivity',
    placeholder:
      'Mention sensitive topics or issues to be handled with care or avoided.',
  },
  {
    name: 'Confidentiality',
    value: 'Confidentiality',
    placeholder: 'Specify confidentiality requirements or restrictions.',
  },
  {
    name: 'Case Studies',
    value: 'Case Studies',
    placeholder:
      'Request referencing relevant case studies or real-world examples.',
  },
  {
    name: 'Metaphors',
    value: 'Metaphors',
    placeholder:
      'Encourage the use of metaphors to make complex ideas more relatable.',
  },
]
