import { FileType } from './file-upload.model'

export type MessageRole = 'user' | 'assistant' | 'system'
export type UserFeedback = 'like' | 'dislike'

export interface ChatMessageModel {
  role: MessageRole
  content: string
  citations?: Citation[]
  chart?: string
  availableActions?: AvailableAction[]
  inputForm?: InputForm
  intermediateSteps?: JSON[]
  fileType?: FileType
  toolDebugLogs?: string[]
  feedback?: UserFeedback
  disableFeedback?: boolean
  uploadedFilename?: string
  metadata?: {
    tools?: string[]
    skills?: string[]
  }
}

export interface DsChatMessageModel extends ChatMessageModel {
  dsUsername: string
}

export enum CitationType {
  TEXT = 'TEXT',
  URL = 'URL',
}

export interface Citation {
  type: CitationType
  source: string
  title?: string
}

export interface AvailableAction {
  type: string
  title: string
  params: Record<string, any>
}

export interface AssistantMessage {
  content: string
  suggestedActions?: AvailableAction[]
}

export interface InputFormParam {
  name: string
  value: string
}

export interface InputForm {
  title?: string
  params: InputFormParam[]
}
