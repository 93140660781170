<h3>Content Generation</h3>
<div class="content-generation-container">
  <div class="temperature-labels">
    <span>Precise</span>
    <span>Creative</span>
  </div>
  <mat-slider
    [min]="0"
    [max]="2"
    [step]="0.1"
    [value]="temperature"
    (change)="onTemperatureChange($event)"
  >
    <input matSliderThumb />
  </mat-slider>
</div>
