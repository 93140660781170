import { Auth0Client, User } from '@auth0/auth0-spa-js'
import { environment } from 'environments/environment'

let auth0Client: Auth0Client

export async function emulateSidenavWithoutLogin(
  cwAuth0ConfigClientId: string,
  cwAuth0ConfigOrganization: string
) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<void>(async (resolve, reject) => {
    //TODO init with connection
    initAuth0Client(cwAuth0ConfigClientId, cwAuth0ConfigOrganization)

    try {
      await cwanGetAccessTokenSilentlyOrFail()
    } catch (e) {
      //important that we reject here - this means the user is not logged in, and we don't want to log them in here.
      reject(e)
    }

    const auth0User = await auth0Client.getUser()

    //emulate sidenav's helper object
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(window as any).sidenav = {
      getAccessTokenSilentlyOrLogout: async (): Promise<string> => {
        return cwanGetAccessTokenSilentlyOrFail() //note that this is NOT the OrLogout version!!
      },
      user: getSidenavUserInfo(auth0User),
    }

    resolve()
  })
}

/**
 * This is used temporarily instead of sidenav due to sidenav not recognizing the hard-coded auth0 config provided via attributes,
 * and redirecting users to the login page
 * It also emulates the user info and fullstory init that sidenav does
 */
export async function emulateSidenavAndLogin(
  cwAuth0ConfigClientId?: string,
  cwAuth0ConfigOrganization?: string
) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<void>(async (resolve, reject) => {
    initAuth0Client(cwAuth0ConfigClientId, cwAuth0ConfigOrganization)

    if (isRedirectCallback()) {
      await handleRedirectCallback()
    }

    //this should send you to the login page if you're not logged in...
    await checkLogin()

    const auth0User = await auth0Client.getUser()

    //emulate sidenav's helper object
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(window as any).sidenav = {
      getAccessTokenSilentlyOrLogout: async (): Promise<string> => {
        return cwanGetAccessTokenSilentlyOrLogout()
      },
      user: getSidenavUserInfo(auth0User),
    }

    resolve()
  })
}

function isRedirectCallback() {
  const href = window.location.href
  return href.includes('code=') && href.includes('state=')
}

export async function handleRedirectCallback() {
  await auth0Client.handleRedirectCallback()
  console.log(`handle login redirect...`)
  window.location.href = '/'
}

function initAuth0Client(
  cwAuth0ConfigClientId?: string,
  cwAuth0ConfigOrganization?: string
) {
  if (auth0Client) return auth0Client
  auth0Client = new Auth0Client({
    domain: environment.auth0.domain,
    clientId: cwAuth0ConfigClientId ?? environment.auth0.clientId,
    authorizationParams: {
      organization: cwAuth0ConfigOrganization ?? environment.auth0.organization,
      audience: environment.auth0.audience,
      redirect_uri: `${window.location.origin}`,
    },
  })

  return auth0Client
}

/**
 * https://auth0.github.io/auth0-spa-js/classes/Auth0Client.html#checksession
 * workaround for checkSession - won't always properly detect if user is not logged in.
 */
async function checkLogin(): Promise<string> {
  return cwanGetAccessTokenSilentlyOrLogout()
}

async function cwanGetAccessTokenSilentlyOrFail(): Promise<string> {
  if (!auth0Client) throw new Error('auth0Client is unset')
  try {
    const token = await auth0Client.getTokenSilently()
    return token
  } catch (e: any) {
    if (e.error === 'login_required') {
      console.error('Failed to get token - Login Required')
    }
    throw e
  }
}

//emulates sidenav's helper functions, but does not handle assumeRole
async function cwanGetAccessTokenSilentlyOrLogout(): Promise<string> {
  if (!auth0Client) throw new Error('auth0Client is unset')
  try {
    const token = await auth0Client.getTokenSilently()
    return token
  } catch (e: any) {
    if (e.error === 'login_required') {
      console.warn('Login Required')
      await auth0Client.loginWithRedirect()
      return 'no token - login required' //we should actually never get here due to redirect
    }
    throw e
  }
}

//mostly copied from sidenav//authentication.ts
function getSidenavUserInfo(userInfo: User | undefined) {
  if (!userInfo) throw new Error('Auth0User is unknown')

  const clearwaterUserData: undefined | { [key: string]: unknown } =
    userInfo['http://clearwateranalytics.com/user']
  return {
    auth0Id: userInfo.sub,
    nickname: userInfo.nickname,
    name: userInfo.name,
    email: userInfo.email,
    org_id: userInfo.org_id,
    clearwaterData: {
      clientId: clearwaterUserData?.clientId,
      legacyUserId: clearwaterUserData?.legacyUserId,
      rootClientId: clearwaterUserData?.rootClientId,
      name: clearwaterUserData?.name,
      realm: clearwaterUserData?.realm,
    },
    roleDetails: userInfo.roleDetails,
  }
}
