import { createSelector } from '@ngrx/store'
import { RootState } from '../root'
import { QueryEvaluatedNavBarRedux } from './query-navbar-evaluated.reducer'

const queryNavBarState = (state: RootState) => state.queryNavBarEvaluated

export const selectEvaluatedQueryResult = createSelector(
  queryNavBarState,
  (state: QueryEvaluatedNavBarRedux) => state.currentEvaluatedQuerySession
)

export const selectEvaluatedQueryResultHistory = createSelector(
  queryNavBarState,
  (state: QueryEvaluatedNavBarRedux) => {
    if (!state.currentEvaluatedQuerySession) return []
    return state.currentEvaluatedQuerySession.history
  }
)
