import { createSelector } from '@ngrx/store'
import { RootState } from '../root'
import { UserRedux } from './user.reducer'

const userState = (state: RootState) => state.user

export const selectUserId = createSelector(
  userState,
  (state: UserRedux) => state.userId
)

export const selectUserClientId = createSelector(
  userState,
  (state: UserRedux) => state.clientId
)

export const selectUserEmail = createSelector(
  userState,
  (state: UserRedux) => state.email
)

export const selectIsBetaUser = createSelector(
  userState,
  (state: UserRedux) => state.policy.isBetaUser
)

export const selectIsTosAccepted = createSelector(
  userState,
  (state: UserRedux) => state.isTosAccepted
)

export const selectDigitalSpecialistTos = createSelector (
  userState,
  (state: UserRedux) => state.digitalSpecialistTos
)

export const selectHasDebugTools = createSelector(
  userState,
  (state: UserRedux) => state.policy.isDebugToolsAccess
)

export const selectIsPromptAdmin = createSelector(
  userState,
  (state: UserRedux) => state.policy.isPromptTemplateAdmin
)

export const selectHasDigitalSpecialistAccess = createSelector(
  userState,
  (state: UserRedux) => state.policy.isDigitalSpecialistAccess
)

export const selectHasDigitalSpecialistAdmin = createSelector(
  userState,
  (state: UserRedux) => state.policy.isDigitalSpecialistAdmin
)

export const selectUserTenantId = createSelector(
  userState,
  (state: UserRedux) => state.tenantId
)
