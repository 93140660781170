import { createSelector } from '@ngrx/store'
import { RootState } from '../root'
import { CopilotRedux } from './copilot.reducer'

const copilotState = (state: RootState) => state.copilot

export const selectCopilotChatStatus = createSelector(
  copilotState,
  (state: CopilotRedux) => state.copilotChatStatus
)

export const selectIsOpen = createSelector(
  copilotState,
  (state: CopilotRedux) => state.isOpen
)

export const selectIsDocked = createSelector(
  copilotState,
  (state: CopilotRedux) => state.isDocked
)

export const selectDockPosition = createSelector(
  copilotState,
  (state: CopilotRedux) => state.dockPosition
)
