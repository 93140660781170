import { ChatSessionListRedux } from './chat-session/chat-session-list.reducer'
import { ChatSessionRedux } from './chat-session/chat-session.reducer'
import { CopilotRedux } from './copilot/copilot.reducer'
import { DebugToolsRedux } from './debug-tools'
import { DigitalSpecialistRedux } from './digital-specialist'
import { FileUploadRedux } from './file-upload'
import { PrivateModeReducer } from './private-mode'
import { PromptTemplatesRedux } from './prompt-templates'
import { QueryNavBarEvaluatedListRedux } from './query-navbar-evaluated'
import { QueryEvaluatedNavBarRedux } from './query-navbar-evaluated/query-navbar-evaluated.reducer'
import { QueryNavBarListRedux } from './query-navbar/query-navbar-list.reducer'
import { QueryNavBarRedux } from './query-navbar/query-navbar.reducer'
import { UserRedux } from './user'

export interface RootState {
  templates: PromptTemplatesRedux
  fileUpload: FileUploadRedux
  debugTools: DebugToolsRedux
  privateMode: PrivateModeReducer
  chatSession: ChatSessionRedux
  chatSessionList: ChatSessionListRedux
  copilot: CopilotRedux
  digitalSpecialist: DigitalSpecialistRedux
  user: UserRedux
  queryNavBarList: QueryNavBarListRedux
  queryNavBar: QueryNavBarRedux
  queryNavBarEvaluatedList: QueryNavBarEvaluatedListRedux
  queryNavBarEvaluated: QueryEvaluatedNavBarRedux
}
