import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { CwanGptApi } from './cwan-gpt-api/cwan-gpt-api.service'
import { CwanGptWsHttp } from './cwan-gpt-api/cwan-gpt-ws-http.service'
import { PromptTemplateApiService } from './prompt-service/prompt-template-api.service'
import { PromptTemplateHttpService } from './prompt-service/prompt-template-http.service'

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: CwanGptApi, useClass: CwanGptWsHttp },
    {
      provide: PromptTemplateApiService,
      useClass: PromptTemplateHttpService,
    },
  ],
})
export class ServicesModule {}
