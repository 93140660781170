import { Component } from '@angular/core'
const MOCKED_DATA: any[] = [
  { type: 'Client', identifier: '1 (Clearwater)', enabled: 'Enabled' },
  { type: 'Account', identifier: '51282', enabled: 'Enabled' },
]

@Component({
  selector: 'ai-reconciler-auto-ignore',
  templateUrl: './auto-ignore.component.html',
  styleUrls: ['./auto-ignore.component.scss'],
})
export class AiReconcilerAutoIgnoreComponent {
  inputText = ''
  displayedColumns: string[] = ['type', 'identifier', 'enabled', 'actions']
  dataSource = MOCKED_DATA

  update() {
    this.inputText = ''
  }
}
