import { Injectable } from '@angular/core'
import {
  ChatOptions,
  ChatRequest,
  ChatResponse,
  ChatSession,
  ModelInfo,
  Tool,
} from '@cwan-gpt-ui/models'
import {
  RootState,
  appendMessage,
  dsResponseReceivedAction,
  messageFeedbackAction,
  requestSent,
  responseErrorAction,
  responseReceived,
  responseReceivedAction,
  selectChatSession,
  setCurrentSession,
} from '@cwan-gpt-ui/state-management'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { CwanGptApi } from '../cwan-gpt-api/cwan-gpt-api.service'
import { SessionService } from '../session/session.service'
export const EXCLUSIVE_SOURCES = [
  'database',
  'my_documents',
  'hR_queries',
  'shared_documents',
  'compliance_documents',
]

/**
 * This provides a layer above the raw cwan-gpt-api
 *  - provides a simplified service for view layers to use, abstracting complexities of working with API, loading history, etc
 *  - keeps track of state, and provides updates when state changes
 */
@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private currentSession?: ChatSession

  constructor(
    private readonly cwanGptApi: CwanGptApi,
    private readonly actions$: Actions,
    private readonly sessionService: SessionService,
    private readonly store: Store<RootState>
  ) {
    this.store.select(selectChatSession).subscribe((chatSession) => {
      this.currentSession = chatSession
    })
    this.actions$.pipe(ofType(messageFeedbackAction)).subscribe(() => {
      if (this.currentSession)
        this.sessionService.saveSession(this.currentSession)
    })
  }

  public async getAvailableModels() {
    return this.cwanGptApi.getModels()
  }

  public async getAvailableTools() {
    const datasources = await this.cwanGptApi.getDatasources()
    const toolsArray: Tool[] = datasources.map((tool) => {
      if (tool.toolId == 'hr_queries') tool.toolId = 'hR_queries'
      return {
        name: tool.toolId,
        exclusiveSelection: EXCLUSIVE_SOURCES.includes(tool.toolId),
        samplePrompts: tool.samplePrompts,
      }
    })
    return toolsArray
  }

  public async getToolExamplePrompts() {
    return await this.cwanGptApi.getToolPrompts()
  }

  public setCurrentSession(value: ChatSession | undefined) {
    //let our subscriber update the current session
    this.store.dispatch(setCurrentSession(value))
  }

  public getCurrentSession() {
    return this.currentSession
  }

  public async sendMessageWithoutSession(
    text: string,
    modelInfo: ModelInfo,
    options?: ChatOptions,
    isPrivateMode?: boolean
  ): Promise<string> {
    const chatRequest: ChatRequest = {
      modelInfo: modelInfo,
      messages: [
        {
          role: 'user',
          content: text,
        },
      ],
      options: options,
      isPrivateMode: isPrivateMode,
    }
    try {
      const chatResponse = await this.sendMessageToApi(chatRequest)
      return chatResponse.message.content
    } catch (error) {
      //insert a custom response to show to the user
      const errorMessage =
        'We are sorry, something went wrong. Try requesting your prompt again.'

      return Promise.reject(errorMessage)
    }
  }

  /**
   * Sends a new user prompt to the API
   *  - appends the new prompt to the chatHistory
   *  - sends the entire chat history to API
   *  - signals updates to chatHistory in redux for the UI to display
   *  - gracefully handles API errors
   *
   * @param text - user prompt
   * @param options
   * @param isPrivateMode
   * @returns model response, or a friendly error message
   */
  public async sendMessage(
    text: string,
    options?: ChatOptions,
    isPrivateMode?: boolean
  ): Promise<string> {
    if (!this.currentSession) {
      console.warn('sendMessage: undefined session')
      return Promise.reject('undefined session')
    }

    //we need to save this because the response may come after switching sessions
    const requestSessionId = this.currentSession.id

    const messageOptions = options ?? this.currentSession.options

    //append message to the session history
    this.store.dispatch(
      appendMessage({
        role: 'user',
        content: text,
      })
    )
    //because dispatch is synchronous, history will now be updated with the new request
    const chatHistory = this.currentSession.history

    const chatRequest: ChatRequest = {
      modelInfo: this.currentSession.model,
      messages: chatHistory,
      options: messageOptions,
      isPrivateMode: isPrivateMode,
    }

    try {
      this.signalRequestSent()

      const chatResponse = await this.sendMessageToApi(
        chatRequest,
        requestSessionId
      )

      this.store.dispatch(appendMessage(chatResponse.message))
      return chatResponse.message.content
    } catch (error: any) {
      //insert a custom response to show to the user
      const errorMessage = this.getErrorMessageBasedOnError(error)

      this.store.dispatch(
        appendMessage({
          role: 'assistant',
          content: errorMessage,
        })
      )

      return Promise.reject(errorMessage)
    } finally {
      //keep our reference counter accurate
      this.signalResponseReceived(requestSessionId)
    }
  }

  /** The inner funciton to call the api, keeping track of telemetry **/
  private async sendMessageToApi(
    chatRequest: ChatRequest,
    sessionId?: string
  ): Promise<ChatResponse> {
    const startTime = Date.now()
    const dsUsername = chatRequest.options?.dsUsername

    try {
      let chatResponse
      if (sessionId) {
        if (dsUsername) {
          //digital specialist
          chatResponse = await this.cwanGptApi.sendDsMessage(
            chatRequest,
            sessionId,
            dsUsername
          )
        } else {
          //regular session
          chatResponse = await this.cwanGptApi.sendMessage(
            chatRequest,
            sessionId
          )
        }
      } else {
        //no session
        chatResponse = await this.cwanGptApi.sendMessageWithoutSession(
          chatRequest
        )
      }

      //record the response telemetry
      this.store.dispatch(
        responseReceivedAction({
          sessionId,
          chatResponse,
          durationMs: Date.now() - startTime,
        })
      )
      if (dsUsername) {
        this.store.dispatch(
          dsResponseReceivedAction({
            sessionId,
            dsUsername,
            chatResponse,
            durationMs: Date.now() - startTime,
          })
        )
      }

      return Promise.resolve(chatResponse)
    } catch (error) {
      //record the failure in telemetry
      this.store.dispatch(
        responseErrorAction({
          sessionId: sessionId,
          dsUsername,
          durationMs: Date.now() - startTime,
        })
      )
      return Promise.reject(error)
    }
  }

  /* to start the typing loader */
  public startLoading() {
    if (!this.currentSession) return
    this.signalRequestSent()
  }

  /* to stop the typing loader */
  public stopLoading() {
    if (!this.currentSession) return

    //this is manually done by the helios copilot - note that there is a reference counting bug here if the session is changed before the response comes back
    this.signalResponseReceived(this.currentSession.id)
  }

  private signalRequestSent() {
    this.store.dispatch(requestSent(this.currentSession?.id))
  }

  /**
   * @param sessionId this is not always the 'current session id' - the response could come back after changing sessions, in which case it is ignored
   */
  private signalResponseReceived(sessionId: string) {
    this.store.dispatch(responseReceived(sessionId))
  }

  private getErrorMessageBasedOnError(error: {
    error: string
    status: number
  }) {
    if (error.error == 'login_required') {
      //auth0 getToken failure, in mode EMULATE_SIDENAV_NO_LOGIN
      return 'We are sorry, your login session has expired.  Please log in again to continue.'
    }
    if (error.status === 0) {
      //When a request takes an extended period of time and the ALB times out, indicating that the connection has become idle.
      return 'The request took too long to complete, please try again after some time.'
    }
    if (error.status === 401) {
      //When auth0 token is expired
      return 'Your session has timed out. Kindly refresh the page to resume your activities.'
    }

    console.error(
      `ChatService: Unknown error from chatApi.  Status: ${status}: ${error}`
    )
    return 'We are sorry, something went wrong. Try requesting your prompt again.'
  }
}

@Injectable({
  providedIn: 'root',
})
export class LoadingWheelService {
  private dataSubject = new Subject<any>()
  data$ = this.dataSubject.asObservable()
  loadingStatus(data: any) {
    this.dataSubject.next(data)
  }
}
