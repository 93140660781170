import { Component, EventEmitter, Output } from '@angular/core'
// import API when ready
import { ChatSession } from '@cwan-gpt-ui/models'
import { SessionService } from '@cwan-gpt-ui/services'
import { RootState, setCurrentSession } from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
// import { QueryNavbarComponent } from '../query-navbar/query-navbar.component'

@Component({
  selector: 'query-results',
  templateUrl: './query-results.component.html',
  styleUrls: [`./query-results.component.scss`],
})
export class QueryResultsComponent {
  @Output() createSession = new EventEmitter()

  queryResults: ChatSession[] = []
  isLoading = true

  private destroyed$ = new Subject()

  constructor(
    private readonly store: Store<RootState>,
    private readonly sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.sessionService.loadSessions()
  }
  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  handleSessionSelected(session: ChatSession) {
    this.store.dispatch(setCurrentSession(session))
  }
}
