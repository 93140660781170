import { PromptTemplate } from '@cwan-gpt-ui/models'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const presetTemplates = [
  {
    id: '1',
    name: 'Write Email',
    prompt: `Please consider the following characteristics, if provided, when providing a response to the request written after "User prompt:"

 - Act as: You are a product manager for Helios.
 - Tone: You have a professional tone of voice.
 - Format: Write a short email

User Prompt: Acknowledge the customer's issue or question, provide a helpful solution or response, and offer additional assistance or follow-up if needed. Write a 100-word follow-up email about the following issue/question: Seeing something weird between helios and perseus, I have a transaction where the currency is showing differently between perseus and helios. In helios if the transaction is unentered and I open it to enter it it shows as CAD but in perseus it shows as USD. In both accounts when I open the transaction from entered the custody currency shows as USD and the client says the currency should be USD. I think there's obviously an issue with the security but it seems like theres also a disconnect between what we are seeing between the 2 systems.
`,
    showInMenu: true,
    type: 'preset',
    default: false,
  },
  {
    id: '2',
    name: 'Inside Joke',
    prompt: `Please consider the following characteristics, if provided, when providing a response to the request written after "User prompt:"

 - Act as: a comedy writer.
 - Tone: goofy

User Prompt: Tell me an inside joke that only those who have watched 'The OA' would understand.
`,
    showInMenu: true,
    type: 'preset',
    default: false,
  },
] as PromptTemplate[]

export const customTemplates = [
  {
    id: '3',
    name: 'Financial Plan',
    prompt: `Please consider the following characteristics, if provided, when providing a response to the request written after "User prompt:"

 - Role: financial analyst.
 - Tone: You have a professional tone of voice.

User Prompt: Please come up with creative ways to manage finances. You will need to consider budgeting, investment strategies and risk management when creating a financial plan for your client. In some cases, you may also need to provide advice on taxation laws and regulations in order to help them maximize their profits. Please create a financial plan for Clearwater Analytics.
`,
    showInMenu: true,
    type: 'custom',
    default: false,
  },
  {
    id: '4',
    name: 'Debug Code',
    prompt: `Please consider the following characteristics, if provided, when providing a response to the request written after "User prompt:"

 - Act as: a senior developer mentor.
 - Tone: Professional.

User Prompt: I'm getting an error that says '[insert error message]'. How can I troubleshoot and resolve this error?`,
    showInMenu: true,
    type: 'custom',
    default: false,
  },
] as PromptTemplate[]

export interface PromptTemplatesRedux {
  customTemplates: PromptTemplate[]
  presetTemplates: PromptTemplate[]
  templates: PromptTemplate[]
  selectedTemplate?: PromptTemplate
  appliedTemplate?: PromptTemplate
  defaultTemplateId?: string
  defaultTemplate?: PromptTemplate
}

export const promptTemplateInitialState: PromptTemplatesRedux = {
  customTemplates: [],
  presetTemplates: [],
  templates: [...customTemplates, ...presetTemplates],
  appliedTemplate: undefined,
  selectedTemplate: undefined,
  defaultTemplateId: undefined,
  defaultTemplate: undefined,
}

export const promptTemplatesSlice = createSlice({
  name: 'templates',
  initialState: promptTemplateInitialState,
  reducers: {
    setAppliedTemplate: (
      oldState,
      action: PayloadAction<{ template: PromptTemplate | undefined }>
    ) => ({
      ...oldState,
      appliedTemplate: action.payload.template,
    }),

    setSelectedTemplate: (
      oldState,
      action: PayloadAction<{ template: PromptTemplate | undefined }>
    ) => ({
      ...oldState,
      selectedTemplate: action.payload.template,
    }),

    setDefaultTemplateId: (
      oldState,
      action: PayloadAction<{ templateId: string | undefined }>
    ) => ({
      ...oldState,
      defaultTemplateId: action.payload.templateId,
    }),

    toggleShowInMenu: (
      oldState,
      action: PayloadAction<{ templateId: string; showInMenu: boolean }>
    ) => ({
      ...oldState,
      templates: oldState.templates.map((template) =>
        template.id === action.payload.templateId
          ? { ...template, showInMenu: !template.showInMenu }
          : template
      ),
    }),
  },
})

export const {
  setAppliedTemplate,
  setSelectedTemplate,
  setDefaultTemplateId,
  toggleShowInMenu,
} = promptTemplatesSlice.actions

export default promptTemplatesSlice.reducer
