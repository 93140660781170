import { DigitalSpecialist } from '@cwan-gpt-ui/models'
import { createSelector } from '@ngrx/store'
import { RootState } from '../root'
import { DigitalSpecialistRedux } from './digital-specialist.reducer'

const digitalSpecialistState = (state: RootState) => state.digitalSpecialist

/** Returns a list of ds, e.g. DigitalSpecialist[] */
export const selectDigitalSpecialists = createSelector(
  digitalSpecialistState,
  (state: DigitalSpecialistRedux) => Object.values(state.specialists)
)

/** Returns a list of ds for listing in studio where admin/editor can edit the ds*/
export const selectUpdatableDigitalSpecialists = createSelector(
  digitalSpecialistState,
  (state: DigitalSpecialistRedux) =>
    Object.values(state.updatableDigitalSpecialists)
)

/** Returns a map of ds keyed by username, e.g. Record<str, DigitalSpecialist> */
export const selectDigitalSpecialistRecords = createSelector(
  digitalSpecialistState,
  (state: DigitalSpecialistRedux) => state.specialists
)

export const selectActiveDigitalSpecialists = createSelector(
  digitalSpecialistState,
  (state: DigitalSpecialistRedux) =>
    Object.values(state.specialists).filter((x) => x.active)
)

// this is the 'selected' one
// WARNING - you may not get all the details - if you want the details, load it directly!
export const selectDigitalSpecialist = createSelector(
  digitalSpecialistState,
  (state: DigitalSpecialistRedux) =>
    state.selectedSpecialistUsername
      ? state.specialists[state.selectedSpecialistUsername]
      : undefined
)

export const selectAvailableTools = createSelector(
  digitalSpecialistState,
  (state: DigitalSpecialistRedux) => Object.values(state.availableTools)
)

export const selectTool = createSelector(
  digitalSpecialistState,
  (state: DigitalSpecialistRedux) =>
    state.selectedToolId
      ? state.availableTools[state.selectedToolId]
      : undefined
)

export const selectSkill = createSelector(
  digitalSpecialistState,
  (state: DigitalSpecialistRedux) =>
    state.selectedSkillId ? state.skills[state.selectedSkillId] : undefined
)

export const selectSkills = createSelector(
  digitalSpecialistState,
  (state: DigitalSpecialistRedux) => Object.values(state.skills)
)

export const selectUserInstances = createSelector(
  digitalSpecialistState,
  (state: DigitalSpecialistRedux) => state.instances
)

export const selectUserInstance = createSelector(
  digitalSpecialistState,
  (state: DigitalSpecialistRedux) => state.selectedInstance
)

export const selectRecentDigitalSpecialists = createSelector(
  digitalSpecialistState,
  (state: DigitalSpecialistRedux) => {
    const instances = [...state.instances].sort(
      (a, b) =>
        new Date(a.lastAccessedDatetime ?? a.creationDatetime ?? '').getTime() -
        new Date(b.lastAccessedDatetime ?? b.creationDatetime ?? '').getTime()
    )

    const specialists: DigitalSpecialist[] = []

    for (const instance of instances) {
      const specialist = Object.values(state.specialists).find(
        (x) => x.username === instance.username
      )
      if (specialist) {
        specialists.push(specialist)
      }
    }

    return specialists
  }
)

export const selectSessions = createSelector(
  digitalSpecialistState,
  (state: DigitalSpecialistRedux) => state.sessions
)

export const selectActiveSession = createSelector(
  digitalSpecialistState,
  (state: DigitalSpecialistRedux) => state.selectedSession
)

export const selectToolTypes = createSelector(
  digitalSpecialistState,
  (state: DigitalSpecialistRedux) => state.toolTypes
)

export const selectMemories = createSelector(
  digitalSpecialistState,
  (state: DigitalSpecialistRedux) => Object.values(state.memories)
)

export const selectKnowledge = createSelector(
  digitalSpecialistState,
  (state: DigitalSpecialistRedux) => state.knowledge
)

export const selectDsUsers = createSelector(
  digitalSpecialistState,
  (state: DigitalSpecialistRedux) => state.dsUsers
)
