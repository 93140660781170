import { RootState } from '../root';
import { createSelector } from '@ngrx/store'
import { PrivateModeReducer } from './private-mode.reducer'

const privateModeState = (state: RootState) => state.privateMode

export const selectIsPrivateModeSet = createSelector(
  privateModeState,
  (state: PrivateModeReducer) => state.isPrivateModeSet
)
