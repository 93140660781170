<form [formGroup]="form" (submit)="onSubmit()">
  <div class="form-container">
    <div *ngFor="let input of inputFormParams; let i = index">
      <mat-form-field appearance="fill">
        <mat-label>{{ input.name }}</mat-label>
        <input
          matInput
          aria-label="test"
          type="text"
          formControlName="{{ input.name }}"
          value="{{ input.value }}"
        />
      </mat-form-field>
    </div>
  </div>
  <button mat-raised-button type="submit" color="primary">Submit</button>
</form>
