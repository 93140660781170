import { ChatResponse } from '@cwan-gpt-ui/models'
import { createAction, props } from '@ngrx/store'

export type MessageFeedbackPayload = {
  feedback?: 'like' | 'dislike'
  messageIndex: number
}

export const messageFeedbackAction = createAction(
  '[Chat] MessageFeedback',
  props<MessageFeedbackPayload>()
)

export type ResponseReceivedPayload = {
  sessionId?: string
  chatResponse: ChatResponse
  durationMs: number
}

export type DsResponseReceivedPayload = {
  sessionId?: string
  chatResponse: ChatResponse
  dsUsername: string
  durationMs: number
}

export const responseReceivedAction = createAction(
  '[Chat] ResponseReceived',
  props<ResponseReceivedPayload>()
)

export const dsResponseReceivedAction = createAction(
  '[Chat] dsResponseReceived',
  props<DsResponseReceivedPayload>()
)

export type ResponseErrorPayload = {
  sessionId?: string
  dsUsername?: string
  durationMs: number
}

export const responseErrorAction = createAction(
  '[Chat] ResponseError',
  props<ResponseErrorPayload>()
)
