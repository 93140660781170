import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import {
  ChatMessageModel,
  ChatOptions,
  ChatSession,
  ModelInfo,
  Tool,
} from '@cwan-gpt-ui/models'
import { ChatService, PromptTemplateService } from '@cwan-gpt-ui/services'
import {
  RootState,
  selectChatHistory,
  selectQueryResult,
  setCurrentSessionOptions,
} from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'chat-analyzer-ui',
  templateUrl: './chat-analyzer-ui.component.html',
  styleUrls: [`./chat-analyzer-ui.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatAnalyzerUIComponent implements OnInit, OnDestroy {
  chatHistory: ChatMessageModel[] = []
  currentSession?: ChatSession
  sessions: ChatSession[] = []
  debugOption = new UntypedFormControl(false)
  debug = false

  sidebarVisibility: { [key: string]: boolean } = {
    sessions: false,
    tools: false,
  }

  availableModels: ModelInfo[] = []

  toolOptions: Promise<Tool[]> = this.chatService.getAvailableTools()
  _selectedTools: string[] = []
  set selectedTools(values: string[]) {
    const options: ChatOptions = {
      tools: values,
    }

    //redux update results in this._selectedTools being updated
    this.store.dispatch(setCurrentSessionOptions(options))
  }
  get selectedTools() {
    return this._selectedTools
  }

  private readonly destroyed$ = new Subject<void>()

  constructor(
    private readonly chatService: ChatService,
    private readonly store: Store<RootState>,
    private readonly templateService: PromptTemplateService
  ) {}

  ngOnInit(): void {
    this.templateService.fetchAllTemplates()
    this.chatService
      .getAvailableModels()
      .then((models) => (this.availableModels = models))

    this.store
      .select(selectQueryResult)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((currentQuerySession) => {
        this.onSessionUpdated(currentQuerySession)
      })

    this.store
      .select(selectChatHistory)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((chatHistory) => {
        this.chatHistory = chatHistory
      })
  }

  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  formatLabel(value: number) {
    return `${value}`
  }

  private onSessionUpdated(session: ChatSession | undefined) {
    this.currentSession = session
  }

  @HostListener('window:resize', ['$event'])
  isMobile() {
    return window.innerWidth < 1028
  }
}
