import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { PromptRequest, PromptTemplate } from '@cwan-gpt-ui/models'
import { environment } from 'environments/environment'
import { catchError, take } from 'rxjs/operators'
import { PromptTemplateApiService } from './prompt-template-api.service'

let TEMPLATE_URL = `${environment.cwanGptWsBaseUrl}/templates`

@Injectable({
  providedIn: 'root',
})
export class PromptTemplateHttpService extends PromptTemplateApiService {
  constructor(private readonly httpClient: HttpClient) {
    super()
  }

  /**
   * When used externally by clients, we have to use the public urls
   */
  public usePublicApi() {
    TEMPLATE_URL = `${environment.publicApiBaseUrl}/templates`
  }

  getTemplates(): Promise<PromptTemplate[]> {
    return this.httpClient
      .get<PromptTemplate[]>(TEMPLATE_URL)
      .pipe(take(1))
      .toPromise()
  }

  getTemplatesByTagName(tag: string): Promise<PromptTemplate[]> {
    const url = `${TEMPLATE_URL}/tags/${tag}`
    return this.httpClient.get<PromptTemplate[]>(url).pipe(take(1)).toPromise()
  }

  getTemplate(id: string): Promise<PromptTemplate> {
    const url = `${TEMPLATE_URL}/${id}`
    return this.httpClient
      .get<PromptTemplate>(url)
      .pipe(
        take(1),
        catchError(() => {
          return Promise.reject(
            `Failed to get prompt with ID ${id}. Please try again later.`
          )
        })
      )
      .toPromise()
  }

  getTemplateByName(name: string): Promise<PromptTemplate> {
    const url = `${TEMPLATE_URL}/preset/${name}`
    return this.httpClient
      .get<PromptTemplate>(url)
      .pipe(
        take(1),
        catchError(() => {
          return Promise.reject(
            `Failed to get prompt with Name ${name}. Please try again later.`
          )
        })
      )
      .toPromise()
  }

  createTemplate(request: PromptRequest): Promise<PromptTemplate> {
    return this.httpClient
      .post<PromptTemplate>(TEMPLATE_URL, request)
      .pipe(
        take(1),
        catchError(() => {
          return Promise.reject(
            'Failed to create prompt. Please try again later.'
          )
        })
      )
      .toPromise()
  }

  updateTemplate(id: string, request: PromptRequest): Promise<PromptTemplate> {
    const url = `${TEMPLATE_URL}/${id}`
    return this.httpClient
      .patch<PromptTemplate>(url, request)
      .pipe(
        take(1),
        catchError(() => {
          return Promise.reject(
            `Failed to update prompt with ID ${id}. Please try again later.`
          )
        })
      )
      .toPromise()
  }

  deleteTemplate(id: string): Promise<void> {
    const url = `${TEMPLATE_URL}/${id}`
    return this.httpClient
      .delete<void>(url)
      .pipe(
        take(1),
        catchError(() => {
          return Promise.reject(
            `Failed to delete prompt with ID ${id}. Please try again later.`
          )
        })
      )
      .toPromise()
  }

  toggleShowInMenu(id: string, showInMenu: boolean): Promise<void> {
    const url = `${TEMPLATE_URL}/${id}/show-in-menu`
    return this.httpClient
      .post<void>(url, {}, { params: { value: showInMenu } })
      .pipe(
        take(1),
        catchError(() => {
          return Promise.reject(
            `Failed to toggle prompt menu visibility for prompt with ID ${id}. Please try again later.`
          )
        })
      )
      .toPromise()
  }

  setDefaultTemplate(id: string): Promise<void> {
    const url = `${TEMPLATE_URL}/${id}/save-as-default`
    return this.httpClient
      .put<void>(url, {})
      .pipe(
        take(1),
        catchError(() => {
          return Promise.reject(
            `Failed to set default prompt with ID ${id}. Please try again later.`
          )
        })
      )
      .toPromise()
  }

  removeDefaultTemplate(): Promise<void> {
    const url = `${TEMPLATE_URL}/remove-default`
    return this.httpClient
      .delete<void>(url)
      .pipe(
        take(1),
        catchError(() => {
          return Promise.reject(
            'Failed to remove default prompt. Please try again later.'
          )
        })
      )
      .toPromise()
  }
}
