<div class="card template-card-container" *ngIf="selectedTemplate">
  <div class="card-header">
    <div class="card-title">
      <h2 class="template-name">{{ selectedTemplate.name }}</h2>
      <hr />
      <h4>role: {{ selectedTemplate.role }}</h4>
      <h4 *ngIf="selectedTemplate.role === 'system'">
        id: {{ selectedTemplate.id }}
      </h4>
      <h4 *ngIf="selectedTemplate.description">
        <hr *ngIf="selectedTemplate.role === 'system'" />
        description: {{ selectedTemplate.description }}
      </h4>
      <h6>
        <span *ngFor="let tag of selectedTemplate.tags">
          <span class="nf-custom-badge">
            <i class="mdi mdi-tag"></i>
            {{ tag }}
          </span>
        </span>
      </h6>
    </div>
    <template-action-menu
      [selectedTemplate]="selectedTemplate"
    ></template-action-menu>
  </div>
  <div class="card-body">
    <cw-markdown [markdownText]="promptText"></cw-markdown>
  </div>
</div>
