import { Component, EventEmitter, Input, Output } from '@angular/core'
import { AvailableAction } from '@cwan-gpt-ui/models'

@Component({
  selector: 'chat-message-actions',
  templateUrl: './chat-message-actions.component.html',
  styleUrls: ['./chat-message-actions.component.scss'],
})
export class ChatMessageActionsComponent {
  @Input() availableActions: AvailableAction[] = []

  @Output() actionClicked = new EventEmitter<AvailableAction>()

  handleAction(action: AvailableAction) {
    this.actionClicked.emit(action)
  }
}
