import { createSelector } from '@ngrx/store'
import { RootState } from '../root'
import { FileUploadRedux } from './file-upload.reducer'

const fileUplaodState = (state: RootState) => state.fileUpload

export const selectPlaceholder = createSelector(
  fileUplaodState,
  (state: FileUploadRedux) => state.placeholder
)

export const selectSelectedFiles = createSelector(
  fileUplaodState,
  (state: FileUploadRedux) => state.selectedFiles
)

export const selectTemporaryFiles = createSelector(
  fileUplaodState,
  (state: FileUploadRedux) => state.temporaryFiles
)

export const myDocUploadCompleted = createSelector(
  fileUplaodState,
  (state: FileUploadRedux) => state.myDocUploadCompleted
)
