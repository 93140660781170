import { Directive, ElementRef, Input, OnInit } from '@angular/core'

@Directive({
  selector: '[env]',
})
export class EnvDirective implements OnInit {
  @Input() env = 'dev'
  @Input() set isBetaUser(isBetaUser: boolean | null) {
    if (isBetaUser) {
      this.setupBetaUser()
    }
  }

  constructor(private readonly eleRef: ElementRef) {}

  ngOnInit(): void {
    if (this.env !== 'prod') {
      this.setupEnv()
    }
  }

  setupEnv() {
    const h1 = this.getH1()
    if (!h1) return
    const env = this.env === 'localDevServer' ? 'local' : this.env

    const envSpan = document.createElement('span')
    envSpan.innerText = env
    envSpan.classList.add(env)
    envSpan.classList.add('env-badge')
    h1.appendChild(envSpan)
  }

  setupBetaUser() {
    const h1 = this.getH1()
    if (!h1) return

    const betaUserSpan = document.createElement('span')
    betaUserSpan.innerText = 'Beta User'
    betaUserSpan.classList.add('beta-user')
    betaUserSpan.classList.add('env-badge')
    h1.appendChild(betaUserSpan)
  }

  getH1() {
    const el = this.eleRef.nativeElement as HTMLElement
    return el.querySelector('.app-title') as HTMLHeadingElement
  }
}
