import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Memory } from '@cwan-gpt-ui/models'
import { environment } from 'environments/environment'
import { catchError, take } from 'rxjs/operators'

const BASE_URL = `${environment.cwanGptWsBaseUrl}/hippocampus-ws/api/v1`

@Injectable({
  providedIn: 'root',
})
export class HippocampusApi {
  constructor(private readonly http: HttpClient) {}

  getMemories(dsUsername: string, topNLongTerm: number): Promise<Memory[]> {
    const url = `${BASE_URL}/memories/query`
    return this.http
      .post<Memory[]>(url, {
        topLongTerm: topNLongTerm,
        dsUsername,
      })
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`hippocampus/getMemories API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  deleteMemory(memoryId: string): Promise<void> {
    const url = `${BASE_URL}/memories/${memoryId}`
    return this.http
      .delete<void>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`hippocampus/deleteMemory API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  insertMemory(dsUsername: string, content: string): Promise<Memory> {
    const url = `${BASE_URL}/memories`
    return this.http
      .post<Memory>(url, {
        dsUsername,
        content,
      })
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`hippocampus/insertMemory API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }
}
