<div class="ai-reconciler-container">
  <div class="ai-reconciler-sidenav">
    <mat-action-list class="main-menu">
      <p class="menu-header">Reports</p>
      <button mat-list-item mat-list-item routerLink="/ai-reconciler">
        Dashboards
      </button>

      <p class="menu-header">Settings</p>
      <button mat-list-item routerLink="/ai-reconciler/auto-ignore">
        Auto Ignore
      </button>
      <button mat-list-item>Auto Enter</button>
      <button mat-list-item>Auto Match</button>
    </mat-action-list>
  </div>
  <div class="ai-reconciler-main">
    <router-outlet></router-outlet>
  </div>
</div>
