<div class="chat-body" mat-app-background>
  <div #chatContentContainer class="chat-content-container">
    <div class="chat-content">
      <ng-container *ngFor="let msg of chatHistory; let i = index">
        <chat-message
          *ngIf="msg.role !== 'system' || debug"
          [message]="msg"
          [messageIndex]="i"
          [debug]="debug"
          (actionSelect)="handleActionSelect($event)"
          (feedback)="handleFeedback($event, i)"
          (messageSend)="handleMessageSend($event)"
        ></chat-message>
      </ng-container>
      <!-- append a temporary typing message -->
      <chat-message *ngIf="showTyping" [showTyping]="true"></chat-message>
    </div>
  </div>
</div>
<chat-input-area
  (messageSend)="handleMessageSend($event)"
  *ngIf="chatEnabled"
></chat-input-area>
