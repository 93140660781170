//this comes from the deploy-time realmConfig.js, imported by index.html
//in unit test, jest.presets.js sets this as a global
declare global {
  let realmConfig_cwgpt: Record<string, unknown>
}

export type Realm = 'alpha' | 'beta' | 'stg1' | 'prd1' | 'prd2'
export type CwanEnvironment =
  | 'localDevServer'
  | 'unittest'
  | 'dev'
  | 'staging'
  | 'prod'

/**
 * This is identical to realmConfig.alpha.js, but we just paste it here so Typescript knows the schema,
 *   and also so that localhost dev just works without any changes
 * to change realmConfig during localhost development, edit index.html to specify which realmConfig.*.js you want
 */
export const environment = {
  realm: 'alpha' as Realm,
  environment: 'localDevServer' as CwanEnvironment,
  production: false,
  test: false,
  auth0: {
    domain: 'auth.beta.cwan.io',
    audience: 'https://core.clearwateranalytics.com',
    organization: 'org_o27ieGG1xliIqiJC',
    clientId: 'n6t3pYTt4DAAZFwhVpvWDnQ6cOGaK11b',
  },
  defaultModel: {
    provider: 'azure-openai',
    deployment: 'gpt-4o-mini',
    model: 'gpt-4o-mini',
    temperature: 0.9,
  },
  defaultDSModel: {
    provider: 'azure-openai',
    model: 'gpt-4o',
    temperature: 0.9,
  },
  sidenavBaseUrl: 'https://console.uswe2.beta.aws.cwan.io/nav',
  cwanGptWsBaseUrl: 'https://cwan-gpt-ws.uswe2.beta.aws.cwan.io',
  // cwanGptWsBaseUrl: 'http://localhost:8080',
  publicApiBaseUrl: 'https://cwan-gpt-ws.uswe2.beta.aws.cwan.io/public',
  cwanGptUiBaseUrl: 'https://cwan-gpt-ui.uswe2.beta.aws.cwan.io',

  ...realmConfig_cwgpt, //override with realm-specific settings from realmConfig.js
}
