import { Component, OnDestroy, OnInit } from '@angular/core'
import { PromptTemplate } from '@cwan-gpt-ui/models'
import {
  RootState,
  selectSelectedTemplate,
} from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'

@Component({
  selector: 'template-card',
  templateUrl: './template-card.component.html',
  styleUrls: ['./template-card.component.scss'],
})
export class TemplateCardComponent implements OnInit, OnDestroy {
  promptText = ''
  selectedTemplate?: PromptTemplate

  _destroyed$ = new Subject<void>()

  constructor(private readonly store: Store<RootState>) {}

  ngOnInit() {
    this.store.select(selectSelectedTemplate).subscribe((template) => {
      this.selectedTemplate = template

      this.promptText = template?.prompt ?? ''
    })
  }

  ngOnDestroy(): void {
    this._destroyed$.next()
    this._destroyed$.complete()
  }
}
