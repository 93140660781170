<ng-select
  #select
  [disabled]="!uploadCompleted"
  [items]="userFiles"
  [(ngModel)]="selectedFiles"
  [loading]="saving"
  clearAllText="Clear"
  bindLabel="name"
  [multiple]="true"
  [required]="required"
  [clearable]="!required"
  class="user-files-select"
  (change)="onFileChange($event)"
  [loading]="loading"
  aria-setsize="6"
  placeholder="Select one or multiple documents"
>
  <ng-template ng-option-tmp let-option="item$" let-item="item">
    <span class="file-name">{{ item }}</span>
    <button
      (click)="deleteFile($event, item)"
      class="btn btn-danger btn-nf btn-icon btn-bare mdi mdi-delete action-button delete-button"
    ></button>
  </ng-template>
</ng-select>
<file-upload
  (uploadCompletionEvent)="loadAsync()"
  [userFiles]="userFiles"
></file-upload>
