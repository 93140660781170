import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import {
  ChatMessageModel,
  PdfAction,
  Tool,
  UserFeedback,
} from '@cwan-gpt-ui/models'
import {
  RootState,
  messageFeedbackAction,
  selectChatHistory,
  selectIsAwaitingResponse,
  selectIsDebugModeSet,
  setMessageFeedback,
} from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: [`./chat-window.component.scss`],
})
export class ChatWindowComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('chatContentContainer')
  private readonly chatContentContainer!: ElementRef

  @Input() excludeSplashScreen?: boolean
  @Input() excludeHeader?: boolean
  @Input() initialInputHeight?: number = undefined
  @Input() toolOptions: Tool[] | null = []
  @Input() selectedTools: string[] = []

  @Output() messageSend = new EventEmitter<string>()
  @Output() pdfAction = new EventEmitter<PdfAction>()

  chatHistory: ChatMessageModel[] = []
  numberOfChatsInHistory = 0
  showTyping = false
  debug = false
  toolSamplePromptsMap: Map<string, string[] | undefined> = new Map()

  private readonly _destroyed$ = new Subject()

  defaultPrompts = [
    'Write me an email explaining the benefits of using Crystal in one paragraph',
    'Explain to me what the difference between a transaction, position, and a lot is',
    'Please give me the top 3 concerns from the following customer meeting notes: <paste customer meeting notes>',
    'Can you write me an email that lets a customer know there will be delay in the delivery of their reports',
    'I have a basic understanding of accounting and finance, suggest some topics to increase my knowledge on investing in LPs',
    'Give me a list of example prompts that I could ask',
  ]
  examplePrompts = this.defaultPrompts

  constructor(private readonly store: Store<RootState>) {}

  ngOnInit(): void {
    this.store
      .select(selectChatHistory)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((chatHistory) => {
        this.handleChatHistoryUpdated(chatHistory)
      })
    this.store
      .select(selectIsAwaitingResponse)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((isAwaitingResponse) => {
        this.showTyping = isAwaitingResponse
      })
    this.store
      .select(selectIsDebugModeSet)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((isDebugModeSet) => {
        this.debug = isDebugModeSet
        this.handleChatHistoryUpdated(this.chatHistory)
      })
  }

  ngOnChanges(): void {
    if (!this.toolSamplePromptsMap.size && this.toolOptions?.length) {
      this.toolOptions?.forEach((toolPrompt) =>
        this.toolSamplePromptsMap.set(toolPrompt.name, toolPrompt.samplePrompts)
      )
    }
    this.setToolExamplePrompts()
  }

  ngOnDestroy(): void {
    this._destroyed$.next()
    this._destroyed$.complete()
  }

  private handleChatHistoryUpdated(chatHistory: ChatMessageModel[]) {
    this.chatHistory = chatHistory

    // Prevent scrolling on the first change to allow the splash screen title
    if (this.chatHistory?.length === 0) {
      // Defer the scroll to the next JavaScript cycle so that the DOM has time to update
      setTimeout(() => this.scrollToTop())
      return
    }

    // If new messages are not added, do not scroll.
    // Stops the chat from scrolling when Feedback is sent.
    if (this.numberOfChatsInHistory === chatHistory.length) return
    this.numberOfChatsInHistory = chatHistory.length

    // Defer the scroll to the next JavaScript cycle so that the DOM has time to update
    setTimeout(() => this.scrollToTopOfLastMessage(), 500)
  }

  handleMessageSend(message: string) {
    this.messageSend.emit(message)
  }

  handlePdfAction(payload: PdfAction) {
    this.pdfAction.emit(payload)
  }

  handleFeedback(feedback: UserFeedback | undefined, messageIndex: number) {
    this.store.dispatch(
      setMessageFeedback({
        messageIndex,
        feedback,
      })
    )
    this.store.dispatch(
      messageFeedbackAction({
        messageIndex,
        feedback,
      })
    )
  }

  scrollToTop(): void {
    try {
      this.chatContentContainer.nativeElement.scrollTop = 0
    } catch (err) {
      return
    }
  }

  scrollToTopOfLastMessage(): void {
    try {
      const chatContainer = this.chatContentContainer.nativeElement
      const lastChatMessage = chatContainer.children[0].lastElementChild

      chatContainer.scrollTop =
        chatContainer.scrollHeight - lastChatMessage.offsetHeight
    } catch (err) {
      return
    }
  }

  applyExamplePrompt(value: any) {
    this.messageSend.emit(value)
  }

  private setToolExamplePrompts() {
    let displayedPrompts: string[] = []
    if (this.selectedTools.length && this.toolSamplePromptsMap?.size) {
      for (const selectedTool of this.selectedTools) {
        if (this.toolSamplePromptsMap.get(selectedTool)) {
          displayedPrompts = displayedPrompts.concat(
            this.toolSamplePromptsMap.get(selectedTool) as string[]
          )
        }
      }
    }
    if (displayedPrompts.length) {
      this.examplePrompts = displayedPrompts
    } else {
      this.examplePrompts = this.defaultPrompts
    }
  }
}
