<div class="cwan-gpt-chat-container">
  <div
    class="chat-sessions"
    *ngIf="sidebarVisibility['sessions']"
    (click)="isMobile() && toggleSidebarVisibility(['sessions'])"
  >
    <chat-sessions
      (createSession)="handleCreateNewSession()"
      (navigateToSession)="handleNavigateToSession()"
    ></chat-sessions>
  </div>
  <div class="cwan-gpt-chat">
    <ng-container
      *ngIf="
        selectedModel.model === 'gpt-4' || selectedModel.model === 'gpt-4-32k'
      "
    >
      <div class="warning">
        WARNING! GPT-4 is a large and expensive model, so the response time
        might be several minutes. We recommend using the GPT-3.5 model.
      </div>
    </ng-container>
    <div class="chat-header">
      <div class="chat-header-left">
        <button
          mat-icon-button
          matTooltip="Chat sessions"
          class="chat-header--button chat-header--button-sidebar-left"
          color="primary"
          icon="view_sidebar"
          aria-label="Chat sessions"
          (click)="toggleSidebarVisibility(['sessions'])"
        >
          <mat-icon>view_sidebar</mat-icon>
        </button>
        <button
          [disabled]="chatHistory.length <= 0"
          *ngIf="currentSession"
          mat-icon-button
          matTooltip="Copy chat history"
          class="chat-header--button"
          color="accent"
          icon="copy"
          aria-label="Copy chat history"
          (click)="copyChatHistory()"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
        <button
          [disabled]="chatHistory.length <= 0"
          *ngIf="currentSession"
          mat-icon-button
          matTooltip="Delete chat session"
          class="chat-header--button"
          color="warn"
          icon="delete"
          aria-label="delete chat session button"
          (click)="deleteCurrentSession()"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>

      <div class="chat-header-middle">
        <nf-form-control label="Model">
          <ng-select
            class="chat-model-select"
            [(ngModel)]="selectedModel"
            [items]="availableModels"
            bindLabel="model"
            placeholder="Select a model"
            [clearable]="false"
          >
            <ng-template ng-option-tmp let-item="item">
              <div [ngbTooltip]="item" container="body" class="ellipsis">
                {{ item.model }}
              </div>
            </ng-template>
          </ng-select>
        </nf-form-control>
        <nf-form-control label="Template">
          <ng-select
            class="chat-template-select"
            placeholder="Select a template"
            [(ngModel)]="selectedTemplate"
            [items]="templateOptions$ | async"
            bindLabel="name"
            [clearable]="true"
            (ngModelChange)="onTemplateChange($event)"
          >
            <ng-template ng-option-tmp let-item="item">
              <div [ngbTooltip]="item.name" container="body" class="ellipsis">
                {{ item.name }}
              </div>
            </ng-template>
          </ng-select>
        </nf-form-control>
        <div class="chat-debug-tools" *ngIf="isDebugToolsAccess$ | async">
          <mat-slide-toggle
            [formControl]="debugOption"
            (ngModelChange)="onDebugModeChange($event)"
          >
            <mat-label>Debug Mode</mat-label>
          </mat-slide-toggle>
        </div>
        <div class="chat-debug-tools">
          <mat-slide-toggle
            [formControl]="privateModeOption"
            (ngModelChange)="togglePrivateMode()"
          >
            <mat-label>Private Mode</mat-label>
          </mat-slide-toggle>
        </div>
      </div>

      <div class="chat-header-right">
        <button
          mat-icon-button
          matTooltip="Chat tools"
          class="chat-header--button chat-header--button-sidebar-right"
          color="primary"
          icon="view_sidebar"
          aria-label="toggle chat toolbar"
          (click)="toggleSidebarVisibility(['tools'])"
        >
          <mat-icon class="mat-icon-rtl-mirror">view_sidebar</mat-icon>
        </button>
      </div>
    </div>

    <chat-window
      class="cwan-gpt-chat-window"
      (pdfAction)="handlePdfAction($event)"
      (messageSend)="handleMessageSend($event)"
      [toolOptions]="toolOptions | async"
      [selectedTools]="selectedTools"
    ></chat-window>
  </div>
  <div class="chat-tools" *ngIf="sidebarVisibility['tools']">
    <div class="right-sidenav">
      <div class="d-flex justify-content-between align-items-center mr-1">
        <ul ngbNav #nav="ngbNav" class="nav-tabs">
          <li [ngbNavItem]="1">
            <a ngbNavLink> Data Sources </a>
            <ng-template ngbNavContent class="mr-1">
              <div class="data-source-container">
                <chat-data-sources
                  [sources]="toolOptions | async"
                  [selected]="selectedTools"
                  (updateSource)="onDataSourceUpdate($event)"
                ></chat-data-sources>
                <div class="right-sidenav-files" *ngIf="isMyDocumentsSelected">
                  <user-files></user-files>
                </div>
                <div *ngIf="isSharedDocumentsSelected">
                  <shared-documents></shared-documents>
                </div>
                <a
                  href="{{ dataSourceDefinitionsDocLink }}"
                  target="_blank"
                  class="cwan-gpt-data-sources-doc"
                  >Data Source Definitions</a
                >
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink>Tuning Options</a>
            <ng-template ngbNavContent class="mr-1">
              <chat-tuning-options
                [temperature]="temperature"
                (temperatureChange)="handleTemperatureChange($event)"
              ></chat-tuning-options>
            </ng-template>
          </li>
        </ul>
        <button
          mat-icon-button
          matTooltip="Close chat tools"
          class="d-none btn-close"
          aria-label="Close chat toolbar"
          (click)="toggleSidebarVisibility(['tools'])"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div [ngbNavOutlet]="nav" class="mt-2 ml-3 mr-2"></div>
    </div>
  </div>
</div>
