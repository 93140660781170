import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import {
  ChatMessageModel,
  ChatSession,
  PdfAction,
  Tool,
  UserFeedback,
} from '@cwan-gpt-ui/models'
import {
  messageFeedbackAction,
  RootState,
  selectQueryResultHistory,
  setMessageFeedback,
} from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'chat-analyzer-window',
  templateUrl: './chat-analyzer-window.component.html',
  styleUrls: [`./chat-analyzer-window.component.scss`],
})
export class ChatAnalyzerWindowComponent implements OnInit, OnDestroy {
  @ViewChild('chatContentContainer')
  private readonly chatContentContainer!: ElementRef

  @Input() excludeSplashScreen?: boolean
  @Input() excludeHeader?: boolean
  @Input() initialInputHeight?: number = undefined
  @Input() toolOptions: Tool[] | null = []
  @Input() selectedTools: string[] = []

  @Output() messageSend = new EventEmitter<string>()
  @Output() pdfAction = new EventEmitter<PdfAction>()

  chatHistory: ChatMessageModel[] = []
  currentSession?: ChatSession
  numberOfChatsInHistory = 0
  showTyping = false
  debug = false
  toolSamplePromptsMap: Map<string, string[] | undefined> = new Map()

  private readonly _destroyed$ = new Subject()

  constructor(private readonly store: Store<RootState>) {}

  ngOnInit(): void {
    this.store
      .select(selectQueryResultHistory)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((chatHistory) => {
        this.handleChatHistoryUpdated(chatHistory)
      })
  }

  ngOnDestroy(): void {
    this._destroyed$.next()
    this._destroyed$.complete()
  }

  private handleChatHistoryUpdated(chatHistory: ChatMessageModel[]) {
    this.chatHistory = chatHistory

    // Prevent scrolling on the first change to allow the splash screen title
    if (this.chatHistory?.length === 0) {
      // Defer the scroll to the next JavaScript cycle so that the DOM has time to update
      setTimeout(() => this.scrollToTop())
      return
    }

    // If new messages are not added, do not scroll.
    // Stops the chat from scrolling when Feedback is sent.
    if (this.numberOfChatsInHistory === chatHistory.length) return
    this.numberOfChatsInHistory = chatHistory.length

    // Defer the scroll to the next JavaScript cycle so that the DOM has time to update
    setTimeout(() => this.scrollToTopOfLastMessage(), 500)
  }

  handleFeedback(feedback: UserFeedback | undefined, messageIndex: number) {
    this.store.dispatch(
      setMessageFeedback({
        messageIndex,
        feedback,
      })
    )
    this.store.dispatch(
      messageFeedbackAction({
        messageIndex,
        feedback,
      })
    )
  }

  scrollToTop(): void {
    try {
      this.chatContentContainer.nativeElement.scrollTop = 0
    } catch (err) {
      return
    }
  }

  scrollToTopOfLastMessage(): void {
    try {
      const chatContainer = this.chatContentContainer.nativeElement
      const lastChatMessage = chatContainer.children[0].lastElementChild

      chatContainer.scrollTop =
        chatContainer.scrollHeight - lastChatMessage.offsetHeight
    } catch (err) {
      return
    }
  }
}
