import { createSlice, Dictionary, PayloadAction } from '@reduxjs/toolkit'
import { setDigitalSpecialists } from '../digital-specialist'

export interface UserPolicy {
  isBetaUser: boolean
  isDebugToolsAccess: boolean
  isPromptTemplateAdmin: boolean
  isDigitalSpecialistAccess: boolean
  isDigitalSpecialistAdmin: boolean
}

export interface UserRedux {
  userId?: string
  email?: string
  policy: UserPolicy
  tenantId?: string
  clientId?: string
  isTosAccepted?: string
  digitalSpecialistTos?: Record<string, Date>
}

export const userInitialState: UserRedux = {
  userId: undefined,
  email: undefined,
  tenantId: undefined,
  clientId: undefined,
  isTosAccepted: undefined,
  digitalSpecialistTos: undefined,
  policy: {
    isBetaUser: false,
    isDebugToolsAccess: false,
    isPromptTemplateAdmin: false,
    isDigitalSpecialistAccess: false,
    isDigitalSpecialistAdmin: false,
  } as UserPolicy,
}

export const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload
    },
    setClientId: (state, action: PayloadAction<string>) => {
      state.clientId = action.payload
    },
    setTenantId: (state, action: PayloadAction<string>) => {
      state.tenantId = action.payload
    },
    setIsTosAccepted: (state, action: PayloadAction<string>) => {
      state.isTosAccepted = action.payload
    },
    setDigitalSpecialistTos: (state, action: PayloadAction<Record<string, Date>>) => {
      if (!state.digitalSpecialistTos) {
        state.digitalSpecialistTos = {};
      }
      state.digitalSpecialistTos = {
        ...state.digitalSpecialistTos,
        ...action.payload
      }
    },
    setUserPolicy: (state, action: PayloadAction<UserPolicy>) => {
      state.policy.isBetaUser = action.payload.isBetaUser
      state.policy.isDebugToolsAccess = action.payload.isDebugToolsAccess
      state.policy.isPromptTemplateAdmin = action.payload.isPromptTemplateAdmin
      state.policy.isDigitalSpecialistAccess =
        action.payload.isDigitalSpecialistAccess
      state.policy.isDigitalSpecialistAdmin =
        action.payload.isDigitalSpecialistAdmin
    },
  },
})

export const {
  setUserId,
  setUserEmail,
  setClientId,
  setTenantId,
  setIsTosAccepted,
  setDigitalSpecialistTos,
  setUserPolicy,
} = userSlice.actions

export default userSlice.reducer
