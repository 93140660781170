<nf-app-container
  *ngIf="isAuthComplete"
  appIconUrl="/assets/Crystal Transparent.png"
  [appTitle]="appTitle"
  [navs]="navLinks"
  [actions]="actions"
  [env]="env"
  [isBetaUser]="isBetaUser$ | async"
>
  <router-outlet></router-outlet>
</nf-app-container>
