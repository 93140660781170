import { Injectable } from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { environment } from 'environments/environment'

/**
 * Standard icons are here: https://fonts.google.com/icons?icon.set=Material+Icons
 *
 * Icon Registry - register your own custom icons
 * If the icon is a relative URL, you can bypass the security stuff because you know it won't have malicious XSS in it
 * We STRONGLY recommend self-hosting icons for apps.
 * WebComponents should also self-host, but they're loaded via http from a different origin than their own server, so their server has to support CORS
 *
 * Nginx snippet for supporting CORS for icon requests:
 *  (note that this only allows access to svg or png under /assets folder)
 * ```nginx.conf
 * location ~ /assets/.+\.(?:svg|png) {
 *      if ($request_method = 'OPTIONS') {
 *          add_header "Access-Control-Allow-Origin"  *;
 *          add_header "Access-Control-Allow-Methods" "GET, OPTIONS, HEAD";
 *          add_header "Access-Control-Allow-Headers" "Authorization, Origin, X-Requested-With, Content-Type, Accept";
 *          add_header Cache-Control "public, no-transform, max-age=600";
 *          return 200;
 *      }
 *      if ($request_method = 'GET') {
 *          add_header "Access-Control-Allow-Origin"  *;
 *          add_header Cache-Control "public, no-transform, max-age=600";
 *      }
 *      try_files $uri =404;
 *  }
 * ```
 */
@Injectable({
  providedIn: 'root',
})
export class MatIconRegistryService {
  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
  ) {}

  init() {
    //due to complexity of cors (changes depending on dev env), we'll use literals
    this.matIconRegistry.addSvgIconLiteral(
      `gpt_logo`,
      this.domSanitizer.bypassSecurityTrustHtml(GPT_LOGO_SVG_LITERAL)
    )

    /*
     * example of how to load icons over cors
     */
    const origin_url = environment.cwanGptUiBaseUrl
    const url = `${origin_url}/assets/cwan-gpt-logo.svg`
    this.matIconRegistry.addSvgIcon(
      `gpt_logo_cors`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(url)
    )
  }
}

const GPT_LOGO_SVG_LITERAL = `<?xml version="1.0" ?><svg width="1865" height="1865" viewBox="0 0 1865 1865" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1361.03 1446.43L1441.14 1271.59L1520.61 1446.43L1695.45 1525.91L1520.61 1606.02L1441.14 1780.23L1361.03 1606.02L1186.82 1525.91L1361.03 1446.43Z" fill="url(#paint0_linear_2092_1048117)"/>
<path d="M932.5 483.205L1055.42 809.58L1381.8 932.5L1055.42 1055.42L932.5 1381.8L809.58 1055.42L483.205 932.5L809.58 809.58L932.5 483.205ZM932.5 0L678.182 678.182L0 932.5L678.182 1186.82L932.5 1865L1186.82 1186.82L1865 932.5L1186.82 678.182L932.5 0Z" fill="url(#paint1_linear_2092_1048117)"/>
<defs>
<linearGradient id="paint0_linear_2092_1048117" x1="932.5" y1="0" x2="932.5" y2="1865" gradientUnits="userSpaceOnUse">
<stop stop-color="#0A5EB2"/>
<stop offset="1" stop-color="#00B2BA"/>
</linearGradient>
<linearGradient id="paint1_linear_2092_1048117" x1="932.5" y1="0" x2="932.5" y2="1865" gradientUnits="userSpaceOnUse">
<stop stop-color="#9747FF"/>
<stop offset="1" stop-color="#2196F3"/>
</linearGradient>
</defs>
</svg>
`
