import {
  ChatMessageModel,
  ChatOptions,
  ChatSession,
  ModelInfo,
} from '@cwan-gpt-ui/models'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { MessageFeedbackPayload } from './chat-session.actions'

export interface ChatSessionRedux {
  currentSession?: ChatSession
  numOutstandingRequests: number
}

export const chatSessionInitialState: ChatSessionRedux = {
  currentSession: undefined,
  numOutstandingRequests: 0,
}

export const chatSessionSlice = createSlice({
  name: 'chatSession',
  initialState: chatSessionInitialState,
  reducers: {
    setCurrentSession: (
      state,
      chatSession: PayloadAction<ChatSession | undefined>
    ) => ({
      ...chatSessionInitialState,
      currentSession: chatSession.payload,
    }),

    setCurrentSessionModel: (state, model: PayloadAction<ModelInfo>) => {
      if (!state.currentSession) return state
      const newSession: ChatSession = {
        ...state.currentSession,
        model: model.payload,
      }
      return {
        ...state,
        currentSession: newSession,
      } as ChatSessionRedux
    },

    setCurrentSessionOptions: (state, options: PayloadAction<ChatOptions>) => {
      if (!state.currentSession) return state
      const newSession: ChatSession = {
        ...state.currentSession,
        options: options.payload,
      }
      return {
        ...state,
        currentSession: newSession,
      } as ChatSessionRedux
    },

    appendMessage: (state, message: PayloadAction<ChatMessageModel>) => {
      if (!state.currentSession) return state

      const newChatHistory = [...state.currentSession.history, message.payload]
      const newSession: ChatSession = {
        ...state.currentSession,
        history: newChatHistory,
      }
      return {
        ...state,
        currentSession: newSession,
      } as ChatSessionRedux
    },

    clearSystemMessages: (state) => {
      if (!state.currentSession) return state

      const newChatHistory = state.currentSession.history.filter(
        (message) => message.role !== 'system'
      )
      const newSession: ChatSession = {
        ...state.currentSession,
        history: newChatHistory,
      }

      return {
        ...state,
        currentSession: newSession,
      } as ChatSessionRedux
    },

    setMessageFeedback: (
      state,
      feedback: PayloadAction<MessageFeedbackPayload>
    ) => {
      if (!state.currentSession) return state
      if (
        feedback.payload.messageIndex < 0 ||
        feedback.payload.messageIndex >= state.currentSession.history.length
      ) {
        console.warn('feedback ignored for invalid index')
        return state
      }

      const msgIndex = feedback.payload.messageIndex
      const message = state.currentSession.history[msgIndex]
      const newMessage = { ...message, feedback: feedback.payload.feedback }

      const newChatHistory = [...state.currentSession.history]
      newChatHistory[msgIndex] = newMessage

      const newSession: ChatSession = {
        ...state.currentSession,
        history: newChatHistory,
      }

      return {
        ...state,
        currentSession: newSession,
      } as ChatSessionRedux
    },
    /**
     * requestSent/responseReceived help keep track of how many outstanding requests we have
     * so we can show the "typing dots"
     * This is a reference counter because we can send two+ requests before receiving a response
     */
    requestSent: (state, sessionId: PayloadAction<string | undefined>) => {
      //ignore for non-session requests
      if (!sessionId.payload) return state
      if (!state.currentSession) return state

      //ignore if this is not for the current session
      if (sessionId.payload !== state.currentSession.id) return state
      return {
        ...state,
        numOutstandingRequests: state.numOutstandingRequests + 1,
      } as ChatSessionRedux
    },

    responseReceived: (state, sessionId: PayloadAction<string | undefined>) => {
      //ignore for non-session requests
      if (!sessionId.payload) return state
      if (!state.currentSession) return state
      //ignore if this is not for the current session
      if (sessionId.payload !== state.currentSession.id) return state
      return {
        ...state,
        numOutstandingRequests: state.numOutstandingRequests - 1,
      } as ChatSessionRedux
    },
  },
})

export const {
  setCurrentSession,
  setCurrentSessionModel,
  setCurrentSessionOptions,
  appendMessage,
  setMessageFeedback,
  clearSystemMessages,
  requestSent,
  responseReceived,
} = chatSessionSlice.actions

export default chatSessionSlice.reducer
