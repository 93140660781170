import { Component, OnInit } from '@angular/core'
import { PromptTemplate } from '@cwan-gpt-ui/models'
import { PromptTemplateService } from '@cwan-gpt-ui/services'
import {
  RootState,
  selectSelectedTemplate,
} from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'

@Component({
  selector: 'prompt-template',
  templateUrl: './prompt-template.component.html',
  styleUrls: ['./prompt-template.component.scss'],
})
export class PromptTemplateComponent implements OnInit {
  selectedPrompt?: PromptTemplate

  constructor(
    private readonly store: Store<RootState>,
    private readonly templateService: PromptTemplateService
  ) {}

  ngOnInit(): void {
    this.store.select(selectSelectedTemplate).subscribe((template) => {
      if (template) {
        this.selectedPrompt = template
      }
    })
  }
}
