// Angular imports
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'

// Third-party imports
import { Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

// Project-specific imports
import { ChatSession } from '@cwan-gpt-ui/models'
import { FileUploadService } from '@cwan-gpt-ui/services'
import {
  RootState,
  appendMessage,
  selectChatSession,
  setTemporaryFiles,
} from '@cwan-gpt-ui/state-management'

@Component({
  selector: 'temp-file-upload',
  templateUrl: './temp-file-upload.component.html',
  styleUrls: ['./temp-file-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TempFileUploadComponent implements OnInit, OnDestroy {
  constructor(
    private readonly fileUploadService: FileUploadService,
    private readonly toastr: ToastrService,
    private readonly store: Store<RootState>
  ) {}

  @Input() userFiles?: string[]
  selectedFiles = new UntypedFormControl()
  progress = 0

  disableButton = false
  showProgress = false
  trackProgressTimeout = 0
  timeout = 2000
  uploadStatusErrorCount = 0
  private readonly destroyed$ = new Subject<void>()
  private session?: ChatSession

  public form: UntypedFormGroup = new UntypedFormGroup({
    selectedFiles: this.selectedFiles,
  })
  resetForm() {
    this.disableButton = false
    this.progress = 0
    this.showProgress = false
    this.selectedFiles = new UntypedFormControl()
    this.form = new UntypedFormGroup({
      selectedFiles: this.selectedFiles,
    })
  }

  ngOnInit(): void {
    this.store
      .select(selectChatSession)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((session) => {
        this.session = session
      })
  }

  async onChange(selectedFiles: File[]) {
    this.disableButton = false
    const fileName = selectedFiles[0].name.replace(/[|+]/g, '_')
    const fileExists = this.userFiles?.includes(fileName)
    if (
      fileExists &&
      !confirm('File already exists. Do you want to replace existing file ?')
    ) {
      this.selectedFiles = new UntypedFormControl()
    }

    this.progress = 0
    this.disableButton = true
    this.showProgress = true
    const selectedFile = selectedFiles[0]
    const fileBlob = new Blob(
      [new Uint8Array(await selectedFile.arrayBuffer())],
      { type: selectedFile.type }
    )
    const formData = new FormData()
    formData.append(
      'file',
      fileBlob,
      this.changeFileExtensionToLowercase(fileName)
    )

    this.store.dispatch(
      appendMessage({
        role: 'assistant',
        content: `The process of uploading '${fileName}' has begun. You will be notified upon its completion.`,
      })
    )
    this.fileUploadService
      .uploadFile(formData, this.session?.id)
      .then((response) => {
        const jobId = response?.jobId
        if (jobId) this.trackUploadProgress(jobId, fileName)
      })
      .catch((error) => {
        if (error) {
          this.resetForm()
          this.toastr.error('File Upload Failed, please retry again.')
        }
      })
  }

  async trackUploadProgress(jobId: string, fileName: string) {
    try {
      const status = await this.fileUploadService.getUploadStatus(jobId)
      this.uploadStatusErrorCount = 0
      if (status.stage === 'ERROR') {
        this.resetForm()
        this.toastr.error('File Upload Failed, please retry again.')
        await this.fileUploadService.deleteFile(fileName)
      } else if (status.percent_complete === 100) {
        this.progress = 95
        setTimeout(() => {
          this.progress = 100
        }, 1000)
        setTimeout(() => {
          this.resetForm()
        }, 2000)

        this.toastr.success('File Uploaded successfully')
        this.store.dispatch(
          appendMessage({
            role: 'assistant',
            content: `File upload is complete!!`,
            uploadedFilename: fileName,
          })
        )
        this.store.dispatch(
          setTemporaryFiles({
            filenames: [fileName],
            sessionId: this.session?.id || '',
          })
        )
        this.disableButton = false
      } else {
        if (this.progress < 79) {
          this.progress = this.progress + 9
        }
        this.trackProgressTimeout = setTimeout(() => {
          this.timeout = Math.min(7000, this.timeout * 1.25)
          this.trackUploadProgress(jobId, fileName)
        }, this.timeout)
      }
    } catch (error) {
      console.error(error)
      if (++this.uploadStatusErrorCount < 4) {
        this.trackProgressTimeout = setTimeout(() => {
          this.trackUploadProgress(jobId, fileName)
        }, 2000 * this.uploadStatusErrorCount)
      } else {
        this.resetForm()
        this.toastr.error('File Upload Failed, please retry again.')
        await this.fileUploadService.deleteFile(fileName)
      }
    }
  }

  private changeFileExtensionToLowercase(filename: string) {
    const parts = filename.split('.')
    const extension = parts.pop()?.toLowerCase()
    return `${parts.join('.')}.${extension}`
  }

  ngOnDestroy(): void {
    clearTimeout(this.trackProgressTimeout)
  }
}
