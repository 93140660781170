<div class="modal-header">
  <h4 class="modal-title">{{ modalHeader }}</h4>
  <button
    nf-icon-button
    icon="close"
    class="btn-bare"
    ngbTooltip="Close"
    (click)="activeModal.dismiss()"
  >
    Close
  </button>
</div>
<div class="modal-body">
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink>Prompt Template</a>
      <ng-template ngbNavContent>
        <div class="toggle-wrapper">
          <nf-form-control label="Template Role" class="role-toggle">
            <nf-toggle
              [states]="roleHandlingToggleStates"
              [formControl]="roleHandlingToggle"
            ></nf-toggle>
          </nf-form-control>

          <nf-form-control
            label="Template Type"
            class="type-toggle"
            *ngIf="isPromptTemplateAdmin$ | async"
          >
            <nf-toggle
              [states]="typeHandlingToggleStates"
              [formControl]="typeHandlingToggle"
            ></nf-toggle>
          </nf-form-control>
        </div>

        <nf-form-control
          id="name"
          label="Name"
          [customErrorMessages]="customValidationMessages"
        >
          <input
            type="text"
            class="form-control"
            [formControl]="name"
            placeholder="Name your prompt here: ex) Follow-up Email"
            autofocus
            autocomplete="nope"
          />
        </nf-form-control>
        <nf-form-control label="Tags" compact>
          <ng-select
            [formControl]="tags"
            [items]="existingTags$ | async"
            [multiple]="true"
            class="multi-select"
            placeholder="Add tags"
            clearAllText="Clear"
            [clearable]="true"
            [clearOnBackspace]="true"
            [addTag]="true"
            [editableSearchTerm]="true"
          >
            <ng-template ng-multi-label-tmp>
              <div
                *ngIf="tags.value.length > 1; else singleItem"
                class="ng-value nf-custom-badge"
              >
                <span class="ng-value-label"
                  >{{ tags.value.length }} tags selected</span
                >
              </div>
              <ng-template #singleItem>
                <div class="ng-value nf-custom-badge">
                  <span class="ng-value-label">{{ tags.value[0] }}</span>
                </div>
              </ng-template>
            </ng-template>
          </ng-select>
        </nf-form-control>
        <nf-form-control label="Description">
          <textarea
            id="description"
            name="description"
            class="form-control"
            [formControl]="description"
            autocomplete="nope"
            rows="2"
            placeholder="Write a brief summary explaining how to use this template. Ex) ‘Use this template to request basic information from a financial report.’"
          ></textarea>
        </nf-form-control>
        <nf-form-control label="Prompt">
          <textarea
            id="prompt"
            name="template"
            class="form-control prompt-template"
            autocomplete="nope"
            rows="10"
            placeholder="Write your prompt here: Ex) You are a Reconciler. You have a professional tone of voice. Acknowledge the customer's issue or question, provide a helpful solution or response, and offer additional assistance or follow-up if needed. Write a 100-word follow-up email about the following issue/question: "
            [formControl]="template"
          ></textarea>
        </nf-form-control>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>Prompt Helper</a>
      <ng-template ngbNavContent>
        <p>
          When using Crystal, the quality and thoroughness of the input prompt
          has tremendous implications on the output responses. Select any option
          and fill out the prompt to suit your needs.
        </p>

        <div class="prompt-helper-container">
          <new-template-configuration-option
            *ngFor="let config of configurations.controls; let i = index"
            (delete)="removeConfigurationOption(i)"
            (changed)="handleConfigurationChange($event, i)"
            [input]="config.get('input')?.value"
            [configuration]="config.get('configuration')?.value"
            [registeredConfigurationValues]="registeredConfigurationValues"
          ></new-template-configuration-option>
          <!-- Keep this for adding a new row -->
          <new-template-configuration-option
            [isLastRow]="true"
            (changed)="handleAddConfiguration($event)"
            [registeredConfigurationValues]="registeredConfigurationValues"
          ></new-template-configuration-option>
        </div>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>
  <p class="template-content-footer">
    To learn more about how to improve your prompt engineering, visit our
    <!-- <a target="_blank" routerLink="/faq" class="links">FAQ Page</a> or -->
    <a
      target="_blank"
      href="https://cwan.atlassian.net/wiki/spaces/GAI/pages/364904691/CWAN-GPT+Prompt+Guide#General-Prompt-Tips"
      class="links"
      >Crystal Prompt Guide Page</a
    >.
  </p>

  <ng-container
    *ngIf="
      nav.activeId === 2 &&
      (name.errors?.required || name.hasError('nameExists'))
    "
  >
    <ngb-alert class="template-validation-message">
      <ng-container *ngIf="name.errors?.required">
        Please provide a name to save this template!
      </ng-container>
      <ng-container *ngIf="name.hasError('nameExists')">
        Please provide a unique name to save this template!
      </ng-container>
    </ngb-alert>
  </ng-container>
</div>

<div class="modal-footer">
  <button nf-button="cancel" (click)="activeModal.dismiss()"></button>
  <button
    nf-button="primary"
    (click)="save()"
    [disabled]="name.invalid"
    ngbTooltip="Please provide a name to save this template"
  >
    Save
  </button>
  <button
    nf-button="primary"
    (click)="save(true)"
    [disabled]="name.invalid"
    ngbTooltip="Please provide a name to save and apply this template"
  >
    Save & Apply
  </button>
</div>
