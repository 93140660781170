import { Injectable } from '@angular/core'
import {
  RootState,
  appendMemory,
  removeMemory,
  setMemories,
} from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import { HippocampusApi } from './hippocampus-api.service'

@Injectable({
  providedIn: 'root',
})
export class MemoryService {
  constructor(
    private readonly store: Store<RootState>,
    private readonly hippocampusApi: HippocampusApi
  ) {
    //TODO: save userid (apparently the API doesn't save user-specific memories yet)
  }

  async loadMemories(dsUsername: string): Promise<void> {
    const topNLongTerm = 100
    const memories = await this.hippocampusApi.getMemories(
      dsUsername,
      topNLongTerm
    )
    this.store.dispatch(setMemories(memories))
  }

  async deleteMemory(memoryId: string): Promise<void> {
    await this.hippocampusApi.deleteMemory(memoryId)
    this.store.dispatch(removeMemory(memoryId))
  }

  async insertMemory(dsUsername: string, content: string): Promise<void> {
    const memory = await this.hippocampusApi.insertMemory(dsUsername, content)
    this.store.dispatch(appendMemory(memory))
  }
}
