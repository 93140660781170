import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent {
  @Input() progress = 0
  @Input() showProgress = false
}
