<div class="container">
  <div class="header">
    <button
      mat-flat-button
      matTooltip="New chat sessions"
      class="btn-new-session"
      color="primary"
      aria-label="New chat session"
      (click)="handleCreateNewSession()"
    >
      <mat-icon>add_box</mat-icon>
      New Chat Session
    </button>
    <!-- this only shows in mobil mode, and any click in this view in mobile mode will hide the view -->
    <button
      mat-icon-button
      matTooltip="Close chat sessions sidebar"
      class="d-none btn-close"
      aria-label="Close chat session sidebar"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="card-containers">
    <div
      class="loader"
      [ngClass]="{ loading: isLoading }"
      *nfLoader="isLoading"
    ></div>
    <ng-container *ngFor="let session of sessions; let i = index">
      <!-- hide empty sessions -->
      <div
        *ngIf="session.history.length > 0"
        class="chat-card"
        (click)="handleSessionSelected(session)"
        [ngClass]="{ selected: session === currentSession }"
      >
        <p>
          {{ session.history[0].content }}
        </p>
        <span class="sub-data">
          <small>{{ session.createdAt | date : 'short' }}</small>
          <small class="right">{{ session.model.model }}</small>
        </span>
      </div>
    </ng-container>
  </div>
</div>
