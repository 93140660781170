import { Component, Inject, Input } from '@angular/core'
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import {
  ChatSession,
  DigitalSpecialistSession,
  PromptTemplate,
} from '@cwan-gpt-ui/models'
import { RootState } from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'

@Component({
  selector: 'apply-template-modal',
  templateUrl: './apply-template-modal.component.html',
  styleUrls: ['./apply-template-modal.component.scss'],
})
export class ApplyTemplateModalComponent {
  _openedTemplate?: PromptTemplate
  description?: string

  @Input() modalHeader = ''
  @Input() currentSession: ChatSession | DigitalSpecialistSession | undefined
  @Input() isSystemTemplate = false

  @Input() set openedTemplate(value: PromptTemplate | undefined) {
    this.prompt.setValue(value?.prompt)
    this._openedTemplate = {
      ...value,
      userConfiguration: {}, // added to prevent duplicate prompt values from service
      prompt: value?.prompt ?? '',
    } as PromptTemplate
    this.description = value?.description

    const variableNames = this.scanPromptForVariables(this.prompt.value)
    if (variableNames.length === 0) {
      this.addNewVariable()
    }

    variableNames.forEach((variableName) => {
      this.variables.push(
        new UntypedFormGroup({
          name: new UntypedFormControl(variableName),
          value: new UntypedFormControl(''),
        })
      )
    })
  }
  prompt = new UntypedFormControl('')
  variables = new UntypedFormArray([])

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly store: Store<RootState>,
    public dialogRef: MatDialogRef<ApplyTemplateModalComponent>
  ) {}

  onApply(newChat: boolean) {
    if (this._openedTemplate) {
      const promptValue = this.prompt.value
      if (promptValue !== this._openedTemplate.prompt) {
        this._openedTemplate.prompt = promptValue
      }

      if (this.variables) {
        this._openedTemplate.prompt = this.applyVariablesToPrompt()
      }
    }

    this.dialogRef.close({
      template: this._openedTemplate,
      createNewChat: newChat,
    })
  }

  addNewVariable() {
    this.variables.push(
      new UntypedFormGroup({
        name: new UntypedFormControl(''),
        value: new UntypedFormControl(''),
      })
    )
  }

  deleteVariable(index: number) {
    this.variables.removeAt(index)
  }

  handleVariableChange(value: { name: string; value: string }, index: number) {
    const group = this.variables.at(index) as UntypedFormGroup
    group.controls.name.setValue(value.name)
    group.controls.value.setValue(value.value)
  }

  applyVariablesToPrompt(): string {
    let newPrompt = this.prompt.value
    this.variables.value.forEach(
      (variable: { name: string; value: string }) => {
        const regex = new RegExp(`{${variable.name}}`, 'g')
        newPrompt = newPrompt.replace(regex, variable.value)
      }
    )
    return newPrompt
  }

  scanPromptForVariables(prompt: string): string[] {
    const regex = /{([^:{}]+)}/g
    const matches = []
    let match

    while ((match = regex.exec(prompt)) !== null) {
      if (!/^ *: *$/.test(match[1])) {
        // Check if it's not an empty JSON object
        matches.push(match[1])
      }
    }

    return matches
  }
}
