<form
  NfFormValidation
  novalidate
  (validSubmit)="submit($event)"
  [formGroup]="form"
  class="file-upload"
>
  <div class="form-row">
    <nf-form-control class="col flex-fill">
      <nf-file-select
        count="1"
        [title]="title"
        noDuplicate
        [extensions]="extensions"
        [accept]="accept"
        [formControl]="selectedFiles"
        (selectedFiles)="onChange($event)"
        size="250000000"
      >
        <div class="file-upload-info">Drag or click to upload a document.</div>
      </nf-file-select>
    </nf-form-control>
  </div>
  <app-progress
    class="file-upload-progress"
    [progress]="progress"
    [showProgress]="showProgress"
  ></app-progress>
  <button nf-button="submit" [disabled]="!selectedFiles.value?.length || disableButton"></button>

</form>
