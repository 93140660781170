import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'typing-dots',
  templateUrl: './typing-dots.component.html',
  styleUrls: [`./typing-dots.component.scss`],
})
export class TypingDotsComponent implements OnInit {
  messageUpdate = ''

  ngOnInit(): void {
    setInterval(() => {
      this.messageUpdate = 'Still working on it'
    }, 40000)
  }
}
