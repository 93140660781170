import { Injectable } from '@angular/core'
import { AvailableAction, ModelInfo } from '@cwan-gpt-ui/models'
import {
  DsResponseReceivedPayload,
  MessageFeedbackPayload,
  ResponseErrorPayload,
  ResponseReceivedPayload,
  RootState,
  dsResponseReceivedAction,
  messageFeedbackAction,
  responseErrorAction,
  responseReceivedAction,
  selectChatModel,
  selectChatSessionId,
} from '@cwan-gpt-ui/state-management'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { FullStoryService } from './fullstory'

@Injectable({
  providedIn: 'root',
})
export class TelemetryService {
  private modelInfo?: ModelInfo
  private sessionId?: string

  constructor(
    private readonly fullStoryService: FullStoryService,
    private readonly actions$: Actions,
    private readonly store: Store<RootState>
  ) {}

  init() {
    this.store
      .select(selectChatSessionId)
      .subscribe((sessionId) => (this.sessionId = sessionId))

    this.store
      .select(selectChatModel)
      .subscribe((modelInfo) => (this.modelInfo = modelInfo))

    this.actions$
      .pipe(ofType(responseReceivedAction))
      .subscribe((payload) => this.handleResponseReceived(payload))

    this.actions$
      .pipe(ofType(dsResponseReceivedAction))
      .subscribe((payload) => this.handleDsResponseReceived(payload))

    this.actions$
      .pipe(ofType(responseErrorAction))
      .subscribe((payload) => this.handleResponseError(payload))

    this.actions$
      .pipe(ofType(messageFeedbackAction))
      .subscribe((payload) => this.handleFeedback(payload))
  }

  handleResponseReceived(payload: ResponseReceivedPayload) {
    this.fullStoryService.emitCustomEvent('cwanGptChatResponse', {
      chatModel: payload.chatResponse.model,
      promptTokens: payload.chatResponse.usage.promptTokens,
      completionTokens: payload.chatResponse.usage.completionTokens,
      durationMs: payload.durationMs,
      numCitations: payload.chatResponse.message.citations
        ? payload.chatResponse.message.citations.length
        : 0,
    })
  }

  handleDsResponseReceived(payload: DsResponseReceivedPayload) {
    this.fullStoryService.emitCustomEvent('cwanGptDsChatResponse', {
      dsUsername: payload.dsUsername,
      durationMs: payload.durationMs,
      sessionId: payload.sessionId,
      tools: payload.chatResponse.message.metadata?.tools?.join(', '),
      skills: payload.chatResponse.message.metadata?.skills?.join(', '),
    })
  }

  handleResponseError(payload: ResponseErrorPayload) {
    this.fullStoryService.emitCustomEvent('cwanGptChatResponseError', {
      chatModel: this.modelInfo?.model,
      sessionId: payload.sessionId,
      dsUsername: payload.dsUsername,
      durationMs: payload.durationMs,
    })
  }

  handlePromptSuggestion(action: AvailableAction) {
    if (action.type === 'promptSuggestion') {
      this.fullStoryService.emitCustomEvent('cwanGptPromptSuggestionClicked', {
        content: action.params['content'],
      })
    }
  }

  handleFeedback(payload: MessageFeedbackPayload) {
    this.fullStoryService.emitCustomEvent('cwanGptChatResponseFeedback', {
      chatModel: this.modelInfo?.model,
      feedback: payload.feedback,
      messageIndex: payload.messageIndex,
      sessionId: this.sessionId,
    })
  }
}
