import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core'
import { Tool } from '@cwan-gpt-ui/models'
import { ToggleStates } from '@northfork/ng-basics'
import { keys, pickBy } from 'lodash-es'

@Component({
  selector: 'chat-data-sources',
  templateUrl: './chat-data-sources.component.html',
  styleUrls: [`./chat-data-sources.component.scss`],
})
export class ChatDataSourcesComponent implements OnChanges {
  @Output() updateSource = new EventEmitter<string[]>()

  sourcesValue: { [key: string]: boolean } = {}
  selectedSources: Tool[] = []

  @Input() isLoading = false
  @Input() sources?: Tool[] | null = []
  @Input() selected: string[] = []

  states: ToggleStates = {
    on: '',
    off: '',
  }
  ngOnChanges(): void {
    if (this.sources) {
      this.sources.forEach((source: Tool) => {
        this.sourcesValue[source.name] = this.selected.includes(source.name)
      })
    }
  }

  toggleSource(source: Tool) {
    if (source.isDisabled) {
      return
    }
    if (source.exclusiveSelection) {
      this.sources?.forEach((s: Tool) => {
        s.isDisabled =
          s.name !== source.name && !this.sourcesValue[source?.name]
        if (s.isDisabled) {
          this.sourcesValue[s?.name] = false
        }
      })
    }
    this.sourcesValue[source?.name] = !this.sourcesValue[source?.name]
    this.updateSource.emit(keys(pickBy(this.sourcesValue, (v: any) => v)))
  }
}
