<!-- <div>
  <ng-select
    [(ngModel)]="selectedSpecialists"
    [items]="specialists"
    bindLabel="name"
    bindValue="username"
    placeholder="Filter by digital specialist (broken :c)..."
    [multiple]="true"
  >
    <ng-template ng-option-tmp let-item="item">
      <div [ngbTooltip]="item" container="body" class="ellipsis">
        {{ item.username }}
      </div>
    </ng-template>
  </ng-select>
</div> -->
<!-- Above template for digital specialists -->

<ng-select
  [items]="null"
  bindLabel="name"
  bindValue="id"
  [addTag]="addClientId"
  placeholder="Filter by root ID..."
  [multiple]="false"
  [(ngModel)]="clientId"
>
</ng-select>
<div>
  <ng-select
    [items]="null"
    bindLabel="name"
    bindValue="id"
    [addTag]="addUserId"
    [multiple]="true"
    placeholder="Filter user IDs..."
    [(ngModel)]="userIds"
  >
  </ng-select>
</div>
<div>
  <ng-select
    [items]="null"
    bindLabel="name"
    bindValue="id"
    [addTag]="addCustomWord"
    [multiple]="true"
    placeholder="Filter keywords..."
    [(ngModel)]="keywordSearch"
  >
  </ng-select>
</div>

<div>
  <ng-select
    [(ngModel)]="selectedModels"
    [items]="llmModels"
    bindLabel="model"
    bindValue="model"
    placeholder="Filter by model..."
    [multiple]="true"
  >
    <ng-template ng-option-tmp let-item="item">
      <div [ngbTooltip]="item" container="body" class="ellipsis">
        {{ item.model }}
      </div>
    </ng-template>
  </ng-select>
</div>
<div>
  <mat-form-field appearance="fill">
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input
      [formGroup]="range"
      [rangePicker]="picker"
      [min]="minDate"
      [max]="maxDate"
    >
      <input matStartDate formControlName="start" placeholder="Start date" />
      <input matEndDate formControlName="end" placeholder="End date" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker>
      <mat-date-range-picker-actions>
        <button mat-button matDateRangePickerCancel (click)="refreshDates()">
          Cancel
        </button>
        <button mat-raised-button color="primary" matDateRangePickerApply>
          Apply
        </button>
      </mat-date-range-picker-actions>
    </mat-date-range-picker>

    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')"
      >Invalid start date</mat-error
    >
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
      >Invalid end date</mat-error
    >
  </mat-form-field>
</div>
<div id="checkboxes">
  <mat-checkbox [(ngModel)]="isLiked"> {{ 'Liked Sessions' }} </mat-checkbox>
  <mat-checkbox [(ngModel)]="isDisliked">
    {{ 'Disliked Sessions' }}
  </mat-checkbox>
  <mat-checkbox [(ngModel)]="isExternal">
    {{ 'External Sessions' }}
  </mat-checkbox>
</div>
<button mat-mini-fab color="primary" aria-label="Send" (click)="onClick()">
  Filter
  <span
    matRipple
    class="mat-ripple mat-button-ripple mat-button-ripple-round"
  ></span>
</button>
