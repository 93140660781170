import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'variable-substitution',
  templateUrl: './variable-substitution.component.html',
  styleUrls: ['./variable-substitution.component.scss'],
})
export class VariableSubstitutionComponent implements OnInit, OnDestroy {
  @Output() delete = new EventEmitter<string>()
  @Output() add = new EventEmitter<void>()
  @Output() changed = new EventEmitter<{
    name: string
    value: string
  }>()

  @Input() set variableName(variableName: string) {
    this.variableNameForm.setValue(variableName)
  }
  @Input() set variableValue(variableValue: string) {
    this.variableValueForm.setValue(variableValue)
  }

  variableNameForm = new UntypedFormControl('', [])
  variableValueForm = new UntypedFormControl('', [])

  form = new UntypedFormGroup({
    variableName: this.variableNameForm,
    variableValue: this.variableValueForm,
  })

  private readonly _destroyed$ = new Subject<void>()

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((values) => {
        this.changed.next({
          name: values.variableName ?? '',
          value: values.variableValue ?? '',
        })
      })
  }

  ngOnDestroy(): void {
    this._destroyed$.next()
    this._destroyed$.complete()
  }

  handleDelete() {
    this.delete.emit()
  }

  handleAdd() {
    this.add.emit()
  }
}
