import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { CWMarkdownComponent } from './cw-markdown/cw-markdown.component'

@NgModule({
  declarations: [CWMarkdownComponent],
  imports: [
    // Angular Dependencies
    CommonModule,
  ],
  exports: [CWMarkdownComponent],
})
export class DesignModule {}
