export interface PromptTemplate {
  id: string // uuid
  name: string
  prompt: string
  showInMenu: boolean
  type: 'preset' | 'custom'
  role: 'system' | 'user'
  default: boolean
  description?: string
  tags: string[]
  // Only used on the UI
  userConfiguration?: UserConfiguration | null
  createdAt: Date
}

export interface PromptHelperConfiguration {
  name: string
  value: string
  placeholder: string
}

export interface UserConfiguration {
  tone?: string
  format?: string
  act_as?: string
  objective?: string
  context?: string
  scope?: string
  keywords?: string
  limitations?: string
  examples?: string
  deadline?: string
  audience?: string
  language?: string
  citations?: string
  points_of_view?: string
  counterarguments?: string
  terminology?: string
  analogies?: string
  quotes?: string
  statistics?: string
  visual_elements?: string
  call_to_action?: string
  sensitivity?: string
  humor?: string
  storytelling?: string
  cultural_references?: string
  ethical_considerations?: string
  personalization?: string
  confidentiality?: string
  revision_requirements?: string
  formatting?: string
  hypothetical_scenarios?: string
  historical_context?: string
  future_implications?: string
  case_studies?: string
  faqs?: string
  problem_solving?: string
  comparison?: string
  anecdotes?: string
  metaphors?: string
  pro_con_analysis?: string
  timelines?: string
  trivia?: string
  lessons_learned?: string
  strengths_and_weaknesses?: string
  summary?: string
  best_practices?: string
  step_by_step_guide?: string
  tips_and_tricks?: string
  [key: string]: string | undefined
}

export interface PromptRequest {
  name: string
  tags: string[]
  prompt: string
}
