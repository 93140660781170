import { Injectable } from '@angular/core'
import {
  ChatFilter,
  ChatRequest,
  ChatResponse,
  ChatSession,
  GradedResponse,
  ModelInfo,
  ToolConfig,
  UserPolicyResponse,
} from '@cwan-gpt-ui/models'
import { CwanGptApi } from './cwan-gpt-api.service'

/**
 * Fake impl used for tests or isolated development
 */
@Injectable({
  providedIn: 'root',
})
export class CwanGptWsFake implements CwanGptApi {
  sendFilterRequest(
    chatFilter: ChatFilter
  ): Promise<[ChatSession[], ChatSession[]]> {
    throw new Error('Method not implemented.')
  }

  sendGradedResponse(response: GradedResponse): Promise<void> {
    throw new Error('Method not implemented.')
  }

  usePublicApi() {
    //no-op
  }
  async isVpn(): Promise<boolean> {
    return Promise.resolve(true)
  }

  sendMessage(request: ChatRequest): Promise<ChatResponse> {
    return Promise.resolve({
      created: '2023-08-03T17:53:16Z',
      model: 'gpt-4o-mini',
      message: { role: 'assistant', content: 'hello world!' },
      usage: {
        responseTime: 200,
        completionTokens: 32,
        promptTokens: 21,
        totalTokens: 53,
      },
    })
  }

  sendDsMessage(
    request: ChatRequest,
    sessionId: string,
    dsUsername: string
  ): Promise<ChatResponse> {
    return Promise.resolve({
      created: '2023-08-03T17:53:16Z',
      model: 'gpt-4o-mini',
      message: { role: 'assistant', content: 'hello world!' },
      usage: {
        responseTime: 200,
        completionTokens: 32,
        promptTokens: 21,
        totalTokens: 53,
      },
    })
  }

  sendMessageWithoutSession(request: ChatRequest): Promise<ChatResponse> {
    return Promise.resolve({
      created: '2023-08-03T17:53:16Z',
      model: 'gpt-4o-mini',
      message: { role: 'assistant', content: 'hello world!' },
      usage: {
        responseTime: 200,
        completionTokens: 32,
        promptTokens: 21,
        totalTokens: 53,
      },
    })
  }

  getModels(): Promise<ModelInfo[]> {
    return Promise.resolve([
      { deploymentName: 'gpt-4o-mini', modelName: 'gpt-4o-mini' },
      { deploymentName: 'gpt-4', modelName: 'gpt-4' },
    ])
  }

  getDatasources(): Promise<ToolConfig[]> {
    return Promise.resolve([
      {
        toolId: 'tool_1',
        name: 'Tool 1',
        type: 'core',
        description: 'tool 1',
        usageDescription: 'tool 1',
        uri: '',
        isPlaintext: false,
        samplePrompts: [],
      },
      {
        toolId: 'tool_2',
        name: 'Tool 2',
        type: 'core',
        description: 'tool 2',
        usageDescription: 'tool 2',
        uri: '',
        isPlaintext: false,
        samplePrompts: [],
      },
    ])
  }

  getToolPrompts(): Promise<Map<string, string[]>> {
    return Promise.resolve(new Map([['tool_1', ['example1', 'example2']]]))
  }

  getUserPolicy(): Promise<UserPolicyResponse> {
    return Promise.resolve({
      hasBetaFeatureAccess: true,
      hasDebugToolsAccess: true,
      hasPromptTemplateAdmin: false,
      hasDigitalSpecialistAccess: false,
      hasDigitalSpecialistAdmin: false,
    })
  }
}
