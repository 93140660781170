import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms'
import { Router } from '@angular/router'
import {
  PromptHelperConfiguration,
  PromptTemplate,
  UserConfiguration,
} from '@cwan-gpt-ui/models'
import { PromptTemplateService } from '@cwan-gpt-ui/services'
import {
  RootState,
  selectIsPromptAdmin,
  setAppliedTemplate,
} from '@cwan-gpt-ui/state-management'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngrx/store'
import { ErrorMessage, ToggleStates } from '@northfork/ng-basics'
import { Observable, Subject } from 'rxjs'
import { debounceTime, takeUntil } from 'rxjs/operators'
import {
  nameExistsToUpdateValidator,
  nameExistsValidator,
} from '../utils/template-name-validator'

@Component({
  selector: 'new-template-modal',
  templateUrl: './new-template-modal.component.html',
  styleUrls: ['./new-template-modal.component.scss'],
})
export class NewTemplateModalComponent implements OnInit, OnDestroy {
  existingTemplateNames: Set<string> = new Set()

  name = new UntypedFormControl('', [Validators.required])
  template = new UntypedFormControl('')
  tags = new UntypedFormControl([])
  description = new UntypedFormControl('')
  configurations = new UntypedFormArray([])
  registeredConfigurationValues: string[] = []
  newTemplate: PromptTemplate | undefined
  templateRole = 'user'
  templateType = 'custom'

  roleHandlingToggle = new UntypedFormControl(false)
  roleHandlingToggleStates: ToggleStates = {
    on: 'System',
    off: 'User',
  }

  typeHandlingToggle = new UntypedFormControl(false)
  typeHandlingToggleStates: ToggleStates = {
    on: 'Preset',
    off: 'Custom',
  }

  @Input() modalHeader = ''
  @Input() isEditing = false

  @Input() set existingTemplate(value: PromptTemplate) {
    this.name.setValue(value.name)
    this.template.setValue(value.prompt)
    this.description.setValue(value.description)
    this.tags.setValue(value.tags)
    if (value.role === 'system') {
      this.roleHandlingToggle.setValue(true)
      this.templateRole = 'system'
    } else {
      this.roleHandlingToggle.setValue(false)
      this.templateRole = 'user'
    }
    if (value.type === 'preset') {
      this.typeHandlingToggle.setValue(true)
      this.templateType = 'preset'
    } else {
      this.typeHandlingToggle.setValue(false)
      this.templateType = 'custom'
    }
    this.configurations.clear()
    // Get a key value pair for each configuration into an array
    const configurations = Object.keys(value.userConfiguration ?? {}).map(
      (x) => ({ name: x, value: (value.userConfiguration as any)[x] })
    )

    configurations.forEach((x) => {
      this.configurations.push(
        new UntypedFormGroup({
          input: new UntypedFormControl(x.value),
          configuration: new UntypedFormControl(x.name),
        })
      )
    })
  }

  form = new UntypedFormGroup({
    name: this.name,
    template: this.template,
    description: this.description,
    configurations: this.configurations,
    roleHandlingToggle: this.roleHandlingToggle,
    typeHandlingToggle: this.typeHandlingToggle,
    tags: this.tags,
  })

  customValidationMessages: ErrorMessage[] = [
    {
      error: 'nameExists',
      format: (label?: string): string => {
        const fieldName = label ?? 'Name'
        return `${fieldName} already exists`
      },
    },
  ]

  private readonly _destroyed$ = new Subject<void>()
  isPromptTemplateAdmin$: Observable<boolean>
  existingTags$: Observable<string[]>

  constructor(
    public activeModal: NgbActiveModal,
    private readonly store: Store<RootState>,
    private readonly router: Router,
    private readonly templateService: PromptTemplateService
  ) {
    this.isPromptTemplateAdmin$ = this.store.select(selectIsPromptAdmin)
    this.existingTags$ = this.templateService.getAllTemplateTags$()
  }

  ngOnInit(): void {
    this.loadTemplates()

    const allTemplates$ = this.templateService.getAllTemplates$()
    allTemplates$.pipe(takeUntil(this._destroyed$)).subscribe((templates) => {
      this.createUniqueNameValidator(templates)
    })

    this.name.valueChanges
      .pipe(debounceTime(500), takeUntil(this._destroyed$))
      .subscribe(() => {
        this.name.updateValueAndValidity()
      })

    this.configurations.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((values) => {
        this.registeredConfigurationValues = values.map(
          (x: { configuration: PromptHelperConfiguration }) =>
            x.configuration.value
        )
      })

    this.roleHandlingToggle.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((isSystemPrompt) => {
        if (isSystemPrompt) {
          this.templateRole = 'system'
        } else {
          this.templateRole = 'user'
        }
      })

    this.typeHandlingToggle.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((isPresetPrompt) => {
        if (isPresetPrompt) {
          this.templateType = 'preset'
        } else {
          this.templateType = 'custom'
        }
      })
  }

  ngOnDestroy(): void {
    this._destroyed$.next()
    this._destroyed$.complete()
  }

  loadTemplates() {
    this.templateService.fetchAllTemplates()
  }

  save(apply?: boolean) {
    if (this.form.valid) {
      const helperConfigurations = this.configurations.value as Array<{
        input: string
        configuration: PromptHelperConfiguration
      }>

      const userConfiguration: UserConfiguration = {}

      helperConfigurations.forEach(
        (row: { input: string; configuration: PromptHelperConfiguration }) => {
          userConfiguration[row.configuration.value] = row.input
        }
      )

      const templateData = {
        name: this.name.value,
        prompt: this.template.value,
        description: this.description.value,
        userConfiguration,
        showInMenu: true,
        type: this.templateType,
        role: this.templateRole,
        tags: this.tags.value,
      } as PromptTemplate
      this.activeModal.close(templateData)

      if (apply) {
        this.store.dispatch(setAppliedTemplate({ template: templateData }))
        this.router.navigate(['/'])
      }
    } else {
      this.form.markAllAsTouched()
    }
  }

  removeConfigurationOption(index: number) {
    this.configurations.removeAt(index)
  }

  removeTag(index: number) {
    this.tags.value.removeAt(index)
  }

  removeTags() {
    this.tags.setValue([])
  }

  handleConfigurationChange(
    value: {
      input: string
      configuration: PromptHelperConfiguration
    },
    index: number
  ) {
    const group = this.configurations.at(index) as UntypedFormGroup
    group.controls.input.setValue(value.input)
    group.controls.configuration.setValue(value.configuration)
  }

  handleAddConfiguration(value: {
    input: string
    configuration: PromptHelperConfiguration
  }) {
    this.configurations.push(
      new UntypedFormGroup({
        input: new UntypedFormControl(value.input, []),
        configuration: new UntypedFormControl(value.configuration, [
          Validators.required,
        ]),
      })
    )
  }

  createUniqueNameValidator(templates: PromptTemplate[]) {
    this.existingTemplateNames = new Set(
      templates.map((template) => template.name)
    )
    if (this.isEditing) {
      this.name.setAsyncValidators([
        nameExistsToUpdateValidator(
          this.name.value,
          this.existingTemplateNames
        ),
      ])
    } else {
      this.name.setAsyncValidators([
        nameExistsValidator(this.existingTemplateNames),
      ])
    }
  }
}
