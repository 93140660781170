<ng-container *ngIf="showPdfCtas">
  <div class="file-action-buttons">
    <div class="btn-group">
      <button
        class="chat-send-button btn-bare"
        nf-icon-button="primary"
        icon="help"
        type="button"
        class="btn btn-default border border-primary m-1"
      ></button>
      <button
        (click)="handlePdfActions('Summarize')"
        type="button"
        [disabled]="isAwaitingResponse"
        class="btn btn-default border text-primary border-primary rounded m-1"
      >
        Summarize
      </button>
      <button
        (click)="handlePdfActions(SUGGEST_LIST_OF_QUESTIONS)"
        type="button"
        [disabled]="isAwaitingResponse"
        class="btn btn-default border text-primary border-primary rounded m-1"
      >
        Suggest Questions
      </button>
    </div>
  </div>
</ng-container>
