import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatTableModule } from '@angular/material/table'
import { Route, RouterModule } from '@angular/router'
import { AiReconcilerComponent } from './ai-reconciler.component'
import { AiReconcilerAutoIgnoreComponent } from './components/auto-ignore/auto-ignore.component'
import { AiReconcilerDashboardComponent } from './components/dashboard/dashboard.component'

const routes: Route[] = [
  {
    path: 'ai-reconciler',
    component: AiReconcilerComponent,
    children: [
      {
        path: '',
        component: AiReconcilerDashboardComponent,
      },
      {
        path: 'auto-ignore',
        pathMatch: 'full',
        component: AiReconcilerAutoIgnoreComponent,
      },
      {
        path: 'auto-enter',
        pathMatch: 'full',
        component: AiReconcilerComponent,
      },
      {
        path: 'auto-match',
        pathMatch: 'full',
        component: AiReconcilerComponent,
      },
    ],
  },
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,

    // Material
    MatListModule,
    MatButtonModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  declarations: [
    AiReconcilerComponent,
    AiReconcilerDashboardComponent,
    AiReconcilerAutoIgnoreComponent,
  ],
  exports: [AiReconcilerComponent],
})
export class AiReconcilerModule {}
