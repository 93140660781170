import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import chatSessionListReducer from './chat-session/chat-session-list.reducer'
import chatSessionReducer from './chat-session/chat-session.reducer'
import copilotReducer from './copilot/copilot.reducer'
import debugToolsReducer from './debug-tools/debug-tools.reducer'
import digitalSpecialistReducer from './digital-specialist/digital-specialist.reducer'
import fileUploadReducer from './file-upload/file-upload.reducer'
import monitorChatReducer from './private-mode/private-mode.reducer'
import promptTemplatesReducer from './prompt-templates/prompt-templates.reducer'
import queryNavbarEvaluatedListReducer from './query-navbar-evaluated/query-navbar-evaluated-list.reducer'
import queryNavbarListReducer from './query-navbar/query-navbar-list.reducer'
import queryNavbarReducer from './query-navbar/query-navbar.reducer'
import userReducer from './user/user.reducer'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('user', userReducer),
    StoreModule.forFeature('templates', promptTemplatesReducer),
    StoreModule.forFeature('fileUpload', fileUploadReducer),
    StoreModule.forFeature('debugTools', debugToolsReducer),
    StoreModule.forFeature('privateMode', monitorChatReducer),
    StoreModule.forFeature('chatSession', chatSessionReducer),
    StoreModule.forFeature('chatSessionList', chatSessionListReducer),
    StoreModule.forFeature('copilot', copilotReducer),
    StoreModule.forFeature('digitalSpecialist', digitalSpecialistReducer),
    StoreModule.forFeature('queryNavBar', queryNavbarReducer),
    StoreModule.forFeature('queryNavBarList', queryNavbarListReducer),
    StoreModule.forFeature('queryNavBarEvaluated', queryNavbarReducer),
    StoreModule.forFeature(
      'queryNavBarEvaluatedList',
      queryNavbarEvaluatedListReducer
    ),
    StoreModule.forRoot([], {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot([]),
  ],
})
export class StateManagementModule {}
