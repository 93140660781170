<div class="source" *ngFor="let item of sources">
  {{ item.name.replaceAll('_', ' ') }}
  <nf-form-control class="btn-toggle" [for]="item.name">
    <nf-toggle
      [elId]="item.name"
      [states]="states"
      [disabled]="item.isDisabled ? 'disabled' : ''"
      [ngModel]="sourcesValue[item.name]"
      (click)="toggleSource(item)"
    ></nf-toggle
  ></nf-form-control>
</div>
