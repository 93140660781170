import { Component, EventEmitter, Input, Output } from '@angular/core'
import { AvailableAction } from '@cwan-gpt-ui/models'

@Component({
  selector: 'chat-message-action',
  templateUrl: './chat-message-action.component.html',
  styleUrls: [`./chat-message-action.component.scss`],
})
export class ChatMessageActionComponent {
  availableAction?: AvailableAction
  availableActionNumber = 0

  title = ''

  @Input() set action(value: AvailableAction) {
    this.availableAction = value
    this.title = value.title
  }

  @Input() set index(i: number) {
    this.availableActionNumber = i + 1
  }

  @Output()
  actionClicked = new EventEmitter<AvailableAction>()

  onActionClick() {
    this.actionClicked.emit(this.availableAction)
  }
}
