import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { ChatFilter, DigitalSpecialist, ModelInfo } from '@cwan-gpt-ui/models'
import {
  ChatService,
  DigitalSpecialistService,
  LoadingWheelService,
  PromptTemplateService,
} from '@cwan-gpt-ui/services'
import {
  RootState,
  selectActiveDigitalSpecialists,
  selectDigitalSpecialists,
  selectHasDigitalSpecialistAdmin,
  updateEvaluatedSessionsList,
  updateQuerySessionList,
} from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { CwanGptApi } from '@cwan-gpt-ui/services'

import { FormControl, FormGroup } from '@angular/forms'
@Component({
  selector: 'chat-filter',
  templateUrl: './chat-filter.component.html',
  styleUrls: ['./chat-filter.component.scss'],
})
export class ChatFilterComponent implements OnInit, OnDestroy {
  @Output() sendFilter = new EventEmitter<ChatFilter>()

  keywordSearch: string[] = []
  userIds: string[] = []
  isLiked = false
  isDisliked = false
  isExternal = false
  llmModels: ModelInfo[] = []
  selectedModels: string[] = []
  clientId: string | null = null

  // below section is unused FOR NOW
  selectedSpecialists: string[] = []
  specialists: DigitalSpecialist[] = []
  //
  private subscription: Subscription = new Subscription()

  private readonly _destroyed$ = new Subject()
  filter = ''

  isAdmin = false

  minDate: Date = new Date(2024, 0, 1)
  maxDate: Date = new Date()

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  })

  constructor(
    private readonly chatService: ChatService,
    private readonly dsService: DigitalSpecialistService,
    private readonly store: Store<RootState>,
    private readonly templateService: PromptTemplateService,
    private readonly cwanGptApi: CwanGptApi,
    private readonly loadingService: LoadingWheelService
  ) {}

  onClick() {
    this.loadingService.loadingStatus(true)
    const data = {
      keywordSearch: this.keywordSearch,
      userIds: this.userIds,
      clientId: this.clientId ?? '',
      isLiked: this.isLiked,
      isDisliked: this.isDisliked,
      isExternal: this.isExternal,
      specialists: this.selectedSpecialists,
      llmModels: this.selectedModels,
      startDate: this.range.value.start,
      endDate: this.range.value.end,
    }
    const chatFilter: ChatFilter = data
    this.cwanGptApi.sendFilterRequest(chatFilter).then((res) => {
      const queryChatSessions = res
      this.store.dispatch(updateQuerySessionList(queryChatSessions[0]))
      this.store.dispatch(updateEvaluatedSessionsList(queryChatSessions[1]))
      this.loadingService.loadingStatus(false)
    })
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
    this._destroyed$.next()
    this._destroyed$.complete()
  }
  ngOnInit(): void {
    this.dsService.loadAllSpecialists()
    this.templateService.fetchAllTemplates()
    this.chatService
      .getAvailableModels()
      .then((models) => (this.llmModels = models))
    this.store
      .select(selectHasDigitalSpecialistAdmin)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((hasAdmin) => {
        this.isAdmin = hasAdmin
        if (hasAdmin) {
          this.loadAdminSpecialists()
        } else {
          this.loadNonAdminSpecialists()
        }
      })
  }

  loadNonAdminSpecialists() {
    this.store
      .select(selectActiveDigitalSpecialists)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(
        (data) =>
          (this.specialists = data.sort((a, b) =>
            a.fullName.localeCompare(b.fullName)
          ))
      )
  }

  loadAdminSpecialists() {
    this.store
      .select(selectDigitalSpecialists)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((data) => {
        this.specialists = data.sort((a, b) =>
          a.fullName.localeCompare(b.fullName)
        )
      })
  }

  addCustomWord = (term: string) => ({ id: term, name: term })
  addUserId = (term: string) => ({ id: term, name: term })
  addClientId = (term: string) => ({ id: term, name: term })

  refreshDates() {
    this.range = new FormGroup({
      start: new FormControl(),
      end: new FormControl(),
    })
  }
}
