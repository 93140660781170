import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialogModule } from '@angular/material/dialog'
import {
  MatFormField,
  MatFormFieldModule,
  MatHint,
  MatLabel,
} from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSliderModule } from '@angular/material/slider'
import { MatTooltipModule } from '@angular/material/tooltip'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {
  CwanGptApi,
  CwanGptWsHttp,
  PromptTemplateApiService,
  PromptTemplateHttpService,
} from '@cwan-gpt-ui/services'
import { NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap'
import { NgSelectModule } from '@ng-select/ng-select'

import { PromptTemplateModule } from '@cwan-gpt-ui/libs/prompt-template'

import { MatNativeDateModule } from '@angular/material/core'
import {
  MatDatepicker,
  MatDatepickerModule,
  MatDateRangeInput,
} from '@angular/material/datepicker'
import { MatDividerModule } from '@angular/material/divider'
import {
  MatProgressSpinner,
  MatProgressSpinnerModule,
} from '@angular/material/progress-spinner'
import { ChatWidgetModule } from '@cwan-gpt-ui/chat-widget'
import { CwanGPTChatModule } from '@cwan-gpt-ui/cwan-chat'
import { DesignModule } from '@cwan-gpt-ui/design'
import {
  ButtonModule,
  CheckboxModule,
  FileSelectModule,
  InlineEditModule,
  NfFormsModule,
  NumberModule,
  ToggleModule,
} from '@northfork/ng-basics'
import { HighchartsChartModule } from 'highcharts-angular'
import { NgxJsonViewerModule } from 'ngx-json-viewer'
import { GlobalConfig, ToastrModule } from 'ngx-toastr'
import { ChatAnalyzerGraderComponent } from './chat-analyzer-grading/chat-analyzer-grader.component'
import { ChatAnalyzerUIComponent } from './chat-analyzer-ui/chat-analyzer-ui.component'
import { ChatAnalyzerWindowComponent } from './chat-analyzer-window/chat-analyzer-window.component'
import { ChatFilterComponent } from './chat-filter/chat-filter.component'
import { QueryNavbarComponent } from './query-navbar/query-navbar.component'
import { QueryResultsComponent } from './query-results/query-results.component'

const DEFAULT_TOASTR_CONFIG: Partial<GlobalConfig> = {
  preventDuplicates: true,
  maxOpened: 5,
  closeButton: true,
}

@NgModule({
  declarations: [
    // CwanGPTChatComponent,
    // ChatWindowComponent,
    // ChatSessionsComponent,
    // ProgressComponent,
    // ChatDataSourcesComponent,
    // UserFilesComponent,
    // TempFileUploadComponent,
    // PdfCtasComponent,
    // ComplianceSourceComponent,
    // ChatTuningOptionsComponent,
    // SharedDocumentsComponent,
    ChatAnalyzerUIComponent,
    ChatAnalyzerWindowComponent,
    QueryNavbarComponent,
    QueryResultsComponent,
    ChatFilterComponent,
    ChatAnalyzerGraderComponent,
  ],
  imports: [
    // Angular Dependencies
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    //this app modules
    ChatWidgetModule,
    CwanGPTChatModule,
    DesignModule,
    //northfork
    NfFormsModule,
    NgSelectModule,
    ButtonModule,
    NgbTooltipModule,
    NgbNavModule,
    ToggleModule,
    ToastrModule.forRoot(DEFAULT_TOASTR_CONFIG),
    InlineEditModule,
    NgSelectModule,
    FileSelectModule,
    CheckboxModule,
    NgxJsonViewerModule,
    NumberModule,
    PromptTemplateModule,

    // Material Dependencies
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatTooltipModule,
    HighchartsChartModule,
    MatDividerModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    ChatAnalyzerUIComponent,
    ChatAnalyzerWindowComponent,
    QueryNavbarComponent,
    MatDatepicker,
    MatDateRangeInput,
    MatLabel,
    MatFormField,
    MatHint,
    MatProgressSpinner,
  ],
  providers: [
    { provide: CwanGptApi, useClass: CwanGptWsHttp },
    {
      provide: PromptTemplateApiService,
      useClass: PromptTemplateHttpService,
    },
  ],
})
export class ChatAnalyzerModule {}
