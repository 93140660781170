import { createSelector } from '@ngrx/store'
import { RootState } from '../root'
import { QueryNavBarEvaluatedListRedux } from './query-navbar-evaluated-list.reducer'

const queryNavBarEvaluatedListState = (state: RootState) =>
  state.queryNavBarEvaluatedList

export const selectQueryNavBarEvaluatedList = createSelector(
  queryNavBarEvaluatedListState,
  (state: QueryNavBarEvaluatedListRedux) => state.evaluatedSessions
)
