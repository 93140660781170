import { ChatMessageModel } from './chat.model'
import { ChatOptions, ModelInfo } from './cwan-gpt-api.model'

export interface ChatSession {
  createdAt: Date
  id: string
  history: ChatMessageModel[]
  model: ModelInfo
  options?: ChatOptions
}
