import { createSelector } from '@ngrx/store'
import { RootState } from '../root'
import { PromptTemplatesRedux } from './prompt-templates.reducer'

const promptTemplatesState = (state: RootState) => state.templates

export const selectCustomTemplates = createSelector(
  promptTemplatesState,
  (state: PromptTemplatesRedux) => state.customTemplates
)

export const selectPresetTemplates = createSelector(
  promptTemplatesState,
  (state: PromptTemplatesRedux) => state.presetTemplates
)

export const selectAppliedTemplate = createSelector(
  promptTemplatesState,
  (state: PromptTemplatesRedux) => state.appliedTemplate
)

export const selectSelectedTemplate = createSelector(
  promptTemplatesState,
  (state: PromptTemplatesRedux) => state.selectedTemplate
)

export const selectDefaultTemplateId = createSelector(
  promptTemplatesState,
  (state: PromptTemplatesRedux) => state.defaultTemplateId
)

export const selectDefaultTemplate = createSelector(
  promptTemplatesState,
  (state: PromptTemplatesRedux) => state.defaultTemplate
)
