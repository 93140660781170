import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { ChatSession } from '@cwan-gpt-ui/models'
import { LoadingWheelService, SessionService } from '@cwan-gpt-ui/services'
import {
  RootState,
  selectQueryNavBarEvaluatedList,
  selectQueryNavBarList,
  selectQueryResult,
  setCurrentQuerySession,
  updateQuerySessionInList,
} from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
@Component({
  selector: 'query-navbar',
  templateUrl: './query-navbar.component.html',
  styleUrls: ['./query-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryNavbarComponent implements OnInit, OnDestroy {
  @Output() createSession = new EventEmitter()

  queryResults: ChatSession[] = []
  evaluatedResults: ChatSession[] = []
  isLoading = false
  value = 0
  currentSession?: ChatSession
  private destroyed$ = new Subject()

  private loadingSubscription: Subscription = new Subscription()
  constructor(
    private readonly store: Store<RootState>,
    private readonly sessionService: SessionService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private loadingService: LoadingWheelService
  ) {}
  ngOnInit(): void {
    this.store
      .select(selectQueryNavBarList)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((sessions) => {
        this.queryResults = sessions
        this.cdr.detectChanges()
      })

    this.store
      .select(selectQueryResult)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((session) => {
        this.currentSession = session
        if (!this.currentSession) return
        // as the current chat session is updated, keep the session inside of the redux list updated,
        // so that list displays reflect accurately
        this.store.dispatch(updateQuerySessionInList(this.currentSession))
      })

    this.store
      .select(selectQueryNavBarEvaluatedList)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((evaluatedSessions) => {
        this.evaluatedResults = evaluatedSessions
        this.cdr.detectChanges()
      })

    // this.store
    //   .select(selectLoadingWheel)
    //   .pipe(takeUntil(this.destroyed$))
    //   .subscribe((isLoading) => {
    //     this.isLoading = isLoading
    //     this.cdr.detectChanges()
    //   })

    this.loadingSubscription = this.loadingService.data$.subscribe(
      (isLoading) => {
        this.isLoading = isLoading
        this.cdr.detectChanges()
      }
    )
  }
  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  handleSessionSelected(session: ChatSession) {
    this.store.dispatch(setCurrentQuerySession(session))
  }
  handleGradedSessionSelected(session: ChatSession) {
    this.store.dispatch(setCurrentQuerySession(session))
    this.toastr.error('WARNING: this session has already been graded')
  }
}
