export const DATA_SOURCE_MY_DOCUMENTS = 'my_documents'
export const DATA_SOURCE_SHARED_DOCUMENTS = 'shared_documents'

export const CLIENT_ACCOUNT_USER_TOOL = 'client_account_user_tool'
export const DATA_SOURCE_HR_SELF_SERVICE = 'hR_queries'
export const DATA_SOURCE_JIRA_PROJECT = 'jira_platdesk'
// export const DATA_SOURCE_MY_DOCUMENTS = 'compass'
export const MODEL_GPT4 = 'gpt-4'
export const MODEL_GPT4_32K = 'gpt-4-32k'
export const MODEL_GPT4_TURBO = 'gpt-4-turbo'
export const SUGGEST_LIST_OF_QUESTIONS = 'Suggest list of questions related to'
export const DATA_SOURCE_DEFINITIONS_DOC_LINK =
  'https://cwan.atlassian.net/wiki/spaces/GAI/pages/612073689/CWAN-GPT+Data+Sources'
