import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type Position = {
  left: number
  top: number
  width: number
  height: number
}

export type CopilotChatStatus = {
  chatEnabled: boolean
  solicitType?: string
}

export interface CopilotRedux {
  copilotChatStatus: CopilotChatStatus
  isOpen: boolean
  isDocked: boolean
  dockPosition?: Position
}

export const copilotInitialState: CopilotRedux = {
  copilotChatStatus: { chatEnabled: true, solicitType: '' },
  isOpen: false,
  isDocked: false,
  dockPosition: undefined,
}

export const copilotTemplatesSlice = createSlice({
  name: 'copilot',
  initialState: copilotInitialState,
  reducers: {
    setCopilotChatStatus: (state, action: PayloadAction<CopilotChatStatus>) => {
      state.copilotChatStatus = action.payload
    },
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
    setIsDocked: (state, action: PayloadAction<boolean>) => {
      state.isDocked = action.payload
    },
    setDockPosition: (state, action: PayloadAction<Position>) => {
      state.dockPosition = action.payload
    },
  },
})

export const { setCopilotChatStatus, setIsOpen, setIsDocked, setDockPosition } =
  copilotTemplatesSlice.actions

export default copilotTemplatesSlice.reducer
