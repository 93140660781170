import { Component, Input } from '@angular/core'
import * as Highcharts from 'highcharts'

@Component({
  selector: 'chat-message-chart',
  templateUrl: './chat-message-chart.component.html',
  styleUrls: ['./chat-message-chart.component.scss'],
})
export class ChatMessageChartComponent {
  showChart = false
  title = 'Show Chart'

  @Input() chartOptions?: Highcharts.Options

  Highcharts: typeof Highcharts = Highcharts

  handleClick() {
    this.showChart = true
  }
}
