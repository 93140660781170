import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class LoadMdFileService {
  constructor(private httpClient: HttpClient) {}

  loadUserGuideFile(type: string): Promise<string> {
    const userGuideFileName = type.toLowerCase().replace(/_/g, '-')
    return this.httpClient
      .get(`assets/md/${userGuideFileName}.md`, {
        responseType: 'text',
      })
      .toPromise()
  }
}
