<div>
  <div>
    <div id="complexity-dropdown">
      <form>
        <mat-form-field>
          <mat-label>Complexity</mat-label>
          <select
            matNativeControl
            [(ngModel)]="selectedComplexity"
            name="gradingOptions"
          >
            <option value="" selected></option>
            <option *ngFor="let grade of gradingOptions" [value]="grade">
              {{ grade }}
            </option>
          </select>
        </mat-form-field>
      </form>

      <div id="session-id">
        <p *ngIf="currId !== ''" id="session-id">
          Chat session ID: {{ currId }}
        </p>
      </div>
    </div>
    <div>
      <mat-label>Correctness</mat-label>
      <mat-slider
        min="0"
        max="10"
        step="1"
        [thumbLabel]="true"
        [tickInterval]="tickInterval"
        [displayWith]="formatLabel"
        id="correctnessSlider"
        [(ngModel)]="selectedNumValue"
      >
        <input matSliderThumb />
      </mat-slider>
    </div>
  </div>
  <div>
    <textarea
      placeholder="Additional notes..."
      class="form-control table-data finalResult textarea_revision"
      style="
        resize: none;
        overflow: hidden;
        height: 254px;
        min-height: 250px;
        overflow-wrap: break-word;
      "
      rows="12"
      [(ngModel)]="userFeedback"
    ></textarea>
  </div>
  <div class="btn-container">
    <button
      mat-mini-fab
      color="primary"
      aria-label="Send"
      type="submit"
      [disabled]="isSubmitDisabled()"
      (click)="onClick()"
    >
      Save
      <span
        matRipple
        class="mat-ripple mat-button-ripple mat-button-ripple-round"
      ></span>
    </button>
  </div>
</div>
