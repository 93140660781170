<div class="template-sidenav-container">
  <chat-filter></chat-filter>
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li [ngbNavItem]="1" id="1">
      <a ngbNavLink>Unevaluated</a>
      <ng-template ngbNavContent>
        <div class="preset-container">
          <div class="preset-templates-container">
            <div
              class="loader"
              [ngClass]="{ loading: isLoading }"
              *ngIf="isLoading"
            >
              <div>
                <mat-progress-spinner
                  diameter="60"
                  [value]="value"
                  mode="determinate"
                >
                </mat-progress-spinner>
              </div>
            </div>
            <div *ngIf="!isLoading">
              <ng-container
                *ngIf="queryResults && queryResults.length > 0; else noResults"
              >
                <div
                  class="preset-template-row"
                  *ngFor="let result of queryResults; let i = index"
                >
                  <div
                    *ngIf="result.history.length > 0"
                    class="chat-card"
                    (click)="handleSessionSelected(result)"
                    [ngClass]="{ selected: result === currentSession }"
                  >
                    <p>
                      {{ result.history[0].content }}
                    </p>
                    <span class="sub-data">
                      <small>{{ result.createdAt | date : 'short' }}</small>
                      <small class="right">{{ result.model.model }}</small>
                    </span>
                  </div>
                </div>
              </ng-container>

              <ng-template #noResults>
                <div class="no-results-message">
                  <p>No results found. Please try a different query.</p>
                </div>
              </ng-template>
            </div>
          </div>
          <div class="preset-templates-container">
            <div class="loader" *ngIf="isLoading">
              <div>
                <mat-spinner></mat-spinner>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" id="2">
      <a ngbNavLink>Evaluated</a>
      <ng-template ngbNavContent>
        <div class="custom-container">
          <div class="preset-templates-container">
            <div
              class="loader"
              [ngClass]="{ loading: isLoading }"
              *ngIf="isLoading"
            >
              <div>
                <mat-progress-spinner
                  diameter="60"
                  [value]="value"
                  mode="determinate"
                >
                </mat-progress-spinner>
              </div>
            </div>
            <div *ngIf="!isLoading">
              <ng-container
                *ngIf="
                  evaluatedResults && evaluatedResults.length > 0;
                  else noResults
                "
              >
                <div
                  class="preset-template-row"
                  *ngFor="let result of evaluatedResults; let i = index"
                >
                  <div
                    *ngIf="result.history.length > 0"
                    class="chat-card"
                    (click)="handleGradedSessionSelected(result)"
                    [ngClass]="{ selected: result === currentSession }"
                  >
                    <p>
                      {{ result.history[0].content }}
                    </p>
                    <span class="sub-data">
                      <small>{{ result.createdAt | date : 'short' }}</small>
                      <small class="right">{{ result.model.model }}</small>
                    </span>
                  </div>
                </div>
              </ng-container>

              <ng-template #noResults>
                <div class="no-results-message">
                  <p>No results found. Please try a different query.</p>
                </div>
              </ng-template>
            </div>
          </div>
          <div class="preset-templates-container">
            <div class="loader" *ngIf="isLoading">
              <div>
                <mat-spinner></mat-spinner>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
