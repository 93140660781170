import { Injectable } from '@angular/core'
import { PromptTemplate } from '@cwan-gpt-ui/models'

@Injectable({
  providedIn: 'root',
})
export class PromptSubstitutionService {
  substituteText(text: string, variables: Record<string, string>): string {
    return text.replace(new RegExp('{([A-z0-9_$&]*)}', 'g'), (m) => {
      return variables[m.substring(1, m.length - 1)]
    })
  }

  substitutePrompt(
    prompt: PromptTemplate,
    variables: Record<string, string>
  ): string {
    return this.substituteText(prompt.prompt, variables)
  }
}
