<div class="template-sidenav-container">
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li [ngbNavItem]="1" id="1">
      <a ngbNavLink>Preset</a>
      <ng-template ngbNavContent>
        <div class="preset-container">
          <!-- Filter -->
          <input
            id="filterPreset"
            [(ngModel)]="filterPreset"
            class="form-control filter-presets"
            type="text"
            placeholder="Search..."
          />

          <div class="preset-templates-container">
            <div
              class="preset-template-row"
              *ngFor="let template of presetTemplates$ | async"
            >
              <div
                class="preset-template"
                (click)="setSelectedTemplate(template)"
                *ngIf="showFilteredTemplates(template)"
                [ngClass]="{ selected: template === selectedTemplate }"
                ngbTooltip="{{ template.name }}"
              >
                <p>
                  {{ template.name }}
                </p>
                <div
                  *ngIf="template.tags && template.tags.length > 0"
                  class="badge badge-light default-tag"
                  ngbTooltip="{{ template.tags.join(',') }}"
                >
                  {{ template.tags.length }}
                </div>
                <div
                  *ngIf="template.id === this.defaultTemplateId"
                  class="badge badge-light default-tag"
                  ngbTooltip="This template will be applied automatically when CWAN GPT starts."
                >
                  Default
                </div>
              </div>
              <template-action-menu
                [selectedTemplate]="template"
                *ngIf="showFilteredTemplates(template)"
              ></template-action-menu>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" id="2">
      <a ngbNavLink>My Templates</a>
      <ng-template ngbNavContent>
        <div class="custom-container">
          <!-- Filter -->
          <input
            id="filterPreset"
            [(ngModel)]="filterPreset"
            class="form-control filter-presets"
            type="text"
            placeholder="Search..."
          />
          <button
            nf-button="success"
            icon="plus"
            class="add-new-template-btn"
            (click)="createNewTemplate()"
            aria-labelledby="add-new-template-button"
          >
            Add New Template
          </button>

          <div class="custom-templates-container">
            <div
              class="custom-template-row"
              *ngFor="let template of customTemplates$ | async"
            >
              <div
                class="custom-template"
                (click)="setSelectedTemplate(template)"
                [ngClass]="{
                  selected: template === selectedTemplate
                }"
                *ngIf="showFilteredTemplates(template)"
                ngbTooltip="{{ template.name }}"
              >
                <p>{{ template.name }}</p>
                <div
                  *ngIf="template.id === this.defaultTemplateId"
                  class="badge badge-light default-tag"
                  ngbTooltip="This template will be applied automatically when CWAN GPT starts."
                >
                  Default
                </div>
                <div
                  *ngIf="template.tags && template.tags.length > 0"
                  class="badge badge-light default-tag"
                  ngbTooltip="{{ template.tags.join(',') }}"
                >
                  <i class="mdi mdi-tag"></i>
                  {{ template.tags.length }}
                </div>
              </div>
              <template-action-menu
                [selectedTemplate]="template"
                *ngIf="showFilteredTemplates(template)"
              ></template-action-menu>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
