import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { PdfAction } from '@cwan-gpt-ui/models'
import {
  RootState,
  selectChatToolNames,
  selectIsAwaitingResponse,
  selectSelectedFiles,
} from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import {
  DATA_SOURCE_MY_DOCUMENTS,
  SUGGEST_LIST_OF_QUESTIONS,
} from '../constants'

@Component({
  selector: 'pdf-ctas',
  templateUrl: './pdf-ctas.component.html',
  styleUrls: [`./pdf-ctas.component.scss`],
})
export class PdfCtasComponent implements OnDestroy, OnInit {
  SUGGEST_LIST_OF_QUESTIONS = SUGGEST_LIST_OF_QUESTIONS

  @Output() pdfAction = new EventEmitter<PdfAction>()
  showPdfCtas? = false
  isAwaitingResponse = false // gray out the buttons until all responses are back

  private selectedFiles: string[] = []
  private selectedTools: string[] = []
  private _destroyed$ = new Subject<void>()

  constructor(private readonly store: Store<RootState>) {}

  ngOnInit() {
    this.store
      .select(selectSelectedFiles)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((files) => {
        this.selectedFiles = files
        this.setShowPdfCtasFlag()
      })
    this.store
      .select(selectChatToolNames)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((toolNames) => {
        this.selectedTools = toolNames
        this.setShowPdfCtasFlag()
      })
    this.store
      .select(selectIsAwaitingResponse)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((value) => {
        this.isAwaitingResponse = value
      })
  }

  ngOnDestroy() {
    this._destroyed$.next()
    this._destroyed$.complete()
  }

  private setShowPdfCtasFlag() {
    this.showPdfCtas =
      this.selectedFiles.length == 1 &&
      this.selectedTools?.includes(DATA_SOURCE_MY_DOCUMENTS)
  }

  handlePdfActions(str: string) {
    if (this.selectedFiles.length === 0) {
      return
    }
    const filename = this.selectedFiles[0]
    const msg = `${str} the file ${filename}`
    this.pdfAction.emit({ messageText: msg })
  }
}
