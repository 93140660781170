<div class="cwan-gpt-chat-container">
  <img
    class="cwic-studio-logo"
    src="../../../assets/cwic-studio-logo.svg"
    alt="cwic-studio-logo"
  />
  <h1>
    To access Digital Specialists, use “CWIC Studio” app from the sidenav.
  </h1>
</div>
