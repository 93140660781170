import { Injectable } from '@angular/core'
import { RootState, setUserPolicy } from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import { CwanGptApi } from '../cwan-gpt-api/cwan-gpt-api.service'

@Injectable({
  providedIn: 'root',
})
export class PolicyService {
  constructor(
    private readonly cwanGptApi: CwanGptApi,
    private readonly store: Store<RootState>
  ) {}

  async init() {
    const userPolicy = await this.cwanGptApi.getUserPolicy()
    this.store.dispatch(
      setUserPolicy({
        isBetaUser: !!userPolicy.hasBetaFeatureAccess,
        isDebugToolsAccess: !!userPolicy.hasDebugToolsAccess,
        isPromptTemplateAdmin: !!userPolicy.hasPromptTemplateAdmin,
        isDigitalSpecialistAccess: !!userPolicy.hasDigitalSpecialistAccess,
        isDigitalSpecialistAdmin: !!userPolicy.hasDigitalSpecialistAdmin,
      })
    )
  }
}
