export interface FileUploadStatus {
  collection: string
  duration_seconds: number
  job_id: string
  last_updated_ts: string
  message: string
  percent_complete: number
  stage: string
}

export enum FileType {
  USER_DOCS_FILE = 'USER_DOCS_FILE',
  API_SPEC_FILE = 'API_SPEC_FILE',
  MATRIX_GENERATION_FILE = 'MATRIX_GENERATION_FILE',
}
