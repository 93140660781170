import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  ChatFilter,
  ChatRequest,
  ChatResponse,
  ChatSession,
  CiamUsersResponse,
  GradedResponse,
  ModelInfo,
  ToolConfig,
  ToolPrompt,
  UserPolicyResponse,
} from '@cwan-gpt-ui/models'
import { environment } from 'environments/environment'
import { throwError } from 'rxjs'
import { catchError, map, take } from 'rxjs/operators'
import { DATASOURCES } from '../constants'
import { CwanGptApi } from './cwan-gpt-api.service'

let BASE_URL = environment.cwanGptWsBaseUrl

@Injectable({
  providedIn: 'root',
})
export class CwanGptWsHttp extends CwanGptApi {
  constructor(private readonly httpClient: HttpClient) {
    super()
  }

  /**
   * When used externally by clients, we have to use the public urls
   */
  public usePublicApi() {
    BASE_URL = environment.publicApiBaseUrl
  }

  /**
   * Check if we can access the private vpn url.
   * MUST be called before usePublicApi
   */
  async isVpn(): Promise<boolean> {
    //const url = `${BASE_URL}/user-policy

    //Sending the request now to unathenticated end-point with /vpncheck
    const url = `${BASE_URL}/vpncheck`

    // creating a new AbortController
    const controller = new AbortController()

    // Setting timeout to automatically abort
    const timeoutId = setTimeout(() => {
      controller.abort()
    }, 4000)

    // Getting signal from the controller
    const signal = controller.signal

    try {
      const response = await fetch(url, {
        signal,
      })

      return true // Indicates that the fetch was successful (not necessarily a VPN)
    } catch (error: any) {
      return false
    } finally {
      clearTimeout(timeoutId) // Clean up the timeout
    }
  }

  sendMessage(request: ChatRequest, sessionId: string): Promise<ChatResponse> {
    const url = `${BASE_URL}/chat`
    return this.httpClient
      .post<ChatResponse>(url, request, {
        params: {
          sessionId,
        },
      })
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`sendMessage API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  sendDsMessage(
    request: ChatRequest,
    sessionId: string,
    dsUsername: string
  ): Promise<ChatResponse> {
    const url = `${BASE_URL}/chat/ds`
    return this.httpClient
      .post<ChatResponse>(url, request, {
        params: {
          sessionId,
          dsUsername,
        },
      })
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`sendMessage API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  sendMessageWithoutSession(request: ChatRequest): Promise<ChatResponse> {
    const url = `${BASE_URL}/chat`
    return this.httpClient
      .post<ChatResponse>(url, request)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`sendMessageWithoutSession API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  getModels(): Promise<ModelInfo[]> {
    const url = `${BASE_URL}/chat/models/list`
    return this.httpClient
      .get<ModelInfo[]>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`getModels API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  getDatasources(): Promise<ToolConfig[]> {
    const url = `${BASE_URL}/digital-specialist-ws/tools`
    return this.httpClient
      .get<ToolConfig[]>(url)
      .pipe(
        take(1),
        map((tools: ToolConfig[]) => {
          const datasources = new Array<ToolConfig>()
          tools.forEach((tool) => {
            if (DATASOURCES.includes(tool.toolId)) {
              datasources.push(tool)
            }
          })
          return datasources
        }),
        catchError((error) => {
          console.error(`getTools API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  getToolPrompts(): Promise<Map<string, string[]>> {
    const url = `${BASE_URL}/tool-prompts`
    return this.httpClient
      .get<ToolPrompt[]>(url)
      .pipe(
        take(1),
        map((toolPrompts: ToolPrompt[]) => {
          const map = new Map<string, string[]>()
          toolPrompts.forEach((toolPrompt) =>
            map.set(toolPrompt.tool, toolPrompt.prompts)
          )
          return map
        }),
        catchError((error) => {
          console.error(`get tool prompts API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  getTenantUsers(searchTerm: string): Promise<CiamUsersResponse> {
    const url = `${BASE_URL}/identity/users?filter=${searchTerm}`
    return this.httpClient
      .get<CiamUsersResponse>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`User API failed, url: ${url}`)
          return throwError(error)
        })
      )
      .toPromise()
  }

  getUserPolicy(): Promise<UserPolicyResponse> {
    const url = `${BASE_URL}/user-policy`
    return this.httpClient
      .get<UserPolicyResponse>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`getUserPolicy API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  sendFilterRequest(
    chatFilter: ChatFilter
  ): Promise<[ChatSession[], ChatSession[]]> {
    const url = `${BASE_URL}/chat/chatfilter`
    return this.httpClient
      .post<[ChatSession[], ChatSession[]]>(url, chatFilter)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`sendFilterRequest API failed, url: ${url}`)
          console.log(error)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  sendGradedResponse(response: GradedResponse): Promise<void> {
    const url = `${BASE_URL}/chat/graded-response`
    return this.httpClient
      .post<void>(url, response)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`sendGradedResponse API failed, url: ${url}`)
          console.log(error)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }
}
