import { Component, Input } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'nf-delete-confirm-modal',
  templateUrl: './delete-template-confirmation-modal.component.html',
  styleUrls: ['./delete-template-confirmation-modal.component.scss'],
})
export class DeleteTemplateConfirmationModalComponent {
  @Input() templateName: string | undefined
  @Input() templatePrompt: string | undefined

  constructor(public activeModal: NgbActiveModal) {}
}
