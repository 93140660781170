import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, from } from 'rxjs'
import { switchMap } from 'rxjs/operators'

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  intercept(
    httpRequest: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return from(this.getToken()).pipe(
      switchMap((token) => {
        return next.handle(
          httpRequest.clone({
            setHeaders: { Authorization: `Bearer ${token}` },
          })
        )
      })
    )
  }

  //get the token from the sidenav.  This will only work after sidenav loads and completes authentication
  private getToken(): Promise<string> {
    const getTokenFn = (window as any)?.sidenav?.getAccessTokenSilentlyOrLogout
    if (!getTokenFn)
      return Promise.reject(new Error('cannot find SidenavExports :('))
    return getTokenFn()
  }
}
