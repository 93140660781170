import {
  ChatFilter,
  ChatRequest,
  ChatResponse,
  ChatSession,
  GradedResponse,
  ModelInfo,
  ToolConfig,
  UserPolicyResponse,
} from '@cwan-gpt-ui/models'

/**
 * This abstracts an API from a server - it has a real (http) implementation and a fake one for unit tests.
 * The fake one can also be used for parallel development, so UI can be developed while the backend API is not yet ready.
 *
 * Must be an abstract class rather than interface due to Dependency Injection
 */
export abstract class CwanGptApi {
  abstract usePublicApi(): void
  abstract isVpn(): Promise<boolean>

  abstract sendMessage(
    request: ChatRequest,
    sessionId: string
  ): Promise<ChatResponse>

  abstract sendDsMessage(
    request: ChatRequest,
    sessionId: string,
    dsUsername: string
  ): Promise<ChatResponse>

  abstract sendMessageWithoutSession(
    request: ChatRequest
  ): Promise<ChatResponse>

  abstract getModels(): Promise<ModelInfo[]>
  abstract getDatasources(): Promise<ToolConfig[]>
  abstract getToolPrompts(): Promise<Map<string, string[]>>
  abstract getUserPolicy(): Promise<UserPolicyResponse>

  abstract sendFilterRequest(
    chatFilter: ChatFilter
  ): Promise<[ChatSession[], ChatSession[]]>

  abstract sendGradedResponse(response: GradedResponse): Promise<void>
}
