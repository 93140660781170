import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpParams,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'

import { environment } from 'environments/environment'

import { FileType, FileUploadStatus } from '@cwan-gpt-ui/models'
import { catchError, map, take } from 'rxjs/operators'

const BASE_URL = environment.cwanGptWsBaseUrl
const UPLOAD_URL = `${BASE_URL}/chat/upload`
const FILES_URL = `${BASE_URL}/chat/files`
const JOB_STATUS_URL = `${BASE_URL}/job/status/user_documents`

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private readonly httpClient: HttpClient) {}

  async getUserFiles() {
    return this.httpClient
      .get<string[]>(FILES_URL)
      .pipe(
        take(1),
        catchError((error) => {
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  async getUploadStatus(jobId: string) {
    return this.httpClient
      .get<FileUploadStatus>(JOB_STATUS_URL, {
        params: {
          jobId: jobId,
        },
      })
      .pipe(
        take(1),
        catchError((error) => {
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  uploadFile(
    formData: FormData,
    sessionId?: string,
    fileType = FileType.USER_DOCS_FILE
  ) {
    const params = { fileType: fileType, sessionId: sessionId || '' }

    const httpParams = new HttpParams({
      fromObject: params,
    })

    const req = new HttpRequest('POST', UPLOAD_URL, formData, {
      reportProgress: true,
      params: httpParams,
    })

    return this.httpClient
      .request(req)
      .pipe(
        map((event) => this.getEventMessage(event)),
        catchError((error) => {
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  private getEventMessage(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        return event

      case HttpEventType.Response:
        return event.body
    }
  }

  deleteFile(fileName: string, fileType = FileType.USER_DOCS_FILE) {
    return this.httpClient
      .delete(FILES_URL + `/${fileName}?fileType=${fileType}`)
      .pipe(
        take(1),
        catchError((error) => {
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  downloadFile(
    fileName: string,
    fileType = FileType.USER_DOCS_FILE,
    type = ''
  ) {
    let url = `${BASE_URL}/chat/file/${fileName}?fileType=${fileType}`
    if (type) {
      url = `${url}&type=${type}`
    }
    return this.httpClient.get(url, {
      responseType: 'blob',
    })
  }
}
