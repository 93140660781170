<div class="dialog-container">
  <h1 mat-dialog-title>{{ modalHeader }}</h1>
  <mat-dialog-content class="modal-body">
    <mat-tab-group class="nav-tabs">
      <mat-tab label="Template Prompt">
        <div class="description-container" *ngIf="description">
          <p class="desc-label">Template Description</p>
          <div class="preview-box">
            {{ description }}
          </div>
        </div>
        <mat-form-field>
          <mat-label>Template Prompt</mat-label>
          <textarea
            matInput
            name="prompt"
            class="form-control"
            [ngClass]="{ expand: !description }"
            rows="15"
            placeholder="Write your prompt here: ex) You are a Reconciler. You have a professional tone of voice. Acknowledge the customer's issue or question, provide a helpful solution or response, and offer additional assistance or follow-up if needed. Write a 100-word follow-up email about the following issue/question: "
            [formControl]="prompt"
          ></textarea>
        </mat-form-field>
        <mat-hint>
          <p *ngIf="isSystemTemplate">
            * System prompts will be added to the conversation. If you are a
            beta user, turn on Debug Mode to see the system prompts.
          </p>
          <p *ngIf="!isSystemTemplate">
            * The prompt will be sent as a message.
          </p>
        </mat-hint>
      </mat-tab>
      <mat-tab label="Variables">
        <div class="add-btn-container">
          <button
            mat-button
            color="primary"
            (click)="addNewVariable()"
            aria-labelledby="add-variable"
          >
            Add New Variable
          </button>
        </div>
        <div *ngFor="let variable of variables.controls; let i = index">
          <variable-substitution
            [variableName]="variable.get('name')?.value"
            [variableValue]="variable.get('value')?.value"
            (delete)="deleteVariable(i)"
            (changed)="handleVariableChange($event, i)"
          ></variable-substitution>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <mat-dialog-actions class="modal-footer">
    <button mat-raised-button mat-dialog-close color="warn">Cancel</button>
    <button
      mat-raised-button
      *ngIf="currentSession"
      [ngClass]="{ info: isSystemTemplate, primary: !isSystemTemplate }"
      (click)="onApply(false)"
    >
      Apply to Chat
    </button>
    <button
      mat-raised-button
      [ngClass]="{ info: isSystemTemplate, primary: !isSystemTemplate }"
      (click)="onApply(true)"
    >
      Start New Session
    </button>
  </mat-dialog-actions>
</div>
