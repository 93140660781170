<ng-container>
  <!-- Display the first 4 actions -->
  <div *ngFor="let action of availableActions.slice(0, 4); let i = index">
    <chat-message-action
      class="chat-message-action-item"
      [action]="action"
      [index]="i"
      (actionClicked)="handleAction(action)"
    ></chat-message-action>
  </div>

  <!-- Show 'More...' dropdown if there are more than 4 actions -->
  <ng-container *ngIf="availableActions.length > 4">
    <button
      mat-raised-button
      color="primary"
      class="more-actions"
      [matMenuTriggerFor]="moreActionsMenu"
      aria-label="More actions"
    >
      More...
    </button>

    <mat-menu #moreActionsMenu="matMenu" class="more-action-items">
      <button
        mat-menu-item
        *ngFor="let action of availableActions.slice(4); let i = index"
        (click)="handleAction(action)"
      >
        {{ action.title }}
      </button>
    </mat-menu>
  </ng-container>
</ng-container>
