import { Component, Input } from '@angular/core'
import { Citation, CitationType } from '@cwan-gpt-ui/models'

@Component({
  selector: 'chat-message-citation',
  templateUrl: './chat-message-citation.component.html',
  styleUrls: [`./chat-message-citation.component.scss`],
})
export class ChatMessageCitationComponent {
  citationSourceUrl = ''
  citationTest = ''
  citationNumber = 0
  citationTitle = ''
  textCitation = false

  @Input() set citation(value: Citation) {
    this.citationSourceUrl = value.source
    this.citationTitle = value.title || ''
    this.textCitation = value.type === CitationType.TEXT
  }

  @Input() set index(i: number) {
    this.citationNumber = i + 1
  }
}
