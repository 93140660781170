import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MatSliderChange } from '@angular/material/slider'

const DEFAULT_TEMPERATURE_VALUE = 0.9

@Component({
  selector: 'chat-tuning-options',
  templateUrl: './chat-tuning-options.component.html',
  styleUrls: [`./chat-tuning-options.component.scss`],
})
export class ChatTuningOptionsComponent {
  @Input() temperature = DEFAULT_TEMPERATURE_VALUE
  @Output() temperatureChange = new EventEmitter<number>()

  onTemperatureChange(e: MatSliderChange) {
    this.temperatureChange.emit(e.value ?? DEFAULT_TEMPERATURE_VALUE)
  }
}
