import {
  SharedDocumentsService
} from '@cwan-gpt-ui/services'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'

@Component({
  selector: 'shared-documents',
  templateUrl: './shared-documents.component.html',
  styleUrls: ['./shared-documents.component.scss'],
})
export class SharedDocumentsComponent implements OnInit  {

  categories: string[] = []
  selectedCategory?: string
  @Output() createSession = new EventEmitter()

  constructor(
    private readonly sharedDocumentsService: SharedDocumentsService,
  ) {}
  
  ngOnInit(): void {
    this.loadAsync()
  }

  async loadAsync() {
    this.categories = await this.sharedDocumentsService.getCategories()
    this.selectedCategory = this.categories[0]
    this.selectCategory(this.selectedCategory)
  }

  selectCategory(category: string) {
    this.sharedDocumentsService.changeCategory(category)
    this.createSession.emit()
  }

}
