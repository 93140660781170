import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { PromptTemplate } from '@cwan-gpt-ui/models'
import { PromptTemplateService } from '@cwan-gpt-ui/services'
import {
  RootState,
  selectDefaultTemplateId,
  selectIsPromptAdmin,
  setAppliedTemplate,
  setDefaultTemplateId,
  setSelectedTemplate,
} from '@cwan-gpt-ui/state-management'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { DeleteTemplateConfirmationModalComponent } from '../delete-template-modal/delete-template-confirmation-modal.component'
import { NewTemplateModalComponent } from '../new-template-modal/new-template-modal.component'

@Component({
  selector: 'template-action-menu',
  templateUrl: './template-action-menu.component.html',
  styleUrls: ['./template-action-menu.component.scss'],
})
export class TemplateActionMenuComponent implements OnInit, OnDestroy {
  defaultTemplateId: string | null = null
  showInMenu = false

  menuCanEdit = false
  menuCanDelete = false
  menuCanCloneAndEdit = true

  isPromptTemplateAdmin = false

  @Input() selectedTemplate: PromptTemplate | undefined

  _destroyed$ = new Subject<void>()

  constructor(
    private readonly store: Store<RootState>,
    private readonly modal: NgbModal,
    private readonly templateService: PromptTemplateService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.updateMenuItems()

    this.store.select(selectDefaultTemplateId).subscribe((id) => {
      this.defaultTemplateId = id ?? null
    })

    this.store
      .select(selectIsPromptAdmin)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((value) => {
        this.isPromptTemplateAdmin = value
        this.updateMenuItems()
      })
  }

  ngOnDestroy(): void {
    this._destroyed$.next()
    this._destroyed$.complete()
  }

  private updateMenuItems() {
    if (!this.selectedTemplate) return

    if (this.selectedTemplate.type === 'custom') {
      this.menuCanEdit = true
      this.menuCanDelete = true
      this.menuCanCloneAndEdit = true
      return
    }
    if (this.selectedTemplate.type === 'preset') {
      this.menuCanEdit = this.isPromptTemplateAdmin
      this.menuCanDelete = this.isPromptTemplateAdmin
      this.menuCanCloneAndEdit = this.isPromptTemplateAdmin
    }
  }

  async handleDelete() {
    const modalOptions = {
      size: 'lg',
    }
    const modalRef = this.modal.open(
      DeleteTemplateConfirmationModalComponent,
      modalOptions
    )

    modalRef.componentInstance.templateName = this.selectedTemplate?.name
    modalRef.componentInstance.templatePrompt = this.selectedTemplate?.prompt

    modalRef.result
      .then(async (result) => {
        if (result === true && this.selectedTemplate?.id) {
          await this.templateService.deleteTemplate(
            this.selectedTemplate.id,
            this.selectedTemplate.type
          )
          this.store.dispatch(setSelectedTemplate({ template: undefined }))
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  async handleEdit() {
    this.modal.dismissAll()

    const modalOptions = {
      size: 'lg',
    }
    const modalRef = this.modal.open(NewTemplateModalComponent, modalOptions)

    modalRef.componentInstance.existingTemplate = this.selectedTemplate
    modalRef.componentInstance.modalHeader = 'Update Prompt Template'
    modalRef.componentInstance.isEditing = true

    modalRef.result
      .then(async (template: PromptTemplate) => {
        await this.templateService.updateTemplate(
          this.selectedTemplate?.id ?? '',
          template
        )
      })
      .catch(() => {
        // Ignore
      })
  }

  async handleCloneAndEdit() {
    if (!this.selectedTemplate?.id) return

    try {
      const fetchedTemplate = await this.templateService.fetchTemplate(
        this.selectedTemplate.id
      )

      const existingTemplate = { ...fetchedTemplate }

      this.store.dispatch(setSelectedTemplate({ template: existingTemplate }))

      const modalOptions = {
        size: 'lg',
      }

      const modalRef = this.modal.open(NewTemplateModalComponent, modalOptions)

      modalRef.componentInstance.existingTemplate = {
        ...existingTemplate,
        name: `Copy of ${existingTemplate.name}`,
      }

      modalRef.componentInstance.modalHeader = 'Clone & Update Prompt Template'

      const template = await modalRef.result

      await this.templateService.createTemplate(template)
    } catch (error) {
      console.log(error)
    }
  }

  setAppliedTemplate(template?: PromptTemplate) {
    this.store.dispatch(setAppliedTemplate({ template }))
    this.router.navigate(['/'])
  }

  async handleSetAsDefault() {
    const template = this.selectedTemplate
    if (!template?.id) return

    await this.templateService.setDefaultTemplate(template.id)

    // update template with default as true
    const updatedTemplate = {
      ...template,
      default: true,
    } as PromptTemplate

    this.setAppliedTemplate(updatedTemplate)
  }

  async handleClearDefault() {
    const template = this.selectedTemplate
    if (!template?.id) return

    await this.templateService.clearDefaultTemplate()

    this.store.dispatch(setDefaultTemplateId({ templateId: undefined }))
  }

  async toggleShowInMenu() {
    const newVisibility = !this.selectedTemplate?.showInMenu

    if (this.selectedTemplate?.id) {
      this.templateService.toggleShowInMenu(
        this.selectedTemplate?.id,
        newVisibility
      )
    }
  }
}
