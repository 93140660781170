<div class="modal-header" [nfModalVariant]="'danger'">
  <h4 class="modal-title">Are you sure you want to delete this template?</h4>
  <button
    nf-icon-button
    icon="close"
    class="btn-bare"
    ngbTooltip="Close"
    (click)="activeModal.close()"
  >
    Close
  </button>
</div>
<div class="modal-body">
  <p>Template Name: {{ templateName }}</p>
  <p>Template Prompt:</p>
  <div class="preview-box">
    {{ templatePrompt }}
  </div>
</div>
<div class="modal-footer">
  <button
    mat-raised-button
    matTooltip="Cancel"
    class="template-cancel"
    aria-label="Cancel"
    (click)="activeModal.dismiss()"
  >
    Cancel
  </button>
  <button
    mat-raised-button
    matTooltip="Delete template"
    class="template-delete"
    color="warn"
    icon="delete"
    aria-label="Delete template"
    (click)="activeModal.close(true)"
  >
    <mat-icon>delete</mat-icon>
    Delete
  </button>
</div>
