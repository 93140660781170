import { Injectable } from '@angular/core'
import { PromptRequest, PromptTemplate } from '@cwan-gpt-ui/models'
import { customTemplates, presetTemplates } from '@cwan-gpt-ui/state-management'
import { Observable, of } from 'rxjs'
import { v4 as uuid } from 'uuid'
import { PromptTemplateApiService } from './prompt-template-api.service'

@Injectable({
  providedIn: 'root',
})
export class MockPromptTemplateService implements PromptTemplateApiService {
  prompts: PromptTemplate[] = [...presetTemplates, ...customTemplates]
  presetTemplates$: Observable<PromptTemplate[]> = of([...presetTemplates])
  customTemplates$: Observable<PromptTemplate[]> = of([...customTemplates])
  templateTags$: Observable<string[]> = of([])

  private updateLocalStorage() {
    localStorage.setItem('prompts', JSON.stringify(this.prompts))
  }

  fetchAllTemplates() {
    // Ignore
  }

  getTemplateByName(name: string): Promise<PromptTemplate | undefined> {
    return Promise.resolve(this.prompts.find((p) => p.name === name))
  }

  getTemplates(): Promise<PromptTemplate[]> {
    return Promise.resolve(this.prompts)
  }

  getAllTemplateTags$(): Observable<string[]> {
    return this.templateTags$
  }

  getTemplate(id: string): Promise<PromptTemplate> {
    const prompt =
      this.prompts.find((p) => p.id === id) ?? ({} as PromptTemplate)
    return Promise.resolve(prompt)
  }

  getTemplatesByTagName(tag: string): Promise<PromptTemplate[]> {
    const prompt: PromptTemplate[] = []
    return Promise.resolve(prompt)
  }

  createTemplate(request: PromptRequest): Promise<PromptTemplate> {
    const newPrompt: PromptTemplate = {
      id: uuid(),
      name: request.name,
      prompt: request.prompt,
      showInMenu: true,
      type: 'custom',
      tags: request.tags,
      default: false,
      createdAt: new Date(),
      role: 'user',
    }
    this.prompts.push(newPrompt)
    this.updateLocalStorage()
    return Promise.resolve(newPrompt)
  }

  updateTemplate(id: string, request: PromptRequest): Promise<PromptTemplate> {
    const index = this.prompts.findIndex((p) => p.id === id)
    if (index !== -1)
      this.prompts[index] = {
        ...this.prompts[index],
        name: request.name,
        prompt: request.prompt,
      }
    this.updateLocalStorage()
    return Promise.resolve(this.prompts[index])
  }

  deleteTemplate(id: string): Promise<void> {
    const index = this.prompts.findIndex((p) => p.id === id)
    if (index !== -1) this.prompts.splice(index, 1)
    this.updateLocalStorage()
    return Promise.resolve()
  }

  toggleShowInMenu(id: string): Promise<void> {
    const index = this.prompts.findIndex((p) => p.id === id)
    if (index !== -1)
      this.prompts[index] = {
        ...this.prompts[index],
        showInMenu: !this.prompts[index].showInMenu,
      }
    this.updateLocalStorage()
    return Promise.resolve()
  }

  setDefaultTemplate(id: string): Promise<void> {
    const index = this.prompts.findIndex((p) => p.id === id)
    if (index !== -1) {
      this.prompts[index] = {
        ...this.prompts[index],
        default: true,
      }
    }
    this.updateLocalStorage()
    return Promise.resolve()
  }

  removeDefaultTemplate(): Promise<void> {
    const index = this.prompts.findIndex((p) => p.default)

    if (index !== -1) {
      this.prompts[index] = {
        ...this.prompts[index],
        default: false,
      }
    }

    this.updateLocalStorage()

    return Promise.resolve()
  }
}
