import { createSelector } from '@ngrx/store'
import { RootState } from '../root'
import { DebugToolsRedux } from './debug-tools.reducer'

const debugToolsState = (state: RootState) => state.debugTools

export const selectIsDebugModeSet = createSelector(
  debugToolsState,
  (state: DebugToolsRedux) => state.isDebugModeSet
)
