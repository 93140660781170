import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { ChatSession } from '@cwan-gpt-ui/models'
import { SessionService } from '@cwan-gpt-ui/services'
import {
  RootState,
  prependSessionToList,
  selectChatSession,
  selectChatSessionList,
  setCurrentSession,
  updateSessionInList,
} from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'chat-sessions',
  templateUrl: './chat-sessions.component.html',
  styleUrls: [`./chat-sessions.component.scss`],
})
export class ChatSessionsComponent implements OnInit, OnDestroy {
  @Output() createSession = new EventEmitter()
  @Output() navigateToSession = new EventEmitter()

  currentSession?: ChatSession
  sessions: ChatSession[] = []
  isLoading = true
  private destroyed$ = new Subject()

  constructor(
    private readonly store: Store<RootState>,
    private readonly sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.sessionService.loadSessions()

    this.store
      .select(selectChatSessionList)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((sessions) => {
        this.isLoading = false
        this.sessions = sessions
        this.ensureCurrentSessionIsInList()
      })

    this.store
      .select(selectChatSession)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((session) => {
        this.currentSession = session
        if (!this.currentSession) return
        //as the current chat session is updated, keep the session inside of the redux list updated, so that list displays reflect accurately
        this.store.dispatch(updateSessionInList(this.currentSession))
      })
  }
  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  handleCreateNewSession() {
    this.createSession.emit()
  }

  handleSessionSelected(session: ChatSession) {
    this.navigateToSession.emit()
    this.store.dispatch(setCurrentSession(session))
  }

  /**
   * there's a race condition, where the session may start before the whole list loads.
   * in that case, make sure the current session is added to the list
   */
  private ensureCurrentSessionIsInList() {
    if (!this.currentSession) return
    if (this.sessions.length === 0) return
    const currentId = this.currentSession.id

    const idx = this.sessions.findIndex((item) => item.id === currentId)
    if (idx < 0) {
      this.store.dispatch(prependSessionToList(this.currentSession))
    }
  }
}
