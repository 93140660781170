<div class="temporary-file">
  <button mat-button class="temporary-file-button">
    <mat-icon inline="true" class="icon-color">{{
      filename.includes('.pdf') ? 'picture_as_pdf' : 'file_copy'
    }}</mat-icon>
    {{ filename }}
  </button>
  <div class="temporary-file-ctas">
    <div class="btn-group">
      <button
        (click)="onMessageSend('Summarize')"
        type="button"
        class="btn btn-default border text-primary border-primary rounded m-1"
      >
        Summarize
      </button>
      <button
        (click)="onMessageSend(SUGGEST_LIST_OF_QUESTIONS)"
        type="button"
        class="btn btn-default border text-primary border-primary rounded m-1"
      >
        Suggest Questions
      </button>
    </div>
  </div>
</div>
