export interface ChatFilter {
  keywordSearch: string[]
  userIds: string[]
  isLiked: boolean
  isDisliked: boolean
  isExternal: boolean
  specialists: string[]
  llmModels: string[]
  // startDate: string
  startDate: Date
  endDate: Date
  // endDate: string
  clientId: string
}
