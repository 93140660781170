import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  AccessLevel,
  ChatMessageModel,
  DigitalSpecialist,
  DigitalSpecialistSession,
  DsUser,
  LLMTool,
  SharedResourceType,
  Skill,
  SkillRelationships,
  ToolRelationships,
  UserInstance,
} from '@cwan-gpt-ui/models'
import { environment } from 'environments/environment'
import { catchError, take } from 'rxjs/operators'

// let DS_BASE_URL = `http://127.0.0.1:8080/digital-specialist-ws`
let DS_BASE_URL = `${environment.cwanGptWsBaseUrl}/digital-specialist-ws`

/**
 * This is the implementation of the digital specialist API.
 * IMPORTANT!  this should be only a _very_ thin layer over the raw API.
 *   - NO BUSINESS LOGIC HERE
 *   - NO DEPENDENCIES (other than models used in the API)
 */
@Injectable({
  providedIn: 'root',
})
export class DigitalSpecialistApi {
  constructor(private readonly http: HttpClient) {}

  public usePublicApi() {
    DS_BASE_URL = `${environment.publicApiBaseUrl}/digital-specialist-ws`
  }

  /**
   * WARNING - this does not return any details on skills or tools
   * @returns list of all specialists
   */
  getAllSpecialists(accessLevel: AccessLevel): Promise<DigitalSpecialist[]> {
    const url = `${DS_BASE_URL}/specialists?accessLevel=` + accessLevel
    return this.http
      .get<DigitalSpecialist[]>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/getAll API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  /**
   * @returns DS with all the details of the skills filled in
   */
  getSpecialist(username: string): Promise<DigitalSpecialist> {
    const url = `${DS_BASE_URL}/specialists/${username}`
    return this.http
      .get<DigitalSpecialist>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/getByUsername API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  createSpecialist(specialist: DigitalSpecialist): Promise<DigitalSpecialist> {
    const url = `${DS_BASE_URL}/specialists`
    return this.http
      .post<DigitalSpecialist>(url, specialist)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/create API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  cloneSpecialist(
    username: string,
    newUsername: string
  ): Promise<DigitalSpecialist> {
    const url = `${DS_BASE_URL}/specialists/${username}/clone`
    return this.http
      .post<DigitalSpecialist>(
        url,
        {},
        {
          params: { newUsername: newUsername },
        }
      )
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/clone API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  updateSpecialist(specialist: DigitalSpecialist): Promise<DigitalSpecialist> {
    const url = `${DS_BASE_URL}/specialists/${specialist.username}`
    return this.http
      .put<DigitalSpecialist>(url, specialist)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/edit API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  deleteSpecialist(username: string): Promise<void> {
    const url = `${DS_BASE_URL}/specialists/${username}`
    return this.http
      .delete<void>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/delete API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  getAvailableTools(): Promise<LLMTool[]> {
    const url = `${DS_BASE_URL}/tools`
    return this.http
      .get<LLMTool[]>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/getAvailableTools API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  getTool(id: string): Promise<LLMTool> {
    const url = `${DS_BASE_URL}/tools/${id}`
    return this.http
      .get<LLMTool>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/getTool API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  getToolRelationships(id: string): Promise<ToolRelationships> {
    const url = `${DS_BASE_URL}/tools/${id}/relationships`
    return this.http
      .get<ToolRelationships>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/getToolRelationships API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  updateTool(tool: LLMTool): Promise<LLMTool> {
    const url = `${DS_BASE_URL}/tools/${tool.toolId}`
    return this.http
      .put<LLMTool>(url, tool)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/updateTool API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  createTool(tool: LLMTool): Promise<LLMTool> {
    const url = `${DS_BASE_URL}/tools`
    return this.http
      .post<LLMTool>(url, tool)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/createTool API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  deleteTool(id: string): Promise<void> {
    const url = `${DS_BASE_URL}/tools/${id}`
    return this.http
      .delete<void>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/deleteTool API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  createSkill(skill: Skill): Promise<Skill> {
    const url = `${DS_BASE_URL}/skills`
    return this.http
      .post<Skill>(url, skill)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/createSkill API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  getSkills(): Promise<Skill[]> {
    const url = `${DS_BASE_URL}/skills`
    return this.http
      .get<Skill[]>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/getSkills API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  updateSkill(skill: Skill): Promise<Skill> {
    const url = `${DS_BASE_URL}/skills/${skill.skillId}`
    return this.http
      .put<Skill>(url, skill)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/updateSkill API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  getSkill(id: string): Promise<Skill> {
    const url = `${DS_BASE_URL}/skills/${id}`
    return this.http
      .get<Skill>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/getSkill API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  getSkillRelationships(id: string): Promise<SkillRelationships> {
    const url = `${DS_BASE_URL}/skills/${id}/relationships`
    return this.http
      .get<SkillRelationships>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/getSkillRelationships API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  deleteSkill(id: string): Promise<void> {
    const url = `${DS_BASE_URL}/skills/${id}`
    return this.http
      .delete<void>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/deleteSkill API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  getUserInstances(): Promise<UserInstance[]> {
    const url = `${DS_BASE_URL}/user-instances`
    return this.http
      .get<UserInstance[]>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/getUserInstances API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  getUserInstance(specialistUsername: string): Promise<UserInstance> {
    const url = `${DS_BASE_URL}/user-instances/${specialistUsername}`
    return this.http
      .get<UserInstance>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/getUserInstance API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  getAllSessions(
    specialistUsername: string,
    topN: number
  ): Promise<DigitalSpecialistSession[]> {
    const url = `${DS_BASE_URL}/chatSessions`
    return this.http
      .get<DigitalSpecialistSession[]>(url, {
        params: {
          specialistUsername,
          topN,
        },
      })
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/getAllSessions API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  //TODO: deleteme 2024-07-01
  // DS chat content used to be stored in dyanamo in ds-ws, but since MOONSHOT-1611 it's been moved to S3
  // this method only exists to ease the transition period (only internal users have been using DS before MOONSHOT-1611, so these session histories can be lost)
  async getDeprecatedSessionMessages(
    sessionId: string
  ): Promise<ChatMessageModel[]> {
    const url = `${DS_BASE_URL}/chatSessions/${sessionId}/messages`
    const response = await this.http
      .get<Record<string, unknown>>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(
            `ds/getDeprecatedSessionMessages API failed, url: ${url}`
          )
          //special handling, just say it's empty
          return []
        })
      )
      .toPromise()
    if (response['messages']) {
      return response['messages'] as ChatMessageModel[]
    }

    return []
  }

  createSession(
    specialistUsername: string,
    message: string
  ): Promise<DigitalSpecialistSession> {
    // Trim length of message. If it's too long, request will be blocked
    message = message.substring(0, 20)
    const url = `${DS_BASE_URL}/chatSessions`
    return this.http
      .post<DigitalSpecialistSession>(url, {
        firstLineText: message,
        specialistUsername,
      })
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/createSession API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  deleteSession(sessionId: string): Promise<void> {
    const url = `${DS_BASE_URL}/chatSessions/${sessionId}`
    return this.http
      .delete<void>(`${DS_BASE_URL}/chatSessions/${sessionId}`)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/deleteSession API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  getKnowledgeFiles(username: string): Promise<string[]> {
    const url = `${DS_BASE_URL}/knowledge/${username}`
    return this.http
      .get<string[]>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/getKnowledgeFiles API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  uploadKnowledgeFile(username: string, file: File): Promise<void> {
    const form = new FormData()
    form.append('file', file)
    const url = `${DS_BASE_URL}/knowledge/${username}`
    return this.http
      .post<void>(url, form)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/uploadKnowledgeFile API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  deleteKnowledgeFiles(username: string, fileNames: string[]): Promise<void> {
    const url = `${DS_BASE_URL}/knowledge/${username}`
    return this.http
      .delete<void>(url, {
        body: fileNames,
      })
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/deleteKnowledgeFile API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  // ---- DS User permission specific methods ----
  getDsUsers(
    sharedResouceType: SharedResourceType,
    dsId: string
  ): Promise<DsUser[]> {
    const url = `${DS_BASE_URL}/permission/${sharedResouceType}/${dsId}`
    return this.http
      .get<DsUser[]>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/users API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  assignDsToUsers(
    sharedResouceType: SharedResourceType,
    resourceId: string,
    data: DsUser[]
  ) {
    const url = `${DS_BASE_URL}/permission/${sharedResouceType}/${resourceId}`
    return this.http
      .post(url, data)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/assignUsers API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  unassignDsFromUsers(
    sharedResouceType: SharedResourceType,
    resourceId: string,
    data: DsUser[]
  ) {
    const url = `${DS_BASE_URL}/permission/${sharedResouceType}/${resourceId}`
    return this.http
      .delete(url, { body: data })
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/unassignUsers API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  getParameterTypes() {
    const url = `${DS_BASE_URL}/parameters/types`
    return this.http
      .get<string[]>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`ds/getParameterTypes API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }
}
