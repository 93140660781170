import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PrivateModeReducer {
  isPrivateModeSet: boolean
}

export const privateModeInitialState: PrivateModeReducer = {
  isPrivateModeSet: false
}

export const privateModeSlice = createSlice({
  name: 'privateMode',
  initialState: privateModeInitialState,
  reducers: {
    setIsPrivateModeSet: (state, action: PayloadAction<PrivateModeReducer>) => {
      state.isPrivateModeSet = action.payload.isPrivateModeSet
    },
  },
})

export const { setIsPrivateModeSet } = privateModeSlice.actions

export default privateModeSlice.reducer
