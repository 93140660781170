<!-- https://codepen.io/typ90/pen/vgEbvN -->

<div class="loader">
  <span>{{ messageUpdate }}</span>
  <div class="loader--dots">
    <span></span>
    <span></span>
    <span></span>
  </div>
</div>
