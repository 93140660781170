import { Component, OnDestroy, OnInit } from '@angular/core'
import { ChatSession, GradedResponse } from '@cwan-gpt-ui/models'
import { CwanGptApi } from '@cwan-gpt-ui/services'
import {
  RootState,
  selectQueryNavBarEvaluatedList,
  selectQueryNavBarList,
  selectQueryResult,
  updateEvaluatedSessionsList,
  updateQuerySessionList,
} from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
@Component({
  selector: 'chat-analyzer-grader',
  templateUrl: './chat-analyzer-grading.component.html',
  styleUrls: ['./chat-analyzer-grading.component.scss'],
})
export class ChatAnalyzerGraderComponent implements OnInit, OnDestroy {
  gradingOptions: string[] = ['Easy', 'Medium', 'Hard']
  selectedComplexity = ''
  selectedNumValue = 0
  userFeedback = ''
  tickInterval = 1
  step = 1
  currId = ''

  queryResults: ChatSession[] = []
  evaluatedResults: ChatSession[] = []
  private destroyed$ = new Subject()
  constructor(
    private readonly store: Store<RootState>,
    private readonly cwanGptApi: CwanGptApi
  ) {}
  ngOnInit(): void {
    this.store
      .select(selectQueryResult)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((session) => {
        this.currId = session ? session.id : ''
        this.dataEventRefresher()
      })
    this.store
      .select(selectQueryNavBarList)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((queryResults) => {
        this.queryResults = queryResults
      })
    this.store
      .select(selectQueryNavBarEvaluatedList)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((queryResults) => {
        this.evaluatedResults = queryResults
      })
  }

  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  private dataEventRefresher(): void {
    this.selectedComplexity = ''
    this.selectedNumValue = 0
    this.userFeedback = ''
  }

  isSubmitDisabled(): boolean {
    // Check if fields are at their default values
    return (
      this.selectedComplexity === '' ||
      this.selectedNumValue === 0 ||
      this.currId === ''
    )
  }

  onClick() {
    const data = {
      complexity: this.selectedComplexity,
      correctness: this.selectedNumValue,
      comments: this.userFeedback,
      id: this.currId,
    }
    const foundInUnevaluated = this.queryResults.find(
      (query) => query.id === data.id
    )
    if (foundInUnevaluated) {
      this.queryResults = this.queryResults.filter(
        (query) => query.id !== data.id
      )
      this.store.dispatch(updateQuerySessionList(this.queryResults))
      const newEvaluatedResults = [...this.evaluatedResults, foundInUnevaluated]
      this.store.dispatch(updateEvaluatedSessionsList(newEvaluatedResults))
    }

    const gradedResponse: GradedResponse = data
    this.cwanGptApi.sendGradedResponse(gradedResponse)
  }

  formatLabel(value: number) {
    return `${value}`
  }
}
