import { Component } from '@angular/core'

@Component({
  selector: 'ad-blocker',
  templateUrl: './ad-blocker.component.html',
  styleUrls: ['./ad-blocker.component.scss'],
})
export class AdBlockerComponent {
  message =
    '# Disable Ad Blocker #### In order for Crystal to perform at its best capacity, please have Ad Blocker disabled.'
}
