import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { environment } from "environments/environment"
import { BehaviorSubject } from "rxjs"
import { catchError, take } from "rxjs/operators"

const BASE_URL = environment.cwanGptWsBaseUrl
const SHARED_DOCUMENTS_CATEGORIES_URL = `${BASE_URL}/shared-documents/categories`

@Injectable({
  providedIn: 'root',
})
export class SharedDocumentsService {
  constructor(private httpClient: HttpClient) {}
  private category = new BehaviorSubject<string | undefined>(undefined)
  currentCategory = this.category.asObservable()

  changeCategory(category: string | undefined) {
    this.category.next(category)
  }

  async getCategories() {
    return this.httpClient
      .get<string[]>(SHARED_DOCUMENTS_CATEGORIES_URL)
      .pipe(
        take(1),
        catchError((error) => {
          return Promise.reject(error)
        })
      )
      .toPromise()
  }
}