<div class="chat-header" *ngIf="!excludeHeader">
  <div class="chat-title"><!-- descriptive chat title --></div>
  <div class="chat-status"><!-- connection status indicator --></div>
</div>
<div class="chat-body">
  <div #chatContentContainer class="chat-content-container">
    <ng-content
      *ngIf="(chatHistory?.length ?? 0) === 0 && excludeSplashScreen"
    ></ng-content>
    <div
      class="pre-messages"
      *ngIf="chatHistory?.length === 0 && !excludeSplashScreen"
    >
      <h1>Crystal</h1>
      <p>
        Welcome to Crystal! This is a private enterprise instance of generative
        AI technology that can help you with a variety of topics, while keeping
        Clearwater data secure.
      </p>
      <p>
        GPT-4o-Mini is the default model, but can be changed to a different
        model if needed. If you need help with where to start, select a topic
        below.
      </p>
      <div class="pre-messages-examples">
        <h4>Example prompts</h4>
        <ul>
          <li
            *ngFor="let prompt of examplePrompts"
            (click)="applyExamplePrompt(prompt)"
          >
            {{ prompt }}
          </li>
        </ul>
      </div>
    </div>
    <div class="chat-content">
      <ng-container *ngFor="let msg of chatHistory; let i = index">
        <chat-message
          *ngIf="msg.role !== 'system' || debug"
          [message]="msg"
          [messageIndex]="i"
          [debug]="debug"
          (feedback)="handleFeedback($event, i)"
          (messageSend)="handleMessageSend($event)"
        ></chat-message>
      </ng-container>
      <!-- append a temporary typing message -->
      <chat-message *ngIf="showTyping" [showTyping]="true"></chat-message>
    </div>
  </div>
</div>
<pdf-ctas (pdfAction)="handlePdfAction($event)"> </pdf-ctas>
<div class="chat-input-area-container">
  <temp-file-upload></temp-file-upload>
  <chat-input-area (messageSend)="handleMessageSend($event)"></chat-input-area>
</div>
<div class="chat-footer"><!-- footer information --></div>
