import { Injectable } from '@angular/core'
import { ChatSession, defaultModel } from '@cwan-gpt-ui/models'
import {
  RootState,
  removeSessionFromList,
  selectUserId,
  setSessionList,
} from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import { environment } from 'environments/environment'
import { SessionApiService } from './session-api.service'

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private userId?: string

  constructor(
    private readonly sessionApi: SessionApiService,
    private readonly store: Store<RootState>
  ) {
    this.store.select(selectUserId).subscribe((userId) => {
      this.userId = userId
    })
  }

  /**
   *
   * @returns a brand new session, but doesn't do anything with it - it doesn't exist on the server or in any history yet
   */
  generateNewSession(): ChatSession {
    return {
      id: this.createSessionId(),
      history: [],
      createdAt: new Date(),
      model: environment.defaultModel || defaultModel,
    }
  }

  //note: currently backed will save session state to S3 during chat api, this is for out-of-band saves, like for feedback
  async saveSession(session: ChatSession) {
    return this.sessionApi.saveSession(session)
  }

  async loadSession(sessionId: string): Promise<ChatSession> {
    return await this.sessionApi.getSession(sessionId)
  }

  /**
   * Does NOT return the list - just puts it in redux.  Watch redux for the list
   */
  async loadSessions() {
    const sessions = await this.sessionApi.getSessions()
    //schema migration
    updateSessionsV1_V2(sessions)
    this.store.dispatch(setSessionList(sessions))
  }

  async deleteSession(sessionId: string) {
    await this.sessionApi.deleteSession(sessionId)
    //remove from redux
    this.store.dispatch(removeSessionFromList(sessionId))
  }

  /**
   * returns milliseconds until 3000-01-01
   * We use this as a session ID because S3 returns it's objects alphabetically, and we want the most recent session to be first.
   * This is highly likely to be unique for a user, but there may be duplicates across users, so we also append the userid.
   * Security note: userid is only to ensure uniqueness, not enforce access.
   *   Access control is enforced by getting userId from auth token and storing sessions in a folder
   */
  private createSessionId() {
    const msUntil3000 = new Date(3000, 0, 1).getTime() - new Date().getTime()
    let id = msUntil3000.toString().padStart(15, '0')
    id += this.userId ?? ''
    return id
  }
}

/**
 * Schema migration - any further schema changes should implement new functions for V2 -> V3, and V3->V4
 * provider was added to the model
 * deploymentName => deployment
 * modelName => model
 */
function updateSessionsV1_V2(sessions: ChatSession[]) {
  sessions.forEach((s) => {
    s.history.forEach((msg) => {
      if (['AI', 'ai'].includes(msg.role)) {
        msg.role = 'assistant'
      }
    })

    if (!s.model.provider) s.model.provider = 'azure-openai'
    if (!s.model.deployment) {
      s.model.deployment = s.model.deploymentName
      s.model.deploymentName = undefined
    }
    if (!s.model.model) {
      s.model.model = s.model.modelName
      s.model.modelName = undefined
    }
  })
}
