import { createSelector } from '@ngrx/store'
import { RootState } from '../root'
import { ChatSessionRedux } from './chat-session.reducer'

const chatSessionState = (state: RootState) => state.chatSession

/**
 * This will update whenever ANY field within the session changes, which means you'll get a whole lot of updates that you may not want.
 * See the other more specific selectors for specific use cases.
 */
export const selectChatSession = createSelector(
  chatSessionState,
  (state: ChatSessionRedux) => state.currentSession
)

/**
 * This will signal only when the id changes, so is useful for session lifecycle monitoring
 */
export const selectChatSessionId = createSelector(
  chatSessionState,
  (state: ChatSessionRedux) => {
    if (!state.currentSession) return undefined
    return state.currentSession.id
  }
)

export const selectChatModel = createSelector(
  chatSessionState,
  (state: ChatSessionRedux) => {
    if (!state.currentSession) return undefined
    return state.currentSession.model
  }
)

export const selectChatToolNames = createSelector(
  chatSessionState,
  (state: ChatSessionRedux) => {
    if (!state.currentSession?.options?.tools) return []

    const toolNames: string[] = state.currentSession.options.tools?.map((t) => {
      if (typeof t === 'string') return t
      else return t.name
    })
    return toolNames
  }
)

/**
 * Views must subscribe to this to receive updates of the chatHistory for display
 * The sequence is:
 *  - call sendMessage
 *  - chatHistory updated with user message
 *  - display updated chatHistory
 *  - response comes back
 *  - chatHistory updated with assistant message
 *  - display updated chatHistory
 */
export const selectChatHistory = createSelector(
  chatSessionState,
  (state: ChatSessionRedux) => {
    if (!state.currentSession) return []
    return state.currentSession.history
  }
)

/**
 * Views must subscribe to this to receive updates of when to show the 'typing dots', e.g. we are waiting for a response from the AI
 */
export const selectIsAwaitingResponse = createSelector(
  chatSessionState,
  (state: ChatSessionRedux) => state.numOutstandingRequests > 0
)
