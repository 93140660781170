import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { DesignModule } from '@cwan-gpt-ui/design'
import { FileSelectModule, NfFormsModule } from '@northfork/ng-basics'
import { HighchartsChartModule } from 'highcharts-angular'
import { NgxJsonViewerModule } from 'ngx-json-viewer'
import { ChatInputAreaComponent } from './chat-input-area/chat-input-area.component'
import { ChatMessageActionComponent } from './chat-message-action/chat-message-action.component'
import { ChatMessageActionsComponent } from './chat-message-actions/chat-message-actions.component'
import { ChatMessageChartComponent } from './chat-message-chart/chat-message-chart.component'
import { ChatMessageCitationComponent } from './chat-message-citation/chat-message-citation.component'
import { ChatMessageInputFormComponent } from './chat-message-input-form/chat-message-input-form.component'
import { ChatMessageComponent } from './chat-message/chat-message.component'
import { ChatWidgetComponent } from './chat-widget.component'
import { FileUploadComponent } from './file-upload/file-upload.component'
import { ProgressComponent } from './progress/progress.component'
import { TempFileCtasComponent } from './temp-file-ctas/temp-file-ctas.component'
import { TypingDotsComponent } from './typing-dots/typing-dots.component'

@NgModule({
  declarations: [
    ChatInputAreaComponent,
    ChatMessageComponent,
    ChatMessageCitationComponent,
    ChatMessageActionComponent,
    ChatMessageInputFormComponent,
    ChatWidgetComponent,
    TypingDotsComponent,
    FileUploadComponent,
    ProgressComponent,
    TempFileCtasComponent,
    ChatMessageChartComponent,
    ChatMessageActionsComponent,
  ],
  imports: [
    // Angular Dependencies
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    //angular-materia
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    MatMenuModule,
    MatSnackBarModule,
    NfFormsModule,
    FileSelectModule,
    DesignModule,

    //external libs
    NgxJsonViewerModule,
    HighchartsChartModule,
  ],
  exports: [
    ChatWidgetComponent,
    ChatMessageComponent,
    ChatInputAreaComponent,
    FileUploadComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ChatWidgetModule {}
