import { ChatSession } from '@cwan-gpt-ui/models'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface ChatSessionListRedux {
  sessions: ChatSession[]
}

export const chatSessionListInitialState: ChatSessionListRedux = {
  sessions: [],
}

export const chatSessionListSlice = createSlice({
  name: 'chatSessionList',
  initialState: chatSessionListInitialState,
  reducers: {
    setSessionList: (state, chatSessionList: PayloadAction<ChatSession[]>) => ({
      ...chatSessionListInitialState,
      sessions: chatSessionList.payload,
    }),

    prependSessionToList: (state, session: PayloadAction<ChatSession>) => {
      const newSessions = [session.payload, ...state.sessions]
      return {
        ...state,
        sessions: newSessions,
      } as ChatSessionListRedux
    },

    updateSessionInList: (state, session: PayloadAction<ChatSession>) => {
      const id = session.payload.id
      //granted, this is O(n), but: 1) the list will be small, and 2) the most common use case is to update the first in the list (current session usually is at top of list)
      //further optimization would be to add an index here in this file mapping id to index, and keep it up to date
      const idx = state.sessions.findIndex((item) => item.id === id)
      if (idx < 0) {
        //this is ok, in the copilot we don't currently load the session list at all, so this won't be there.
        // console.warn(`updateSession: unknown session id: ${id}`)
        return state
      }
      const newSessions = [...state.sessions]
      newSessions[idx] = session.payload
      return {
        ...state,
        sessions: newSessions,
      }
    },

    removeSessionFromList: (state, sessionId: PayloadAction<string>) => {
      const id = sessionId.payload
      const newSessions = state.sessions.filter((item) => item.id !== id)
      return {
        ...state,
        sessions: newSessions,
      }
    },
  },
})

export const {
  setSessionList,
  prependSessionToList,
  updateSessionInList,
  removeSessionFromList,
} = chatSessionListSlice.actions

export default chatSessionListSlice.reducer
