import { ChatSession } from '@cwan-gpt-ui/models'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface QueryNavBarRedux {
  currentQuerySession?: ChatSession
}

export const queryNavBarInitialState: QueryNavBarRedux = {
  currentQuerySession: undefined,
}

export const queryNavBarSlice = createSlice({
  name: 'queryNavBar',
  initialState: queryNavBarInitialState,
  reducers: {
    setCurrentQuerySession: (state, action: PayloadAction<ChatSession>) => {
      return {
        ...state,
        currentQuerySession: action.payload,
      }
    },
  },
})

export const { setCurrentQuerySession } = queryNavBarSlice.actions

export default queryNavBarSlice.reducer
