import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface DebugToolsRedux {
  isDebugModeSet: boolean
}

export const debugToolsInitialState: DebugToolsRedux = {
  isDebugModeSet: false,
}

export const debugToolsSlice = createSlice({
  name: 'debugTools',
  initialState: debugToolsInitialState,
  reducers: {
    setIsDebugModeSet: (state, action: PayloadAction<DebugToolsRedux>) => {
      state.isDebugModeSet = action.payload.isDebugModeSet
    },
  },
})

export const { setIsDebugModeSet } = debugToolsSlice.actions

export default debugToolsSlice.reducer
