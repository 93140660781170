import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSliderModule } from '@angular/material/slider'
import { MatTooltipModule } from '@angular/material/tooltip'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {
  CwanGptApi,
  CwanGptWsHttp,
  PromptTemplateApiService,
  PromptTemplateHttpService,
} from '@cwan-gpt-ui/services'
import { NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap'
import { NgSelectModule } from '@ng-select/ng-select'

import { PromptTemplateModule } from '@cwan-gpt-ui/libs/prompt-template'

import { ChatWidgetModule } from '@cwan-gpt-ui/chat-widget'
import {
  ButtonModule,
  CheckboxModule,
  FileSelectModule,
  InlineEditModule,
  NfFormsModule,
  NumberModule,
  ToggleModule,
} from '@northfork/ng-basics'
import { HighchartsChartModule } from 'highcharts-angular'
import { NgxJsonViewerModule } from 'ngx-json-viewer'
import { GlobalConfig, ToastrModule } from 'ngx-toastr'
import { ChatDataSourcesComponent } from './chat-data-sources/chat-data-sources.component'
import { ChatSessionsComponent } from './chat-sessions/chat-sessions.component'
import { ChatTuningOptionsComponent } from './chat-tuning-options/chat-tuning-options.component'
import { ChatWindowComponent } from './chat-window/chat-window.component'
import { CwanGPTChatComponent } from './cwan-gpt-chat.component'
import { PdfCtasComponent } from './pdf-ctas/pdf-ctas.component'
import { ProgressComponent } from './progress/progress.component'
import { SharedDocumentsComponent } from './shared-documents/shared-documents.component'
import { TempFileUploadComponent } from './temp-file-upload/temp-file-upload.component'
import { UserFilesComponent } from './user-files/user-files.component'
const DEFAULT_TOASTR_CONFIG: Partial<GlobalConfig> = {
  preventDuplicates: true,
  maxOpened: 5,
  closeButton: true,
}

@NgModule({
  declarations: [
    CwanGPTChatComponent,
    ChatWindowComponent,
    ChatSessionsComponent,
    ProgressComponent,
    ChatDataSourcesComponent,
    UserFilesComponent,
    TempFileUploadComponent,
    PdfCtasComponent,
    ChatTuningOptionsComponent,
    SharedDocumentsComponent,
  ],
  imports: [
    // Angular Dependencies
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    //this app modules
    ChatWidgetModule,
    //northfork
    NfFormsModule,
    NgSelectModule,
    ButtonModule,
    NgbTooltipModule,
    NgbNavModule,
    ToggleModule,
    ToastrModule.forRoot(DEFAULT_TOASTR_CONFIG),
    InlineEditModule,
    NgSelectModule,
    FileSelectModule,
    CheckboxModule,
    NgxJsonViewerModule,
    NumberModule,
    PromptTemplateModule,

    // Material Dependencies
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatTooltipModule,
    HighchartsChartModule,
  ],
  exports: [CwanGPTChatComponent, ChatWindowComponent],
  providers: [
    { provide: CwanGptApi, useClass: CwanGptWsHttp },
    {
      provide: PromptTemplateApiService,
      useClass: PromptTemplateHttpService,
    },
  ],
})
export class CwanGPTChatModule {}
