import { createSelector } from '@ngrx/store'
import { RootState } from '../root'
import { QueryNavBarRedux } from './query-navbar.reducer'

const queryNavBarState = (state: RootState) => state.queryNavBar

export const selectQueryResult = createSelector(
  queryNavBarState,
  (state: QueryNavBarRedux) => state.currentQuerySession
)

export const selectQueryResultHistory = createSelector(
  queryNavBarState,
  (state: QueryNavBarRedux) => {
    if (!state.currentQuerySession) return []
    return state.currentQuerySession.history
  }
)
