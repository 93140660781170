import { PromptRequest, PromptTemplate } from '@cwan-gpt-ui/models'

export abstract class PromptTemplateApiService {
  abstract getTemplates(): Promise<PromptTemplate[]>
  abstract getTemplate(id: string): Promise<PromptTemplate>
  abstract getTemplateByName(name: string): Promise<PromptTemplate | undefined>
  abstract getTemplatesByTagName(tag: string) : Promise<PromptTemplate[]>
  abstract createTemplate(request: PromptRequest): Promise<PromptTemplate>
  abstract updateTemplate(
    id: string,
    request: PromptRequest
  ): Promise<PromptTemplate>
  abstract deleteTemplate(id: string): Promise<void>
  abstract toggleShowInMenu(id: string, showInMenu: boolean): Promise<void>
  abstract setDefaultTemplate(id: string): Promise<void>
  abstract removeDefaultTemplate(): Promise<void>
}
