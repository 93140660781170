<div class="auto-ignore-container">
  <div class="auto-ignore-header">
    <h1>Auto Ignore Settings</h1>
    <button mat-raised-button matSuffix color="primary" aria-label="update">
      Update
    </button>
  </div>
  <p>
    The Minimum Confidence Level controls the aggressiveness of auto ignoring a
    transaction based on the external transaction prediction model
  </p>
  <div class="auto-ignore-form">
    <mat-form-field>
      <mat-label>Minimum Confidence Level</mat-label>
      <input matInput [(ngModel)]="inputText" />
    </mat-form-field>
  </div>

  <div class="auto-ignore-table-container">
    <div class="table-header-row">
      <h6>Accounts / Clients Enabled</h6>
      <button mat-raised-button color="primary">Add Now</button>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let element">{{ element.type }}</td>
      </ng-container>

      <!-- Id Column -->
      <ng-container matColumnDef="identifier">
        <th mat-header-cell *matHeaderCellDef>Identifier</th>
        <td mat-cell *matCellDef="let element">{{ element.identifier }}</td>
      </ng-container>

      <!-- Enabled Column -->
      <ng-container matColumnDef="enabled">
        <th mat-header-cell *matHeaderCellDef>Enabled/Disabled</th>
        <td mat-cell *matCellDef="let element">{{ element.enabled }}</td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">{{ element.actions }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
