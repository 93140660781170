<div class="variable-substitution">
  <div class="delete-btn-container">
    <button
      mat-icon-button
      matTooltip="Delete variable"
      icon="delete"
      class="delete-btn"
      color="warn"
      aria-labelledby="Delete variable"
      (click)="handleDelete()"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <mat-form-field class="variable-name">
    <mat-label>Variable name</mat-label>
    <input
      matInput
      type="text"
      class="form-control"
      [formControl]="variableNameForm"
      placeholder="Variable name"
    />
  </mat-form-field>
  <mat-form-field class="variable-value">
    <mat-label>Enter variable value here</mat-label>
    <input
      matInput
      type="text"
      class="form-control"
      [formControl]="variableValueForm"
      placeholder="Enter variable value here"
    />
  </mat-form-field>
</div>
