import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'

/**
 * Creates an async validator function that checks if a given template name already exists in the set of existing names.
 *
 * @param {Set<string>} existingNames - The set of existing names to check against.
 * @return {AsyncValidatorFn} - An async validator function that returns an Observable of ValidationErrors or null.
 */
export function nameExistsValidator(
  existingNames: Set<string>
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const isInvalid = existingNames.has(control.value)

    return of(isInvalid).pipe(
      map((isValid: boolean) => {
        return isValid ? { nameExists: { value: control.value } } : null
      })
    )
  }
}

/**
 * Generates a validator function that checks if a given template name already exists in a set of existing names, except its own name.
 *
 * @param {string} templateName - The name of the template to check.
 * @param {Set<string>} existingNames - The set of existing names to compare against.
 * @return {AsyncValidatorFn} A validator function that returns an Observable of ValidationErrors or null.
 */
export function nameExistsToUpdateValidator(
  templateName: string,
  existingNames: Set<string>
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    let existingNamesExceptCurrent = existingNames
    if (existingNames.delete(templateName)) {
      existingNamesExceptCurrent = existingNames
    }
    const isInvalid = existingNamesExceptCurrent.has(control.value)

    return of(isInvalid).pipe(
      map((isValid: boolean) => {
        return isValid ? { nameExists: { value: control.value } } : null
      })
    )
  }
}
